import React from 'react';


const md = require('markdown-it')({
    html: true,
    breaks: true,
});

const MD = ({value}) => {
    return (
        <div dangerouslySetInnerHTML={{__html: md.render(value || '')}}/>
    )
};

export default MD;