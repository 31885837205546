/**
 * @name: 点名详情弹窗
 * @description:
 * @author: Wood
 * @date: 2024/3/6
 * @update:
 */

import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {getLessons} from "../../../lib/api_lessons.js";
import {Table, Descriptions, Modal, Button, Spin} from 'antd';
import styles from './dianming_detail.module.css';
import DianMingEditModal from './dianming_edit_modal';
import DictName from '../../../components/dict/dict-name';
import RoomName from '../../../components/rooms/room-name';
import LessonEditModal from '../../affairs/classes/lesson_edit_modal';
import DianMingModal from "./dianming_modal";

const {Column} = Table;

const DianMingDetailModal = ({id, onCancel}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dianping, setDianping] = useState(null);
    const [editCourse, setEditCourse] = useState(null);
    const [preimage, setPreimage] = useState(null);
    const [budianUserID, setBudianUserID] = useState(0);

    const fetchData = () => {
        setLoading(true);
        getLessons(id)
            .then(res => {
                if (res.code === 0) {
                    setData(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(fetchData, []);


    return (
        <Modal
            title="点名和评价情况"
            open={true}
            destroyOnClose={true}
            footer={null}
            onCancel={onCancel}
            width={1000}
        >
            <Spin spinning={loading}>
                {data &&
                    <>
                        <Button onClick={() => setEditCourse(data)}>修改</Button>
                        <Descriptions>
                            <Descriptions.Item label="课程">{data.name}</Descriptions.Item>
                            <Descriptions.Item label="班级">
                                <Link to={`/affairs/classes/${data.classes.id}`}>
                                    {data.classes.name}
                                </Link>
                            </Descriptions.Item>
                            <Descriptions.Item label="状态"><DictName value={data.status}
                                                                      type_value={"lesson_status"}/></Descriptions.Item>
                            <Descriptions.Item label="教师">{data.teacher_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="课程">{data.courses_name}/{data.courses_lessons_name}</Descriptions.Item>
                            <Descriptions.Item label="教室"><RoomName id={data.room_id}/></Descriptions.Item>
                            <Descriptions.Item label="开始时间">{data.course_time}</Descriptions.Item>
                            <Descriptions.Item label="结束时间">{data.course_end_time}</Descriptions.Item>
                            <Descriptions.Item label="点名时间">{data.check_time}</Descriptions.Item>
                            <Descriptions.Item label="时长">{data.duration}分钟</Descriptions.Item>
                            <Descriptions.Item label="授课课时">{data.keshi}</Descriptions.Item>
                            <Descriptions.Item label="到课人数">{data.daoke}</Descriptions.Item>

                            <Descriptions.Item label="备注" span={3}>{data.remark}</Descriptions.Item>

                        </Descriptions>
                        <Table dataSource={data.students} rowKey={"id"} pagination={false} size="small">
                            <Column
                                title="姓名"
                                dataIndex="nick_name"
                                key="nick_name"
                                width={80}
                                render={(nick_name, record) => (
                                    <Link to={`/affairs/students/${record.id}`}>{nick_name}</Link>
                                )}
                            />
                            <Column
                                title="状态"
                                dataIndex="status"
                                key="status"
                                width={60}
                                render={(status) => (status === 1 ? "上课" : "未到")}
                            />

                            <Column
                                title="点名时间"
                                dataIndex="courses_users"
                                key="courses_users.create_on"
                                width={120}
                                render={(courses_users) => (courses_users ?
                                    <span>{courses_users.create_on}</span> : null)}
                            />
                            <Column
                                title="点评"
                                dataIndex="courses_users"
                                key="courses_users.dianping_content"
                                render={(courses_users) => (
                                    <>
                                        {courses_users && <div>
                                            <div>
                                                {courses_users.dianping_content}
                                            </div>
                                            {courses_users.dianping_photos &&
                                                <div>
                                                    {courses_users.dianping_photos.split(',').map(item => (
                                                        <div className={styles.photo}
                                                             style={{backgroundImage: `url(${item}?x-oss-process=style/suofang60)`}}
                                                             onClick={() => setPreimage(item)}
                                                        />))}
                                                </div>
                                            }
                                            {courses_users.dianping_videos && (() => {
                                                let config = JSON.parse(courses_users.dianping_videos);
                                                if (!config) return null;

                                                return (
                                                    <div>
                                                        <video src={config.url} preload="none" controls="controls"
                                                               style={{width: "100%"}}>
                                                            您的浏览器不支持 video 标签。
                                                        </video>
                                                    </div>
                                                )
                                            })()

                                            }
                                        </div>}
                                    </>
                                )}
                            />

                            <Column
                                title="操作"
                                key="action"
                                width={60}
                                render={(text, record) => (
                                    <div>
                                        {record.status !== 1 &&
                                            <a onClick={() => setBudianUserID(record.id)}>补点</a>}
                                        {record.status === 1 &&
                                            <a onClick={() => setDianping(record.courses_users)}>点评</a>
                                        }
                                    </div>
                                )}
                            />
                        </Table>
                    </>
                }

            </Spin>

            {budianUserID > 0 &&
                <DianMingModal
                    lesson_id={id}
                    user_id={budianUserID}
                    onOk={() => {
                        setBudianUserID(0);
                        fetchData();
                    }}
                    onCancel={() => setBudianUserID(0)}
                />
            }


            {!!preimage &&
                <Modal
                    title={false}
                    open={!!preimage}
                    footer={null}
                    bodyStyle={{padding: 0}}
                    onCancel={() => setPreimage(null)}
                >
                    <img src={preimage} style={{maxWidth: '100%'}} alt=""/>
                </Modal>
            }

            {!!dianping &&
                <DianMingEditModal
                    data={dianping}
                    onOk={() => {
                        setDianping(null);
                        fetchData();
                    }}
                    onCancel={() => {
                        setDianping(null);
                    }}
                />
            }

            {!!editCourse &&
                <LessonEditModal
                    data={editCourse}
                    onCancel={() => setEditCourse(null)}
                    onOk={() => {
                        setEditCourse(null);
                        fetchData();
                    }}
                />
            }
        </Modal>
    )
}

export default DianMingDetailModal;