import React, {useEffect, useState} from 'react';
import {Space, Modal, Table, Tag, Divider, message, Button} from 'antd';
import DataTable from '../../../components/data/data-table2';
import {getStudentCoursesLessons} from "../../../lib/api_students";
import {postUnlock, postUnlockMuti} from "../../../lib/api_courses";

const {Column} = Table;

const CoursesList = ({data: {id}, onOk, onCancel, user_id}) => {
    const [list, setList] = useState([]);
    const [ids, setIds] = useState([]);
    const [loading, setLoading] = useState(false);


    const fetch = () => {
        getStudentCoursesLessons(id, user_id)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };

    useEffect(fetch, []);


    const handleUnlockProject = (classes_project_id) => {
        postUnlock(classes_project_id, user_id)
            .then(res => {
                if (res.code === 0) {
                    message.success("解锁成功");
                    fetch();
                } else {
                    message.error(res.message)
                }
            })
    };

    const onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        // this.setState({ selectedRowKeys });
        setIds(selectedRowKeys)
    };

    const handleUnlockProjectMuti = () => {
        setLoading(true);
        postUnlockMuti(ids, user_id)
            .then(res => {
                if (res.code === 0) {
                    message.success("解锁成功");
                    fetch();
                } else {
                    message.error(res.message)
                }
            })
            .finally(_=>{
                setLoading(false);
            })
    };

    return (
        <Modal
            title="课时"
            visible={true}
            // visible={visible}
            // onOk={form.submit}
            onCancel={onCancel}
            width={800}
            destroyOnClose={true}
            footer={false}
        >
            <Button type="primary"
                    onClick={handleUnlockProjectMuti}
                    disabled={ids.length <= 0}
                    loading={loading}
            >
                解锁
            </Button>
            <span style={{marginLeft: 8}}>
            {ids.length > 0 ? `共计${ids.length}项` : ''}
          </span>
            <Table dataSource={list} rowKey={"id"}
                   pagination={false}
                   size={"small"}
                   rowSelection={{
                       type: "checkbox",
                       getCheckboxProps: (record) => ({
                           disabled: record.unlock,
                       }),
                       onChange: onSelectChange
                       // ...rowSelection,
                   }}
            >
                <Column
                    title="序号"
                    dataIndex="tag_index"
                    key="tag_index"
                />
                <Column
                    title="名称"
                    dataIndex="name"
                    key="name"
                />

                <Column
                    title="解锁"
                    dataIndex="unlock"
                    key="unlock"
                    render={(unlock) => (
                        <div>
                            {unlock
                                ? <span style={{color: "green"}}>已解锁</span>
                                : <span style={{color: "red"}}>未解锁</span>
                            }
                        </div>
                    )}
                />


                <Column
                    title="解锁时间"
                    dataIndex="unlock"
                    key="lock2"
                    render={(unlock, record) => (
                        <div>
                            {unlock && <span>
                                            {record.star1_time}
                                        </span>}
                        </div>
                    )}
                />
                <Column
                    title="状态"
                    dataIndex="status"
                    key="status"
                    render={(status, record) => {
                        switch (status) {
                            case 0:
                                return <Tag color="gold">未改编</Tag>
                            case 1:
                                return <Tag color="blue">待批改</Tag>
                            case 2:
                                return <Tag color="red">需要改进</Tag>
                            case 3:
                                return <Tag color="green">已完成</Tag>
                        }
                    }}
                />
                <Column
                    title="操作"
                    dataIndex="id"
                    key="id"
                    render={(id, record) => (
                        <>
                        {!record.unlock && <><a
                            onClick={() => handleUnlockProject(id)}>解锁</a><Divider
                            type="vertical"/></>}
                        {/*<a onClick={() => this.setState({keshi_edit: {...record}})}>解锁之前课程</a>*/}
                        </>
                    )}
                />
            </Table>
        </Modal>
    )
};


export default CoursesList;


