/**
 * @name: reducer集合
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import {combineReducers} from 'redux';
import accountReducer, {initialAccountState} from "./account.js";
import loginModalReducer, {initialLoginModalState} from "./login-modal.jsx";
import passwordModalReducer, {initialPasswordModalState} from "./password-modal.js";
import roomsReducer, {initialRoomsState} from "./rooms";


const initialState = {
    account: initialAccountState,
    // school: initialSchoolState,
    loginModal: initialLoginModalState,
    passwordModal: initialPasswordModalState,
    rooms: initialRoomsState,
    // schoolModal: initialSchoolModalState,
};

const reducer = combineReducers({
    account: accountReducer,
    // school: schoolReducer,
    loginModal: loginModalReducer,
    passwordModal: passwordModalReducer,
    rooms: roomsReducer,
    // schoolModal: schoolModalReducer,
});


export {
    reducer as default,
    initialState,
}