import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {
    getProblem,
    getProblemTestCases,
    nextProblem,
    prevProblem,
    putProblem,
    getProblemTestCase,
    postProblemTestCase,
} from "../../../lib/api_problems";
import {Button, Card, Form, Input, InputNumber, message, Radio, Row, Col} from "antd";
import MdEditor from "../../../components/md-editor/md-editor";
import {SaveFilled, SaveOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import styles from './testcase_info.module.css';


const TestcaseInfo = ({id, name}) => {
    const [form] = Form.useForm();
    const [data, setdata] = useState("");
    const [isedit, setisedit] = useState(false);
    const [newdata, setnewdata] = useState("");


    const fetchFileData = () => {
        getProblemTestCase(id, name)
            .then(res => {
                if (res.code === 0) {
                    setdata(res.data);
                }
            })
    }

    useEffect(fetchFileData, []);

    const handleEdit = () => {
        setisedit(true);
        setnewdata(data);
    }
    const handleCancel = () => {
        setisedit(false);
        setnewdata("");
    }

    const handleSave = () => {
        postProblemTestCase(id,name, newdata)
            .then(res=>{
                if(res.code===0){
                    message.success("保存成功");
                    setisedit(false);
                    setnewdata("");
                    fetchFileData();
                }
            })
    }

    const handleChange=(v)=>{
        // console.log(v.target.value);
        setnewdata(v.target.value);
    }

    return (
        <div>
            {isedit
                ? <div>
                    <Input.TextArea defaultValue={data} onChange={handleChange} autoSize={{ minRows: 2, maxRows: 6 }}/>
                    <Button onClick={handleSave} type={"primary"}>保存</Button>
                    <Button onClick={handleCancel}>取消</Button>
                </div>
                : <div>
                    <pre className={styles.pre}>{data}</pre>
                    <Button onClick={handleEdit}>修改</Button>
                </div>

            }


        </div>
    );
};


export default TestcaseInfo;