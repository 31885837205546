import React, {useRef} from 'react';
import {Card, Input} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import DataTable from '../../../components/data/data-table2';

const WXAuth = () => {

    const table = useRef();
    const config = {
        table: table,
        // title: "数据字典",
        tableName: "wx_auth",
        // pagination: false,
        size: "small",
        columns: [{
            title: 'openId',
            dataIndex: 'openId',
            render: (openId) => <Input value={openId}/>
        }, {
            title: 'nickName',
            dataIndex: 'nickName',
        }, {
            title: 'gender',
            dataIndex: 'gender',
        }, {
            title: 'city',
            dataIndex: 'city',
        }, {
            title: 'province',
            dataIndex: 'province',
        }, {
            title: 'country',
            dataIndex: 'country',
        }, {
            title: 'avatarUrl',
            dataIndex: 'avatarUrl',
            render: (avatarUrl)=>{
                return avatarUrl ? <img src={avatarUrl} width={30} height={30} /> : ""
            }
        }, {
            title: 'unionId',
            dataIndex: 'unionId',
            render: (unionId) => <Input value={unionId}/>
        }, {
            title: 'content',
            dataIndex: 'content',
            render: (content) => <Input value={content}/>
        }, {
            title: 'login_key',
            dataIndex: 'login_key',
            render: (login_key) => <Input value={login_key}/>
        }, {
            title: 'session_key',
            dataIndex: 'session_key',
            render: (session_key) => <Input value={session_key}/>
        }, {
            title: 'appid',
            dataIndex: 'appid',
        }]
    };

    return (
    <PageContent title={"wx_auth"}>
        <Card bordered={false}>
            <DataTable {...config} />

            {/*<DataTable*/}
                {/*tableName={"wx_auth"}*/}
                {/*table={{*/}
                    {/*size: "small",*/}
                    {/*columns: [{*/}
                        {/*title: 'openId',*/}
                        {/*dataIndex: 'openId',*/}
                        {/*render: (openId) => <Input value={openId}/>*/}
                    {/*}, {*/}
                        {/*title: 'nickName',*/}
                        {/*dataIndex: 'nickName',*/}
                    {/*}, {*/}
                        {/*title: 'gender',*/}
                        {/*dataIndex: 'gender',*/}
                    {/*}, {*/}
                        {/*title: 'city',*/}
                        {/*dataIndex: 'city',*/}
                    {/*}, {*/}
                        {/*title: 'province',*/}
                        {/*dataIndex: 'province',*/}
                    {/*}, {*/}
                        {/*title: 'country',*/}
                        {/*dataIndex: 'country',*/}
                    {/*}, {*/}
                        {/*title: 'avatarUrl',*/}
                        {/*dataIndex: 'avatarUrl',*/}
                        {/*render: (avatarUrl)=>{*/}
                            {/*return avatarUrl ? <img src={avatarUrl} width={30} height={30} /> : ""*/}
                        {/*}*/}
                    {/*}, {*/}
                        {/*title: 'unionId',*/}
                        {/*dataIndex: 'unionId',*/}
                        {/*render: (unionId) => <Input value={unionId}/>*/}
                    {/*}, {*/}
                        {/*title: 'content',*/}
                        {/*dataIndex: 'content',*/}
                        {/*render: (content) => <Input value={content}/>*/}
                    {/*}, {*/}
                        {/*title: 'login_key',*/}
                        {/*dataIndex: 'login_key',*/}
                        {/*render: (login_key) => <Input value={login_key}/>*/}
                    {/*}, {*/}
                        {/*title: 'session_key',*/}
                        {/*dataIndex: 'session_key',*/}
                        {/*render: (session_key) => <Input value={session_key}/>*/}
                    {/*}, {*/}
                        {/*title: 'appid',*/}
                        {/*dataIndex: 'appid',*/}
                    {/*}]*/}
                {/*}}*/}
            {/*/>*/}
        </Card>
    </PageContent>
)};


export default WXAuth;


