
const SETROOMS = 'rooms/set';

const initialState = [];

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SETROOMS:
            return [...action.rooms];
        default:
            return state;
    }
};


const setRooms = function (rooms) {
    return {
        type: SETROOMS,
        rooms: rooms
    }
};



export {
    reducer as default,
    initialState as initialRoomsState,
    setRooms,
}