/**
 * @name: 周历
 * @description:
 * @author: Wood
 * @date: 2021/4/26
 * @update:
 */
import React, {Component} from 'react';
import moment from 'moment';
import {getCalendar} from "../../lib/api_calendar";
import CalendarWeek from './calendar-week-comp';

class CalendarWeekTime extends Component {
    state = {
        courses: [],
        teachers: [],
        students: [],
        // date: moment().format("YYYY-MM-DD"),
        columns: [],
        rows: []
    };

    componentDidMount() {
        // let {date} = this.state;
        let {start_date, end_date} = this.props;
        // let now = moment();
        // let start_date = moment(date).day(1).format("YYYY-MM-DD");
        // let end_date = moment(date).day(9).format("YYYY-MM-DD");
        end_date = moment(end_date).add(1, "d").format("YYYY-MM-DD");
        this.loadData(start_date, end_date);
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = (start_date, end_date) => {
        getCalendar(start_date, end_date)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        courses: result.data.courses,
                        teachers: result.data.teachers,
                        students: result.data.students
                    }, () => {
                        this.dealData()
                    })
                } else {

                }
            })
    };

    // 处理数据
    dealData = () => {
        let {courses, teachers} = this.state;
        let{start_date} = this.props;
        let columns = [];
        let rows = teachers.map(teacher=>{
            return {
                id: teacher.id,
                title: teacher.nick_name
            }
        });

        courses.forEach(item => {
            item.type = item.status === 0 ? 'default' : "success";
            if (item.lus) {
                let lus = item.lus.filter(_ => _.status === 1);
                let c = lus.length;
                if (c !== item.daoke) {
                    item.type = "warning"
                }
            }
        });
        // let start_date = moment(date).day(1);
        // let end_date =  moment(date).day(8);
        for (let i = 0; i < 7; i++) {
            let date = moment(start_date).add(i, "d");
            let date_str = date.format("MM.DD")
            columns.push({
                week_str: date.format("dddd"),
                date_str: date_str,
                // courses: courses.filter(item => {
                //     return date.isSame(item.course_time, "day")
                // })
            });
            rows.forEach(row=>{
                row[date_str] = courses.filter(item => {
                    return date.isSame(item.course_time, "day") && item.teacher_id === row.id
                });
            })
        }
        this.setState({
            columns: columns,
            rows: rows
        })
    };
    handleEventClick = (course)=>{
        this.props.onEventClick && this.props.onEventClick(course.id);
    };


    render() {
        let {columns, rows} = this.state;
        return (
            <CalendarWeek columns={columns} rows={rows} onEventClick={this.handleEventClick} />
        )
    }
}


export default CalendarWeekTime;
