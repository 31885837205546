import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Modal } from 'antd';
import {postCoursesLessons} from "../../../lib/api_courses_lessons";

/**
 * 批量添加课时弹窗
 */
class LessonsAddModal extends Component {
    state = {
        loading: false
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.setState({
                    loading: true
                });
                values.id = this.props.id;
                postCoursesLessons(values)
                    .then(result => {
                        if (result.code === 0) {
                            this.handleOK();
                        } else {
                            message.error(result.message);
                        }
                    })
                    .catch(err => {
                        message.error(err.message);
                    })
                    .finally(() => {
                        this.setState({
                            loading: false
                        })
                    });
            }
        });

    };

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {data, visible} = this.props;

        return (
            <Modal
                title="批量添加课时"
                visible={visible}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
                width={700}
            >
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item label={"课时名称"} help={"一行一个名称"}>
                        {getFieldDecorator("names", {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入课时名称',
                                },
                            ],
                        })(<Input.TextArea autosize={{minRows: 5}}/>)}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}


LessonsAddModal.propTypes = {
    visible: PropTypes.bool,
    id: PropTypes.number.isRequired,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

export default Form.create({name: 'lessons-add'})(LessonsAddModal);

