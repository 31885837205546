import React, {Component} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {Layout, Divider} from 'antd';
import './App.css';
import Calendar from './containers/calendar';
import Stat from './containers/stat/stat';
import Login from './containers/login/login';
import SiderBar from './components/sider-menu/sidebar';
import {getFirstMenu} from "./components/sider-menu/menu.config";
import Affairs from './containers/affairs/Router';
import Teaching from './containers/teaching/Router';
import Settings from './containers/settings/Router';
import Operation from './containers/operation/Router';
import ChangePassword from './containers/change-password';
import Test from './containers/calendar/calendar-week';

class App extends Component {
    state = {
        path: "/",
        siderWidth: 224
    };

    routeChange = (route) => {
        console.log(route); // 这个route里面有当前路由的各个参数信息
        this.setState({
            path: route.pathname
        }, () => {
            // 获取菜单情况
            let menu = getFirstMenu(this.state.path)
            if (menu && menu.children.length === 0) {
                this.setState({
                    siderWidth: 92
                })
            } else {
                this.setState({
                    siderWidth: 224
                })
            }
        })
    };

    componentDidMount() {
        // 路由监听
        this.historyinstance = this.props.history.listen(this.routeChange);
        this.routeChange(this.props.history.location)
    }

    componentWillUnmount() {
        // 取消监听
        this.historyinstance && this.historyinstance();
    }


    render() {
        let {account} = this.props;
        if (!account) {
            return (<Login/>)
        }
        return (
            <Layout style={{minHeight: "100%"}}>
                {/*侧边导航栏*/}
                <SiderBar path={this.state.path}/>
                <Layout.Content style={{marginLeft: this.state.siderWidth}}>
                    <Switch>
                        <Route exact path="/"
                               component={Calendar}/>
                        <Route path="/calendar"
                               component={Calendar}/>
                        <Route path="/affairs"
                               component={Affairs}/>
                        <Route path="/teaching"
                               component={Teaching}/>
                        <Route path="/settings"
                               component={Settings}/>
                        <Route path="/operation"
                               component={Operation}/>
                        <Route exact path="/stat"
                               component={Stat}/>
                        <Route exact path="/test"
                               component={Test}/>
                    </Switch>
                    <ChangePassword/>

                </Layout.Content>
            </Layout>
        );
    }
}


const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
