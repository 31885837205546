/**
 * @name: 修改教师信息对话框
 * @description:
 * @author: Wood
 * @date: 2021/5/23
 * @update:
 */
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {putTeacher} from "../../../lib/api_teachers";
import {Modal, Input, Form} from 'antd';
import DictRadioGroup from '../../../components/dict/dict-radio-group';

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const TeacherEditModal = ({visible, data, onOk, onCancel}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(data)
    }, [data]);

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                values.id = data.id;
                putTeacher(values)
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk();
                        }
                    });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            title="修改教师信息"
            visible={visible}
            onCancel={onCancel}
            onOk={handleOk}
        >
            <Form
                {...layout}
                form={form}
            >
                <Form.Item
                    label="姓名"
                    name="nick_name"
                    rules={[{
                        required: true,
                        message: '请输入姓名'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="昵称"
                    name="other_name"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="性别"
                    name="sex"
                    rules={[{
                        required: true,
                        message: '请选择性别'
                    }]}
                >
                    <DictRadioGroup type_value={"sex"}/>
                </Form.Item>
                <Form.Item
                    label="手机号"
                    name="mobile_number"
                    rules={[{
                        required: true,
                        message: '请输入手机号码'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="账号"
                    name="login_name"
                    rules={[{
                        required: true,
                        message: '请输入登录账号'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="状态"
                    name="is_file"
                    rules={[{
                        required: true,
                        message: '请选择状态'
                    }]}
                >
                    <DictRadioGroup type_value={"is_file"}/>
                </Form.Item>
            </Form>
        </Modal>
    )
};


TeacherEditModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    data: PropTypes.object,
};

export default TeacherEditModal;