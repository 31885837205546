import React from 'react';
import {Link} from 'react-router-dom';
import {Table, Tag,Space} from 'antd';

const {Column} = Table;

const ClassesList = ({list, onEdit}) => (
    <Table dataSource={list}
           rowKey={"id"}
           pagination={false}
           size={"small"}
        // scroll={{ x: 1200 }}
    >
        <Column
            title="#"
            dataIndex="id"
            key="id"
            width={50}
            sorter={(a, b) => a.id < b.id ? 1 : -1}
        />
        <Column
            title="班级名称"
            dataIndex="name"
            key="name"
            ellipsis={true}
            width={330}
            render={(name, record) => {
                return (
                    <Link to={`/affairs/classes/${record.id}`}>{name}</Link>
                )
            }}
            sorter={(a, b) => a.name < b.name ? 1 : -1}
        />
        <Column
            title="课程"
            dataIndex="courses_name"
            key="courses_name"
            width={180}
            ellipsis={true}
            sorter={(a, b) => a.courses_name < b.courses_name ? 1 : -1}
        />
        <Column
            title="讲师"
            dataIndex="teacher_name"
            key="teacher_name"
            width={80}
            sorter={(a, b) => a.teacher_name < b.teacher_name ? 1 : -1}
        />
        <Column
            title="学生"
            dataIndex="students"
            key="学生"
            // width={330}
            render={(students) => {
                if(!students) return "";
                return <Space size={[0,6]}  wrap>{students.map(student => <Link
                    to={`/affairs/students/${student.id}`}><Tag>{student.nick_name}</Tag></Link>)}
                </Space>
            }}
        />


        <Column
            title="操作"
            key="action"
            width={60}
            // fixed='right'
            render={(text, record) => (
                <div>
                    <a onClick={() => {
                        onEdit && onEdit(record)
                    }}>修改</a>
                </div>
            )}
        />
    </Table>
);
export default ClassesList;