/**
 * @name: 周历
 * @description:
 * @author: Wood
 * @date: 2021/4/26
 * @update:
 */
import React from 'react';
import styles from './calendar-week-comp.module.css';
import moment from 'moment';

const CalendarWeek = ({columns, rows, onEventClick}) => {
    return (

        <div className={styles.ts}>
            <div className={styles.header}>
                <div className={styles.rowTitle}>
                </div>
                <div className={styles.headerItems}>
                    {columns.map(day => (
                        <div className={styles.item} key={day.week_str}>
                            <div className={styles.week}>
                                {day.week_str}
                            </div>
                            <div>
                                {day.date_str}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.content}>
                {rows.map(row => (
                    <div className={styles.row}>
                        <div className={styles.rowTitle}>
                            <span>{row.title}</span>
                        </div>
                        <div className={styles.rowItems}>
                            {columns.map(({date_str}, index) => (
                                <div className={styles.col} key={index}>
                                    {row[date_str] && row[date_str].map((course) => (
                                        <div className={`${styles.eventitem} ${styles[course.type]}`} key={course.id}
                                             onClick={() => onEventClick && onEventClick(course)}>
                                            <div>
                                                {moment(course.course_time).format("HH:mm")}
                                                -
                                                {moment(course.course_end_time).format("HH:mm")}
                                            </div>
                                            <div className={styles.eventName}>
                                                <span>
                                                {course.name}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>

                    </div>
                ))}

            </div>
        </div>
    )
};


export default CalendarWeek;
