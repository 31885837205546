import axios from './rest-axios';

const getPapers = () => axios.get(`/v1/papers`);
const getPaper = (id) => axios.get(`/v1/papers/${id}`);
const postPaper = (model) => axios.post(`/v1/papers`, model);
const putPaper = (model) => axios.put(`/v1/papers/${model.id}`, model);
const delPaper = (ids) => axios.post(`/v1/papers/delete`, {ids});


export {
    getPapers,
    getPaper,
    postPaper,
    putPaper,
    delPaper,
};
