import React from 'react';
import {Tag} from "antd";

const TagsDisplay = ({tags}) => {
    return tags.map(tag => {
        switch (tag) {
            case "周一":
                return <Tag color="magenta">{tag}</Tag>
            case "周二":
                return <Tag color="red">{tag}</Tag>
            case "周三":
                return <Tag color="volcano">{tag}</Tag>
            case "周四":
                return <Tag color="orange">{tag}</Tag>
            case "周五":
                return <Tag color="gold">{tag}</Tag>
            case "周六":
                return <Tag color="lime">{tag}</Tag>
            case "周日":
                return <Tag color="green">{tag}</Tag>
            default:
                return <Tag>{tag}</Tag>
        }
    })
}

export default TagsDisplay;