/**
 * @name: python作业
 * @description:
 * @author: Wood
 * @date: 2019/4/21
 * @update:
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, message, Input, Radio, Switch, Select, Card } from 'antd';
import PageContent from '../../../components/page-content/page-content';
import MdEditor from '../../../components/md-editor/md-editor';
import TestcasesEdit from '../../../components/testcases-edit/testcases-edit';
import {postChallenge, getChallenge, putChallenge, postUpdate} from "../../../lib/api_challenges";

class ChallengesEdit extends Component {
    state = {};

    componentDidMount() {
        // 获取题目信息
        this.fetch();
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetch = () => {
        // postUpdate();
        getChallenge(this.props.id)
            .then(result => {
                if (result.code === 0) {
                    let testcases = [];
                    if (result.data.config) {
                        let config = JSON.parse(result.data.config);
                        testcases = config.testcases;
                    }

                    this.setState({
                        pkey: result.data.pkey,
                        name: result.data.name,
                        description: result.data.description,
                        statement: result.data.statement2,
                        input_format: result.data.input_format,
                        constraints: result.data.constraints,
                        output_format: result.data.output_format,
                        tags: result.data.tags ? JSON.parse(result.data.tags) : [],
                        difficulty: result.data.difficulty,
                        is_public: result.data.is_public,
                        // config: result.data.config,
                        testcases: JSON.stringify(testcases),
                        tutorial_content: result.data.tutorial_content,
                    })
                } else {
                    message.error(result.message)
                }
            })
    }


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);

                // 数据处理以及保存
                let config = {
                    name: values.name,
                    description: values.description || "",
                    statement: values.statement || "",
                    input_format: values.input_format || "",
                    constraints: values.constraints || "",
                    output_format: values.output_format || "",
                    testcases: JSON.parse(values.testcases || "[]")
                };
                console.log(config)

                putChallenge(this.props.id, {
                    pkey: values.pkey,
                    name: values.name,
                    description: values.description,
                    statement: values.statement,
                    input_format: values.input_format,
                    constraints: values.constraints,
                    output_format: values.output_format,
                    tags: JSON.stringify(values.tags),
                    difficulty: values.difficulty,
                    is_public: values.is_public ? 1 : 0,
                    config: JSON.stringify(config),
                    tutorial_content: values.tutorial_content,
                }).then(result => {
                    if (result.code === 0) {
                        message.success("添加成功！");
                        this.props.history.go(-1);
                    } else {
                        message.error(result.message);
                    }
                })
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        return (
            <PageContent title={"修改题目"}>
                <Card bordered={false}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item label="编号">
                        {getFieldDecorator('pkey', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入编号',
                                },
                            ],
                            initialValue: this.state.pkey
                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item label="题目名称">
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入题目名称',
                                },
                            ],
                            initialValue: this.state.name
                        })(<Input/>)}
                    </Form.Item>
                    {/*<Form.Item label="描述">*/}
                        {/*{getFieldDecorator('description', {*/}
                            {/*initialValue: this.state.description*/}
                        {/*})(<Input.TextArea/>)}*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="难度">
                        {getFieldDecorator('difficulty', {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择难度',
                                },
                            ],
                            initialValue: this.state.difficulty

                        })(
                            <Radio.Group>
                                <Radio value={0}>简单</Radio>
                                <Radio value={1}>一般</Radio>
                                <Radio value={2}>困难</Radio>
                                <Radio value={3}>高级</Radio>
                                <Radio value={4}>专家</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                    <Form.Item label="题目描述">
                        {getFieldDecorator('statement', {
                            initialValue: this.state.statement
                        })(
                            <MdEditor/>
                        )}
                    </Form.Item>
                    {/*<Form.Item label="输入格式">*/}
                        {/*{getFieldDecorator('input_format', {*/}
                            {/*initialValue: this.state.input_format*/}
                        {/*})(*/}
                            {/*<MdEditor/>*/}
                        {/*)}*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="约束">*/}
                        {/*{getFieldDecorator('constraints', {*/}
                            {/*initialValue: this.state.constraints*/}
                        {/*})(*/}
                            {/*<MdEditor/>*/}
                        {/*)}*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label="输出格式">*/}
                        {/*{getFieldDecorator('output_format', {*/}
                            {/*initialValue: this.state.output_format*/}
                        {/*})(*/}
                            {/*<MdEditor/>*/}
                        {/*)}*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="标签">
                        {getFieldDecorator('tags', {
                            initialValue: this.state.tags
                        })(
                            <Select mode="tags" placeholder="Tags Mode">
                                <Select.Option key={"循环"}>循环</Select.Option>
                                <Select.Option key={"条件判断"}>条件判断</Select.Option>
                                <Select.Option key={"排序"}>排序</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="公开">
                        {getFieldDecorator('is_public', {
                            valuePropName: 'checked',
                            initialValue: this.state.is_public
                        })(<Switch/>)}
                    </Form.Item>
                    <Form.Item label="测试用例">
                        {getFieldDecorator('testcases', {
                            initialValue: this.state.testcases
                        })(<TestcasesEdit/>)}
                    </Form.Item>

                    <Form.Item wrapperCol={{span: 12, offset: 4}}>
                        <Button type="primary" htmlType="submit">
                            提交保存
                        </Button>
                    </Form.Item>
                </Form>

                </Card>
            </PageContent>
        )
    }
}

ChallengesEdit.propTypes = {
    id: PropTypes.number.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({name: 'challenges_create'})(ChallengesEdit)));

