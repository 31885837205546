import axios from './rest-axios';

const getHuodong1Templates = () => axios.get(`/v1/huodong/1/templates`);
const postHuodong1Templates = (model) => axios.post(`/v1/huodong/1/templates`, model);
const putHuodong1Templates = (model) => axios.put(`/v1/huodong/1/templates/${model.id}`, model);
export {
    getHuodong1Templates,
    postHuodong1Templates,
    putHuodong1Templates
};
