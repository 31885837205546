
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Select} from 'antd';
import {getProjects} from "../../../lib/api_courses";


class CoursesSelect extends Component {
    state = {
        list: [],
        selectedKeys: []
    };

    componentDidMount() {
        this.loadTeachers();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 下载所有教师数据
    loadTeachers = () => {
        getProjects()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                } else {

                }
            })
    };


    render() {
        const {list} = this.state;
        const {value, onChange} = this.props;
        return (
            <Select value={value}
                    // style={{width: 120}}
                    onChange={(value) => {
                        onChange && onChange(value)
                    }}>
                {list.map(item => (
                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}

            </Select>

        )
    }

}


CoursesSelect.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number
};

export default (CoursesSelect);
