import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {PlusOutlined, DeleteOutlined, ShoppingCartOutlined, CheckOutlined} from '@ant-design/icons';
import {Table, Popconfirm, Button, Card, Space, Row, Col} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getExams, delExam, postExam, putExam, getExamUsers, postExamUsers, delExamUsers} from "../../../lib/api_exams";
import ExamEditForm from './exam_edit_form';
import StudentSelectModal from './student_select_modal';
import EasyFormModal from '../../../components/easy_form_modal';
import moment from 'moment';
import PigaiModal from './pigai_modal';

const {Column} = Table;

const ExamUsers = ({id}) => {
    const [list, setlist] = useState([]);
    const [addModal, setaddModal] = useState(false);
    const [editRecord, seteditRecord] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [pigai, setpigai] = useState(0);

    const fetchData = () => {
        getExamUsers(id)
            .then((result) => {
                if (result.code === 0) {
                    let data = result.data;
                    for (let i in data) {
                        data[i].begin_time = moment(data[i].begin_time)
                        data[i].end_time = moment(data[i].end_time)
                    }
                    setlist(data);
                }
            })
    };

    useEffect(fetchData, []);

    const handleDelete = () => {
        // console.log(selectedRows)
        delExamUsers(id, selectedRows.map(m => m.user_id))
            .then((result) => {
                if (result.code === 0) {
                    fetchData();
                    setSelectedRows([])
                }
            })
    };

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        // selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <PageContent title={"测验学员"}>

            <Card bordered={false}>
                <Row justify={"space-between"}
                     style={{marginBottom: 10}}>
                    <Col>
                        <Space>

                            <Popconfirm
                                title={`是否删除${selectedRows.length}条记录？`}
                                onConfirm={handleDelete}
                                // onCancel={cancel}
                                okText="删除"
                                cancelText="取消"
                                disabled={selectedRows.length === 0}
                            >
                                <Button disabled={selectedRows.length === 0} icon={<DeleteOutlined/>} danger>删除</Button>
                            </Popconfirm>
                            <Button disabled={selectedRows.length === 0} icon={<CheckOutlined/>}
                                    type="primary">自动批改</Button>

                        </Space>
                    </Col>
                    <Col>
                        <Button type="primary" icon={<PlusOutlined/>}
                                onClick={() => setaddModal({})}
                        >添加</Button>
                    </Col>

                </Row>
                <Table dataSource={list}
                       rowKey={"user_id"}
                       pagination={false}
                       size="small"
                       rowSelection={rowSelection}
                       scroll={{
                           // x: 1500,
                           y: `calc(100vh - 230px)`,
                       }}
                >
                    <Column
                        title="学员"
                        dataIndex="nick_name"
                        key="学员"
                    />
                    <Column
                        title="开始时间"
                        dataIndex="begin_time"
                        key="开始时间"
                        width={150}
                        render={(time) => time.format("YYYY-MM-DD HH:mm")}
                    />
                    <Column
                        title="结束时间"
                        dataIndex="end_time"
                        key="结束时间"
                        width={150}
                        render={(time) => time.format("YYYY-MM-DD HH:mm")}
                    />
                    <Column
                        title="时长（分钟）"
                        key="时长"
                        render={(record) => record.end_time.diff(record.begin_time, "m")}
                    />
                    <Column
                        title="最终得分"
                        dataIndex="score"
                        key="最终得分"
                    />
                    <Column
                        title="状态"
                        dataIndex="status"
                        key="状态"
                        render={(status) => {
                            switch (status) {
                                case -1:
                                    return "缺考";
                                case 0:
                                    return "未开始";
                                case 1:
                                    return "进行中";
                                case 2:
                                    return "已提交";
                                case 3:
                                    return "已批改";
                                default:
                                    return "未知";
                            }
                        }}
                    />
                    <Column
                        title="操作"
                        key="操作"
                        render={(record) => (
                            <Space>
                                <a onClick={() => setpigai(record.user_id)}>批改</a>
                            </Space>
                        )}
                    />
                </Table>
            </Card>
            {addModal && <StudentSelectModal
                onOk={(ids) => {
                    console.log(ids);
                    (async () => {
                        await postExamUsers(id, ids)
                            .then(res => {
                                if (res.code === 0) {
                                    setaddModal(false);
                                    fetchData();
                                }
                            })
                    })().catch();
                }}
                onCancel={() => {
                    setaddModal(false);
                }}
            />}
            {pigai>0 &&
            <PigaiModal
                exam_id={id}
                user_id={pigai}
                onOk={()=>{
                    setpigai(0);
                    fetchData();
                }}
                onCancel={()=>{
                    setpigai(0)
                }}
            />
            }

        </PageContent>
    );
};

export default ExamUsers;
