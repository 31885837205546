import React, {useState, useEffect} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Table, Card} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import TaskForm from './task_form';
import EasyFormModal from '../../../components/easy_form_modal';
import {getTasks, postTask, putTask} from "../../../lib/api_tasks";

const {Column} = Table;

const Tasks = () => {
    const [list, setlist] = useState([]);
    const [addModal, setaddModal] = useState(false);
    const [editRecord, seteditRecord] = useState(null);

    const fetchData = () => {
        getTasks()
            .then((result) => {
                if (result.code === 0) {
                    setlist(result.data);
                }
            })
    };

    useEffect(fetchData, []);


    return (
        <PageContent title={"任务管理"}>
            <Card bordered={false}>
                <Button type='primary' icon={<PlusOutlined/>}
                        onClick={() => setaddModal({})}
                        style={{marginBottom: 10}}>添加任务</Button>

                <Table dataSource={list} rowKey={"id"} pagination={false} size={"small"}>
                    <Column
                        title="编号"
                        dataIndex="id"
                        key="编号"
                    />

                    <Column
                        title="名称"
                        dataIndex="title"
                        key="名称"
                        render={(title, record) => (
                            <div>
                                <a onClick={() => {
                                    seteditRecord(record);
                                }}>{title}</a>
                            </div>
                        )}
                    />

                    <Column
                        title="操作"
                        key="action"
                        render={(text, record) => (
                            <div>
                                <a onClick={() => {
                                    seteditRecord(record);
                                }}>修改</a>
                            </div>
                        )}
                    />
                </Table>

                {addModal && <EasyFormModal
                    data={addModal}
                    title={"添加任务"}
                    onOk={() => {
                        setaddModal(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setaddModal(false);
                    }}
                    formComponent={TaskForm}
                    restFunc={postTask}
                />}
                {editRecord && <EasyFormModal
                    data={editRecord}
                    title={"修改任务"}
                    onOk={() => {
                        seteditRecord(null);
                        fetchData();
                    }}
                    onCancel={() => {
                        seteditRecord(null);
                    }}
                    formComponent={TaskForm}
                    restFunc={putTask}
                />}
            </Card>
        </PageContent>
    );
};


export default Tasks;


