/**
 * @name: 上课提醒
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update:
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Row, Col, Tag, Button,DatePicker} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import moment from 'moment';
import styles from './notification.module.css';
import RoomName from '../../../components/rooms/room-name';
import {getNotificationList, sendNotification} from "../../../lib/api_notification";


class Notification extends Component {
    state = {
        lessons: [],
        date: moment().add(1, "d").format("YYYY-MM-DD"),
        sending: false,
    };

    componentDidMount() {
        this.loadClasses()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 获取班级列表
    loadClasses = () => {
        let {date} = this.state;
        getNotificationList(date)
            .then(res => {
                if (res.code === 0) {
                    // console.log(res);
                    this.setState({
                        lessons: res.data.lessons
                    })
                    // this.setState({
                    //     classes: classes.data
                    // }, () => {
                    //     this.loadStudents()
                    // })
                } else {
                    console.log(res.message);
                }
            })
    }

    handleSendNotification = () => {
        let {lessons} = this.state;
        let {rooms} = this.props;
        let notices = []; // 等待发送的通知
        lessons.forEach(lesson => {
            let stus = lesson.students.filter(_ => _.openid !== "");
            stus.forEach(student => {
                let name = student.nick_name;
                if (student.other_name) {
                    name += `(${student.other_name})`
                }
                let keyword3 = "";
                let room = rooms.find(_ => _.id.toString() === lesson.room_id.toString());
                if (room) {
                    keyword3 = room.name
                }
                notices.push({
                    openid: student.openid,
                    first: `[${name}]同学，明天有课，可别迟到哦～`,
                    keyword1: `${lesson.name}`,
                    keyword2: `${moment(lesson.course_time).format("MM月DD日 dddd HH:mm")} - ${moment(lesson.course_end_time).format("HH:mm")}`,
                    keyword3: keyword3,
                    remark: "点击查看课表，小码蚁编程祝您学习进步，生活愉快"
                })
            })
        })
        console.log(notices);
        (async () => {
            this.setState({
                sending: true
            });
            for (let i = 0; i < notices.length; i++) {
                 await sendNotification(notices[i])
            }

        })()
            .catch()
            .finally(_=>{
                this.setState({
                    sending: false
                });
            })


    };

    ondateChange= (date, dateString)=>{
        this.setState({
            date: date.format("YYYY-MM-DD")
        },()=>{
            this.loadClasses()
        })
    }


    render() {
        let {lessons, sending, date} = this.state;

        return (
            <PageContent title={"上课提醒通知"} routes={[
                {
                    path: '',
                    breadcrumbName: "首页",
                },
                {
                    path: 'students',
                    breadcrumbName: '学生管理',
                },
            ]}>
                {/*<Button onClick={() => sendNotification()}>测试</Button>*/}
                <DatePicker onChange={this.ondateChange} defaultValue={moment(date)} />

                <Button onClick={this.handleSendNotification} loading={sending}>全部发送</Button>
                <div>
                    {lessons.map(course => (
                        <div className={styles.item}>
                            <Row>
                                <Col span={12}>
                                <span className={styles.msg}>
                                    [{"{学生姓名(昵称)}"}]同学，明天有课，可别迟到哦～
                                </span><br/>
                                    课程名称：<span className={styles.msg}>{course.name}</span><br/>
                                    上课时间：<span
                                    className={styles.msg}>{moment(course.course_time).format("MM月DD日 dddd HH:mm")}
                                    -
                                    {moment(course.course_end_time).format("HH:mm")}
                                     </span>
                                    <br/>
                                    上课地点：<span className={styles.msg}><RoomName id={course.room_id}/></span><br/>
                                    <span className={styles.msg}>点击查看课表，小码蚁编程祝您学习进步，生活愉快</span>
                                </Col>
                                <Col span={12}>
                                    {course.students.map(student => (
                                        <div>
                                            {student.nick_name}({student.other_name})
                                            {student.openid ? <Tag color="green">已绑定</Tag> : <Tag color="red">未绑定</Tag>}

                                        </div>
                                    ))}
                                </Col>

                            </Row>
                        </div>
                    ))}
                </div>
            </PageContent>
        );
    }
}


Notification.propTypes = {
    classes_id: PropTypes.number
};


const mapStateToProps = state => ({
    // school: state.school,
    rooms: state.rooms,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Notification));


