import PropTypes from 'prop-types';
import React from 'react';
import {ManOutlined, WomanOutlined} from '@ant-design/icons';


const SexIcon = ({sex}) => {
    switch (sex){
        case 1:
            return <ManOutlined style={{color: "#1890ff", marginLeft: 5}}/>;
        case 2:
            return <WomanOutlined style={{color: "#ff0000", marginLeft: 5}}/>;
        default:
            return "";
    }
};

SexIcon.propTypes = {
    sex: PropTypes.number
};

export default SexIcon