import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getClasses, postClasses} from "../../lib/api_classes.js";
import {
    Button,
    Modal,
    Input,
    Alert,
    Table,
    Divider,
    Tabs,
    Calendar,
    Badge,
    Tooltip,
    Tag,
    Card,
    Radio,
    Space
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import styles from './calendar.module.css';
import moment from 'moment';
import {getCalendar} from "../../lib/api_calendar";
import DianmingDetail from '../teaching/courses/dianming_detail_modal';
import {LeftOutlined, RightOutlined, ReloadOutlined} from '@ant-design/icons';

const {Column} = Table;


class CalendarList extends Component {
    state = {
        month: '',
        courses: [],
        teachers: [],
        students: [],
        filter: 0
    };

    componentDidMount() {
        let now = moment();
        let start_date = now.format("YYYY-MM-01");
        let end_date = now.add(1, "months").format("YYYY-MM-01")
        this.loadData(start_date, end_date);
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = (start_date, end_date) => {
        getCalendar(start_date, end_date)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        month: start_date,
                        courses: result.data.courses,
                        teachers: result.data.teachers,
                        students: result.data.students
                    })
                } else {

                }
            })
    };


    getListData = (value) => {
        // console.log(value);
        return this.state.courses.filter(item => {
            if (this.state.filter) {
                if (item.teacher_id !== this.state.filter) {
                    return false;
                }
            }
            return (moment(item.course_time).format("YYYY-MM-DD") === value.format("YYYY-MM-DD"))
        }).map(item => {
            let type = item.status === 0 ? 'default' : "success";
            if (item.lus) {
                let lus = item.lus.filter(_ => _.status === 1);
                let c = lus.length;
                if (c !== item.daoke) {
                    type = "warning"
                }
            }

            return {
                id: item.id,
                type: type,
                content: `${moment(item.course_time).format("HH:mm")} ${item.name}`
            };
        })
    };

    getStudentListData = (value) => {
        // console.log(value);
        return this.state.students.filter(item => {
            return (moment(item.birthday1).format("YYYY-MM-DD") === value.format("YYYY-MM-DD"))
        }).map(item => {
            return {
                id: item.id,
                type: item.status === 0 ? 'warning' : "success",
                content: `${item.nick_name}`
            };
        })
    };

    dateCellRender = (value) => {
        const listData = this.getListData(value);
        const stuListData = this.getStudentListData(value);
        return (
            <ul className={styles.events}>
                {listData.map(item => (
                    <li key={item.id} onClick={() => this.setState({dianmingDetailID: item.id})}>
                        <Badge status={item.type} text={item.content}/>
                    </li>
                ))}
                {/*{stuListData.map(item => (*/}
                {/*    <li>*/}
                {/*        <Link to={`/affairs/students/${item.id}`}>*/}
                {/*            <Badge color="pink" status={item.type}*/}
                {/*                   text={<span><Tag color="magenta">生日</Tag>{item.content}</span>}/>*/}
                {/*        </Link>*/}
                {/*    </li>*/}
                {/*))}*/}
            </ul>
        );
    };

    onChange = (value) => {
        console.log(value);
        let start_date = moment(value.format("YYYY-MM-01"))
        console.log(start_date.format("YYYY-MM-DD"))
        let end_date = moment(start_date).add(1, "months");
        console.log(end_date.format("YYYY-MM-DD"))
        if (this.state.month !== start_date.format("YYYY-MM-DD")) {
            // 月份不一致，重新读取数据
            this.loadData(start_date.format("YYYY-MM-DD"), end_date.format("YYYY-MM-DD"));
        }
    };

    render() {
        let {teachers, courses, filter} = this.state;

        // let curlist = list.filter(item => item.status === 0);
        // let endlist = list.filter(item => item.status === 1);
        return (
            <div style={{padding: 16}}>
                {/*<Radio.Group  buttonStyle="solid" style={{ marginBottom: 8 }}>*/}
                {/*<Radio.Button value="top">月历</Radio.Button>*/}
                {/*<Radio.Button value="left">周历</Radio.Button>*/}
                {/*</Radio.Group>*/}
                <Card bordered={false}>
                    <Tag.CheckableTag checked={filter === 0}
                                      onChange={() => this.setState({filter: 0})}>全部({courses.length})</Tag.CheckableTag>
                    {teachers.map(teacher => (
                        <Tag.CheckableTag checked={filter === teacher.id}
                                          onChange={() => this.setState({filter: teacher.id})}>{teacher.nick_name}({courses.filter(item => item.teacher_id === teacher.id).length})</Tag.CheckableTag>
                    ))}
                    <Calendar dateCellRender={this.dateCellRender}
                              headerRender={({value, type, onChange, onTypeChange}) => {
                                  return (
                                      <div style={{textAlign: "right"}}>
                                          <Space>
                                              <Button icon={<ReloadOutlined/>} onClick={() => {
                                                  onChange(moment());
                                              }}>
                                                  回到今日
                                              </Button>

                                              <Button icon={<LeftOutlined/>} onClick={() => {
                                                  const now = value.clone().add(-1, "M");
                                                  onChange(now);
                                              }}/>
                                              {value.format("YYYY年MM月")}
                                              <Button icon={<RightOutlined/>} onClick={() => {
                                                  const now = value.clone().add(1, "M");
                                                  onChange(now);
                                              }}/>
                                          </Space>
                                      </div>
                                  )
                              }}
                              onChange={this.onChange}
                              className={styles.calendar}
                              validRange={[moment("2019-01-01"), moment("2030-01-01")]}
                    />

                    {/*<Modal*/}
                    {/*    title="点名详情"*/}
                    {/*    visible={this.state.dianmingDetailID > 0}*/}
                    {/*    // onOk={this.handleCoursedianming}*/}
                    {/*    onCancel={() => this.setState({dianmingDetailID: 0})}*/}
                    {/*    // confirmLoading={this.state.CourseAddModalConfirmLoading}*/}
                    {/*    // okText="确认"*/}
                    {/*    // cancelText="取消"*/}
                    {/*    destroyOnClose={true}*/}
                    {/*    width={1000}*/}
                    {/*    footer={null}*/}
                    {/*>*/}
                    {/*    <DianmingDetail id={this.state.dianmingDetailID}/>*/}
                    {/*</Modal>*/}
                    {this.state.dianmingDetailID>0 &&
                        <DianmingDetail
                            id={this.state.dianmingDetailID}
                            onCancel={() => this.setState({dianmingDetailID: 0})}
                        />
                    }

                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CalendarList));
