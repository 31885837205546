import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Modal, Input, DatePicker, Radio, Form, InputNumber} from 'antd';
import moment from 'moment';
import {putCard} from "../../../lib/api_card";

const CardEditModal = ({data, visible, onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleXuKe = () => {
        form
            .validateFields()
            .then((values) => {
            console.log(values)
                // values.user_id = user_id;
                values.id = data.id;
                // 提交数据到服务器
                putCard(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };


    return (
        <Modal
            title="报课"
            visible={visible}
            onOk={handleXuKe}
            onCancel={onCancel}
        >
            <Form
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                form={form}
                initialValues={{...data, createdAt: moment(data.createdAt)}}
            >
                <Form.Item
                    label={"课程名称"}
                    name={"name"}
                    rules={[{
                        required: true,
                        message: '请输入课程名称'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={"报课时间"}
                    name={"createdAt"}
                    rules={[{
                        required: true,
                        message: '请选择报课时间'
                    }]}
                >
                    <DatePicker
                        format={"YYYY-MM-DD HH:mm"}
                        showTime={{format: 'HH:mm'}}
                    />
                </Form.Item>
                <Form.Item label={"备注"} name={"remark"}>
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item
                    label="支付顺序（从小到大）"
                    name="order_index"
                    rules={[
                        {
                            required: true,
                            message: '请输入支付顺序（从小到大）',
                        },
                    ]}
                >
                    <InputNumber/>

                </Form.Item>

            </Form>
        </Modal>
    )
};

CardEditModal.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

export default CardEditModal;