/**
 * @name: 补点名
 * @description:
 * @author: Wood
 * @date: 2024/3/6
 * @update:
 */
import React, {useState} from 'react';
import {Modal, Form, DatePicker} from 'antd';
import default_form_item_layout from "../../../config/default_form_item_layout";
import {postBuDian} from "../../../lib/api_lessons";

const DianMingModal = ({lesson_id, user_id, onOk, onCancel}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields()
            .then((values) => {
                setLoading(true)
                postBuDian(lesson_id, values.time.format("YYYY-MM-DD HH:mm:ss"), user_id)
                    .then(res => {
                        if (res.code === 0) {
                            handleOk();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
    };

    const handleOk = () => {
        onOk && onOk();
    }
    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title="点名"
            open={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            confirmLoading={loading}
            destroyOnClose={true}
        >

            <Form {...default_form_item_layout}
                  form={form}
            >
                <Form.Item
                    label={"点名时间"}
                    name={"time"}
                    rules={[{
                        required: true,
                        message: "请选择'${label}'"
                    }]}
                >
                    <DatePicker
                        showTime
                        format={'YYYY-MM-DD HH:mm'}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default DianMingModal