import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Input, Table} from 'antd';
import MarkdownHtml from '../../../components/markdown-html/markdown-html';
import {listDocuments} from "../../../lib/api_documents";

const DocumentSelectModal = ({onOk, onCancel}) => {
    const [documents, setdocuments] = useState([]);
    const [documentsfilter, setdocumentsfilter] = useState([]);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);

    const fetchData = () => {
        listDocuments()
            .then(result => {
                if (result.code === 0) {
                    setdocuments(result.data);
                    setdocumentsfilter(result.data);
                }
            })
    };

    const handleDocumentsSearch = (e) => {
        setdocumentsfilter(documents.filter(_ => _.title.includes(e.target.value) || _.id === parseInt(e.target.value)))
    };

    useEffect(fetchData, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setselectedRowKeys(selectedRowKeys);
        },
    };

    const handleDocumentsSubmit = () => {
        let list = documents.filter(_ => selectedRowKeys.includes(_.id));
        onOk && onOk(list)
    };

    return (
        <Modal
            title="添加文档"
            visible={true}
            onOk={handleDocumentsSubmit}
            onCancel={onCancel}
            // confirmLoading={this.state.loading}
            destroyOnClose={true}
            width={800}
        >
            <Input.Search placeholder="文档编号/名称" onChange={handleDocumentsSearch} enterButton/>
            <Table
                rowSelection={rowSelection}
                dataSource={documentsfilter}
                rowKey={"id"}
                size={"small"}
            >
                <Table.Column
                    title="编号"
                    dataIndex="id"
                    key="编号"
                />
                <Table.Column
                    title="名称"
                    dataIndex="title"
                    key="名称"
                    render={(title, record)=>{
                        return <a onClick={()=>{
                            Modal.info({
                                title: record.title,
                                content: (
                                    <MarkdownHtml markdown={record.content} />
                                ),
                                maskClosable: true,
                                icon: null,
                                onOk() {},
                            });
                        }}>{title}</a>
                    }}
                />
            </Table>
        </Modal>
    )
};

DocumentSelectModal.propTypes = {
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

export default DocumentSelectModal;