/**
 * @name: 教师列表
 * @description:
 * @author: Wood
 * @date: 2021/5/23
 * @update:
 */
import React, {useState, useEffect} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Card} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import StudentAddModal from './teacher-add-modal';
import TeacherEditModal from './teacher-edit-modal';
import TeacherList from './teacher-list';
import TeacherResetModal from './teacher-reset-modal';
import {getTeachers} from "../../../lib/api_teachers";


const Teachers = () => {
    const [list, setList] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [addModal, setAddModal] = useState(false);
    const [resetUser, setResetUser] = useState(null);

    const loadData = () => {
        getTeachers()
            .then((result) => {
                if (result.code === 0) {
                    setList(result.data);
                }
            })
    };
    useEffect(loadData, []);

    return (
        <PageContent title={"教师管理"}>
            <Card bordered={false}>
                {/*添加按钮*/}
                <Button
                    type='primary'
                    icon={<PlusOutlined/>}
                    onClick={() => {
                        setAddModal(true)
                    }}
                    style={{marginBottom: 10}}
                >添加教师</Button>
                {/*教师列表*/}
                <TeacherList
                    list={list}
                    onEdit={(record) => {
                        setEditUser(record)
                    }}
                    onReset={(record) => {
                        setResetUser(record)
                    }}
                />
            </Card>
            {/*添加教师弹出框*/}
            <StudentAddModal
                onOk={() => {
                    setAddModal(false);
                    loadData();
                }}
                onCancel={() => {
                    setAddModal(false);
                }}
                visible={addModal}
            />
            {/*修改教师弹出框*/}
            <TeacherEditModal
                onOk={() => {
                    setEditUser(null);
                    loadData()
                }}
                onCancel={() => {
                    setEditUser(null);
                }}
                visible={!!editUser}
                data={editUser}
            />
            {/*重置密码弹出框*/}
            <TeacherResetModal
                onOk={() => {
                    setResetUser(null);
                }}
                onCancel={() => {
                    setResetUser(null);
                }}
                visible={!!resetUser}
                data={resetUser}
            />
        </PageContent>
    );
};

export default Teachers