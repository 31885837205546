import axios from './rest-axios';

const getExams = () => axios.get(`/v1/exam`);
const postExam = (model) => axios.post(`/v1/exam`, model);
const getExam = (id) => axios.get(`/v1/exam/${id}`);
const putExam = (model) => axios.put(`/v1/exam/${model.id}`, model);
const delExam = (ids) => axios.post(`/v1/exam/delete`, {ids});
const getExamUsers = (id) => axios.get(`/v1/exam/${id}/users`);
const postExamUsers = (id, userids) => axios.post(`/v1/exam/${id}/users`, {ids: userids});
const delExamUsers = (id, ids) => axios.post(`/v1/exam/${id}/users/delete`, {ids});
const getExamUser = (exam_id, user_id) => axios.get(`/v1/exam/${exam_id}/users/${user_id}`);
const postExamUserPigai = (exam_id, user_id, result, score) => axios.post(`/v1/exam/${exam_id}/users/${user_id}/pigai`, {
    result,
    score
});


export {
    getExams,
    postExam,
    getExam,
    putExam,
    delExam,
    getExamUsers,
    postExamUsers,
    delExamUsers,
    getExamUser,
    postExamUserPigai,
};
