import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Input, message, Modal, Radio, InputNumber, Divider} from 'antd';
// import {postCourses} from "../../lib/api_courses";
import AliyunOSSUpload from '../../../components/aliyun-oss-upload';

import MdEditor from '../../../components/md-editor/md-editor';
import {putCoursesLessons} from "../../../lib/api_courses_lessons";
import ResourcesConfig from './resources-config';

class LessonsAddModal extends Component {
    state = {
        loading: false
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.setState({
                    loading: true
                });
                values.id = this.props.data.id;
                putCoursesLessons(values)
                    .then(result => {
                        if (result.code === 0) {
                            this.handleOK();
                        } else {
                            message.error(result.message);
                        }
                    })
                    .catch(err => {
                        message.error(err.message);
                    })
                    .finally(() => {
                        this.setState({
                            loading: false
                        })
                    });
            }
        });

    };

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {data} = this.props;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };
        return (
            <Modal
                title="添加课时"
                visible={!!data}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
                width={700}
            >
                {data &&
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <div>
                        <Divider orientation="left">基本信息</Divider>
                        <Form.Item label={"编号"}>
                            {getFieldDecorator("tag_index", {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入编号',
                                    },
                                ],
                                initialValue: data.tag_index
                            })(<InputNumber min={0}/>)}
                        </Form.Item>

                        <Form.Item label={"课时名称"}>
                            {getFieldDecorator("name", {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入课时名称',
                                    },
                                ],
                                initialValue: data.name

                            })(<Input/>)}
                        </Form.Item>
                        <Form.Item label={"课程描述"}>
                            {getFieldDecorator("description", {
                                initialValue: data.description
                            })(
                                <Input.TextArea autoSize={{minRows: 2}}/>
                            )}
                        </Form.Item>
                        <Form.Item label={"课程简述（公开）"}>
                            {getFieldDecorator("description2", {
                                initialValue: data.description2
                            })(
                                <Input.TextArea autoSize={{minRows: 2}}/>
                            )}
                        </Form.Item>
                        <Form.Item label={"课程目标"}>
                            {getFieldDecorator("objective", {
                                initialValue: data.objective
                            })(
                                <Input.TextArea autoSize={{minRows: 2}}/>
                            )}
                        </Form.Item>
                        <Form.Item label={"教学重难点"}>
                            {getFieldDecorator("points", {
                                initialValue: data.points
                            })(
                                <Input.TextArea autoSize={{minRows: 2}}/>
                            )}
                        </Form.Item>
                        <Form.Item label={"评价模板"}>
                            {getFieldDecorator("dianping_template", {
                                initialValue: data.dianping_template
                            })(
                                <Input.TextArea autoSize={{minRows: 2}}/>
                            )}
                        </Form.Item>
                        <Form.Item label="封面图">
                            {getFieldDecorator('image_url', {
                                initialValue: data.image_url
                            })(
                                <AliyunOSSUpload multiple={false}/>
                            )}
                        </Form.Item>

                        <Form.Item label="相关资源">
                            {getFieldDecorator('resources_config', {
                                initialValue: data.resources_config
                            })(
                                <ResourcesConfig courses_lessons_id={data.id}/>
                            )}
                        </Form.Item>

                        <Form.Item label="是否隐藏">
                            {getFieldDecorator('ishide', {
                                initialValue: data.ishide
                            })(
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value={0}>显示</Radio.Button>
                                    <Radio.Button value={1}>隐藏</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label="课程类型" name="type">
                            {getFieldDecorator('type', {
                                initialValue: data.type
                            })(
                                <Radio.Group buttonStyle="solid" onChange={(e) => {
                                    // let {data} = this.state;
                                    data.type = e.target.value;
                                    this.setState({})

                                }}>
                                    <Radio.Button value={0}>Scratch</Radio.Button>
                                    <Radio.Button value={1}>Python</Radio.Button>
                                    {/*<Radio.Button value="large">Large</Radio.Button>*/}
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </div>
                    {data.type === 0 && (
                        <div>
                            <div>
                                <Divider orientation="left">Scratch</Divider>

                            </div>
                            <Form.Item label={"课程模板编号"}>
                                {getFieldDecorator("scratch_id", {
                                    initialValue: data.scratch_id
                                })(
                                    <InputNumber min={0}/>
                                )}
                            </Form.Item>
                            <Form.Item label={"作业模板编号"}>
                                {getFieldDecorator("homework_scratch_id", {
                                    initialValue: data.homework_scratch_id
                                })(
                                    <InputNumber min={0}/>
                                )}
                            </Form.Item>
                            <Form.Item label={"参考答案"}>
                                {getFieldDecorator("homework_answer_id", {
                                    initialValue: data.homework_answer_id
                                })(
                                    <InputNumber min={0}/>
                                )}
                            </Form.Item>

                            <Form.Item label={"作业描述"}>
                                {getFieldDecorator("homework_desc", {
                                    initialValue: data.homework_desc
                                })(
                                    <Input.TextArea autoSize={{minRows: 2}}/>
                                )}
                            </Form.Item>

                            <Form.Item label="视频讲解">
                                {getFieldDecorator('video_url', {
                                    initialValue: data.video_url
                                })(
                                    <AliyunOSSUpload multiple={false}/>
                                )}
                            </Form.Item>
                        </div>
                    )}
                    {/*Python*/}
                    {data.type === 1 && (
                        <div>
                            <div>
                                <Divider orientation="left">Python</Divider>

                            </div>
                            <Form.Item label="必做练习">
                                {getFieldDecorator('homeworks_config1', {
                                    initialValue: data.homeworks_config1
                                })(
                                    <ResourcesConfig courses_lessons_id={data.id}/>
                                )}
                            </Form.Item>
                            <Form.Item label="进阶练习">
                                {getFieldDecorator('homeworks_config2', {
                                    initialValue: data.homeworks_config2
                                })(
                                    <ResourcesConfig courses_lessons_id={data.id}/>
                                )}
                            </Form.Item>

                        </div>
                    )}


                </Form>
                }
            </Modal>
        )
    }
}


LessonsAddModal.propTypes = {
    data: PropTypes.object,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

export default Form.create({name: 'lessons-add'})(LessonsAddModal);

