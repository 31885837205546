import axios from './rest-axios';

const getTrainings = (state) => axios.get(`/v1/training/`, state);

const getTraining = (id) => axios.get(`/v1/training/${id}`);

const postTraining = (data) => axios.post(`/v1/training/`, data);

const putTraining = (id, data) => axios.put(`/v1/training/${id}`, data);

const deleteTraining = (id) => axios.delete(`/v1/training/${id}`);

const putProblems  = (id, data)=> axios.put(`/v1/training/${id}/problems/`, data);
const getProblems  = (id,)=> axios.get(`/v1/training/${id}/problems/`);

export {
    getTrainings,
    getTraining,
    postTraining,
    putTraining,
    deleteTraining,
    putProblems,
    getProblems,
}