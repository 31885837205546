import React, {useState, useEffect} from 'react';
import {DatePicker, Form, Input, message, Modal, Radio} from "antd";
import {getContest, postContest, putContest} from "../../../lib/api_contest";
import moment from "moment";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};

const ContestAddModal = ({onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [duration, setDuration] = useState(0);
    const start_time = Form.useWatch('start_time', form);
    const end_time = Form.useWatch('end_time', form);

    useEffect(() => {
        if (end_time && start_time) {
            let duration = end_time.diff(start_time, "seconds");
            setDuration(duration);
        }
    }, [start_time, end_time])

    const handleFinish = () => {
        form.validateFields()
            .then((values) => {
                console.log('Received values of form: ', values);
                postContest({
                    ...values,
                    start_time: values.start_time.format("YYYY-MM-DD HH:mm:ss"),
                    end_time: values.end_time.format("YYYY-MM-DD HH:mm:ss"),
                    duration: values.end_time.diff(values.start_time, "seconds"),
                }).then(res => {
                    if (res.code === 0) {
                        message.success("保存成功!");
                        handleOk();
                    } else {
                        message.error(res.message);
                    }
                })
            })
    };

    const handleOk = () => {
        onOk && onOk();
    }

    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title={"创建比赛"}
            visible={true}
            onCancel={handleCancel}
            onOk={handleFinish}
            width={700}
        >
            <Form
                {...formItemLayout}
                form={form}
                onFinish={handleFinish}
                initialValues={{
                    type: 1,
                    auth: 0,
                    visible: 1,
                    user_id: 15,
                    author: "毛老师"
                }}
            >
                <Form.Item name="title" label="标题" rules={[{
                    required: true,
                    message: '请输入${label}！',
                },]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="type" label="赛制" rules={[{
                    required: true,
                    message: '请选择${label}！',
                },]}>
                    <Radio.Group>
                        <Radio value={0}>ACM</Radio>
                        <Radio value={1}>Rating/IOI</Radio>
                        <Radio value={2}>OI</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="start_time" label="开始时间" rules={[{
                    required: true,
                    message: '请选择${label}！',
                },]}>
                    <DatePicker showTime/>
                </Form.Item>
                <Form.Item name="end_time" label="结束时间" rules={[{
                    required: true,
                    message: '请选择${label}！',
                },]}>
                    <DatePicker showTime/>
                </Form.Item>
                <Form.Item label="比赛时长">
                    <span className="ant-form-text">{duration}</span>
                </Form.Item>
                <Form.Item name="auth" label="是否公开" rules={[{
                    required: true,
                    message: '请选择${label}！',
                },]}>
                    <Radio.Group>
                        <Radio value={0}>公开赛</Radio>
                        <Radio value={1}>私有赛（有密码）</Radio>
                        <Radio value={3}>保护赛（有密码）</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="pwd" label="密码">
                    <Input/>
                </Form.Item>

                <Form.Item name="description" label="比赛说明">
                    <Input.TextArea autoSize/>
                </Form.Item>
                <Form.Item name="visible" label="是否可见" rules={[{
                    required: true,
                    message: '请选择${label}！',
                },]}>
                    <Radio.Group>
                        <Radio value={0}>隐藏</Radio>
                        <Radio value={1}>可见</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="user_id" label="创建者id">
                    <Input/>
                </Form.Item>
                <Form.Item name="author" label="比赛创建者的用户名">
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>)
}

export default ContestAddModal;