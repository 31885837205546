/**
 * @name: 修改点评
 * @description:
 * @author: Wood
 * @date: 2024/3/6
 * @update:
 */
import React, {useState} from 'react';
import {Input, Modal, Form} from 'antd';
import {putLessonsUsersDianPing} from "../../../lib/api_lessons_users";
import default_form_item_layout from "../../../config/default_form_item_layout";

const DianMingEditModal = ({data, onOk, onCancel}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields()
            .then((values) => {
                setLoading(true)
                putLessonsUsersDianPing({
                    ...data,
                    ...values,
                })
                    .then(res => {
                        if (res.code === 0) {
                            handleOk();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
    };

    const handleOk = () => {
        onOk && onOk();
    }
    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title="修改点评"
            open={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            confirmLoading={loading}
            destroyOnClose={true}
        >
            <Form {...default_form_item_layout}
                  form={form}
                  initialValues={data}
            >
                <Form.Item
                    label={"点评"}
                    name={"dianping_content"}
                    rules={[{
                        required: true,
                        message: "请输入'${label}'"
                    }]}
                >
                    <Input.TextArea autoSize={{minRows: 3}}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default DianMingEditModal