import React, {useState, useEffect} from 'react';
import {DatePicker, Form, Input, message, Modal, Radio} from "antd";
import {getContest, postTraining, putContest} from "../../../lib/api_training";
import moment from "moment";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};

const TrainingAddModal = ({onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleFinish = () => {
        form.validateFields()
            .then((values) => {
                postTraining({
                    ...values,
                }).then(res => {
                    if (res.code === 0) {
                        message.success("保存成功!");
                        handleOk();
                    } else {
                        message.error(res.message);
                    }
                })
            })
    };

    const handleOk = () => {
        onOk && onOk();
    }

    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title={"创建题单"}
            visible={true}
            onCancel={handleCancel}
            onOk={handleFinish}
            width={700}
        >
            <Form
                {...formItemLayout}
                form={form}
                onFinish={handleFinish}
                initialValues={{
                    auth: 0,
                    visible: 1,
                    user_id: 15,
                    author: "毛老师"
                }}
            >
                <Form.Item name="id" label="编号" rules={[{
                    required: true,
                    message: '请输入${label}！',
                },]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="name" label="名称" rules={[{
                    required: true,
                    message: '请输入${label}！',
                },]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="description" label="比赛说明">
                    <Input.TextArea autoSize/>
                </Form.Item>
                <Form.Item name="visible" label="是否可见" rules={[{
                    required: true,
                    message: '请选择${label}！',
                },]}>
                    <Radio.Group>
                        <Radio value={0}>隐藏</Radio>
                        <Radio value={1}>可见</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="type" label="类型">
                    <Input/>
                </Form.Item>
                <Form.Item name="user_id" label="创建者id">
                    <Input/>
                </Form.Item>
                <Form.Item name="author" label="比赛创建者的用户名">
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>)
}

export default TrainingAddModal;