/**
 * @Description: 标签选择器，可多选
 * @author Wood
 * @date 2019/3/6
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Select} from 'antd';
import {tags as OPTIONS} from "../../../config/tagOptions";


class TagsSelector extends React.Component {
    state = {
        selectedItems: this.props.value ? JSON.parse(this.props.value) : []
    };

    handleChange = selectedItems => {
        this.setState({selectedItems});
        if (this.props.onChange) {
            this.props.onChange(JSON.stringify(selectedItems));
        }
    };

    render() {
        const {selectedItems} = this.state;
        const filteredOptions = OPTIONS.filter(o => !selectedItems.includes(o));
        return (
            <Select
                mode="multiple"
                placeholder="请选择标签"
                value={selectedItems}
                onChange={this.handleChange}
                style={{width: '100%'}}
            >
                {filteredOptions.map(item => (
                    <Select.Option key={item} value={item}>
                        {item}
                    </Select.Option>
                ))}
            </Select>
        )
    }
}

TagsSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};
export default TagsSelector;