import axios from './rest-axios';

const getContests = (state) => axios.get(`/v1/contest/`, state);

const getContest = (id) => axios.get(`/v1/contest/${id}`);

const postContest = (data) => axios.post(`/v1/contest/`, data);

const putContest = (id, data) => axios.put(`/v1/contest/${id}`, data);

const deleteContest = (id) => axios.delete(`/v1/contest/${id}`);

const getContestProblems = (id) => axios.get(`/v1/contest/${id}/problems`);
const getContestProblem = (id) => axios.get(`/v1/contest_problem/${id}`);
const postContestProblem = (id, data) => axios.post(`/v1/contest/${id}/problems`, data);
const putContestProblem = (id, data) => axios.put(`/v1/contest_problem/${id}`, data);
const deleteContestProblem = (id) => axios.delete(`/v1/contest_problem/${id}`);
const getContestRecords = (id, state) => axios.get(`/v1/contest/${id}/records`, state);

export {
    getContests,
    getContest,
    postContest,
    putContest,
    deleteContest,
    getContestProblems,
    getContestProblem,
    postContestProblem,
    putContestProblem,
    deleteContestProblem,
    getContestRecords,
}