/**
 * @name: 添加学生对话框
 * @description:
 * @author: Wood
 * @date: 2024/2/28
 * @update:
 */
import React from 'react';
import {postStudent,} from "../../../lib/api_students.js";
import {Modal, Input, Row, Col, Radio, DatePicker, Form} from 'antd';
import moment from 'moment';
import DictSelect from '../../../components/dict/dict-radio-group';


const StudentAddModal = ({onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                postStudent({
                    ...values,
                    birthday: values.birthday ? moment(values.birthday).format('YYYY-MM-DD') : null,
                    sign_up_time: values.sign_up_time ? moment(values.sign_up_time).format('YYYY-MM-DD') : null
                })
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk();
                        }
                    });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    const handleCancel = () => {
        onCancel && onCancel();
    }
    return (
        <Modal
            title="添加学生"
            open={true}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            destroyOnClose={true}
        >
            <Form
                form={form}
                initialValues={{
                    sex: 1,
                    sign_up_time: moment(),

                }}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="学生姓名"
                            name="nick_name"
                            rules={[{
                                required: true,
                                message: "请输入'${label}'"
                            }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="学生性别"
                            name="sex"
                            rules={[{
                                required: true,
                                message: "请选择'${label}'"
                            }]}
                        >
                            <Radio.Group>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="小名"
                            name="other_name"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="联系电话"
                            name="parent_phone"
                            rules={[{
                                required: true,
                                message: "请输入'${label}'"
                            }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="家长姓名"
                            name="parent_name"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="出生年月"
                            name="birthday"
                        >
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={16}>
                        <Form.Item
                            label="课程名称"
                            name="course_name"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="报名时间"
                            name="sign_up_time"
                        >
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label="备　　注"
                    name="remark"
                >
                    <Input.TextArea autosize={{minRows: 2}}/>
                </Form.Item>
                <Form.Item
                    label="年　　级"
                    name="grade"
                    extra="每年9月1日会自动进行升班，学龄前和成人除外">
                    <DictSelect type_value={"grade"}/>
                </Form.Item>
                <Form.Item
                    label="学　　校"
                    name="school"
                >
                    <DictSelect type_value={"schools"}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}


export default StudentAddModal;
