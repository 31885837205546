import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Input, Form, Radio, Row, Col, Button, InputNumber, Space, message, Card} from 'antd';
import MdEditor from '../../../components/md-editor/md-editor';
import {postQuestion, getQuestion} from "../../../lib/api_questions";
import {getPaper, putPaper} from "../../../lib/api_papers";
import MD from '../questions/md';
import styles from '../questions/examination_modal.module.css';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import {SortableItem} from '../questions/SortableItem';
import PageContent from "../../../components/page-content/page-content";


const ExaminationModal = ({id, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [config, setconfig] = useState({
        title: "试卷标题",
        desc: "试卷说明",
        score: 0,
        groups: []
    });

    const fetchData = () => {
        getPaper(id)
            .then(res => {
                if (res.code === 0) {
                    setconfig(JSON.parse(res.data.content))
                }
            })
    }

    useEffect(() => {
        fetchData()
    }, []);

    const onFinish = (values) => {
        let data = {
            id: id,
            content: JSON.stringify(config)
        }
        putPaper(data)
            .then(res => {
                if (res.code === 0) {
                    message.success("修改成功");
                    onOk();
                } else {
                }
            })
    }

    // useEffect(()=>{
    //     setconfig(JSON.parse(data.content))
    // }, []);

    // 重新编号
    const reBuild = () => {
        let index = 1;
        for (let i in config.groups) {
            let group = config.groups[i];
            for (let j in group.questions) {
                group.questions[j].id = index;
                index += 1;
            }
        }

        setconfig({...config});
    };

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                // console.log(values)
                values.type = "单选题";
                postQuestion(values)
                    // values.password = md5(values.password);
                    // postTeacher(values)
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const handleReload = (item) => {
        getQuestion(item.qid).then(res => {
            if (res.code === 0) {
                // console.log(item);
                // console.log(res.data);
                item.answer = res.data.answer;
                item.content = res.data.content;
                setconfig({...config});
            }
        })
    }

    const handleChangeScore = (item, score) => {
        item.score = score;
        setconfig({...config});
    };

    const handleChangeScoreMuti = (group) => {
        for (let i in group.questions) {
            group.questions[i].score = group.score_avg;
        }
        setconfig({...config});
    };

    const handleRefrushTitle = (group) => {
        let score = 0;
        for (let i in group.questions) {
            score += group.questions[i].score;
        }
        group.score = score;
        group.title = `${group.type}（共${group.questions.length}题，每题${group.score_avg}分，共${group.score}分）`;
        // setconfig({...config});
        handleStatScore()
    };

    const handleStatScore = () => {
        let score = 0;
        for (let i in config.groups) {
            score += config.groups[i].score
        }
        config.score = score;
        setconfig({...config});
    };

    const renderItem = (item) => {
        switch (item.type) {
            case "单选题": {
                let config = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <MD value={config.title}></MD>
                        <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                     addonAfter={"分"} style={{width: 100}}/>
                        <Button onClick={() => handleReload(item)}>重新加载</Button>
                        {/*{config.title}*/}
                    </div>
                    <div>
                        <Radio.Group value={answer.answer}>
                            <Space direction="vertical">
                                {config.options.map(option => (
                                    <Radio value={option.key}>
                                        <div className={styles.options}>
                                            <span>{option.key}.</span>
                                            <MD value={option.value}/>
                                        </div>
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </div>
                </div>)
            }
            case "判断题": {
                let config = JSON.parse(item.content);
                let answer = JSON.parse(item.answer);
                return (<div className={styles.question}>
                        <div className={styles.title}>
                            <span>{item.id}.</span>
                            <MD value={config.title}></MD>
                            <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                         addonAfter={"分"} style={{width: 100}}/>
                            {/*{config.title}*/}
                        </div>
                        <div>

                            <Radio.Group value={answer.answer}>
                                <Space direction="vertical">
                                    <Radio value={"正确"}>
                                        <div className={styles.options}>
                                            正确
                                        </div>
                                    </Radio>
                                    <Radio value={"错误"}>
                                        <div className={styles.options}>
                                            错误
                                        </div>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                            {/*{config.options.map(option=>(*/}
                            {/*<div className={styles.options}>*/}
                            {/*<span>{option.key}.</span>*/}
                            {/*<MD value={option.value} />*/}
                            {/*</div>*/}
                            {/*))}*/}
                        </div>
                    </div>
                )
            }
            case "python": {
                let config = JSON.parse(item.content);
                return (<div className={styles.question}>
                    <div className={styles.title}>
                        <span>{item.id}.</span>
                        <div style={{flex: 1}}>
                            <MD value={config.content}></MD>
                        </div>
                        <InputNumber value={item.score} onChange={(value) => handleChangeScore(item, value)}
                                     addonAfter={"分"} style={{width: 100}}/>
                        {/*{config.title}*/}
                    </div>
                    <div>
                        <div>评分标准：</div>
                        <MD value={config.pfbz}></MD>

                    </div>

                </div>)
            }
            default:
                return (
                    <div>
                        {item.content}
                    </div>
                )
        }

    };

    const renderGroup = (group) => {
        return (<div className={styles.group}>
            <div className={styles.groupTitle}>
                <Space>
                    <Input.Group compact>
                        <Input value={group.title} onChange={(e) => {
                            group.title = e.target.value;
                            setconfig({...config})
                        }} size="large" style={{width: 400}}/>
                        <Button size="large" onClick={() => handleRefrushTitle(group)}>刷新</Button>
                    </Input.Group>
                    <Input.Group compact>
                        <InputNumber size="large" addonAfter={"分"} style={{width: 100}}
                                     value={group.score_avg}
                                     onChange={(value) => {
                                         group.score_avg = value;
                                         setconfig({...config})
                                     }}
                        />
                        <Button size="large" onClick={() => handleChangeScoreMuti(group)}>批量设置</Button>

                    </Input.Group>
                </Space>
            </div>
            <div>
                {group.questions.map(renderItem)}
            </div>
        </div>)
    };
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const handleDragEnd = (event, group) => {
        const {active, over} = event;

        console.log(event)

        if (active.id !== over.id) {
            let items = group.questions;
            const oldIndex = items.findIndex((item) => {
                return active.id === item.id
            });
            const newIndex = items.findIndex((item) => {
                return over.id === item.id
            });
            group.questions = arrayMove(items, oldIndex, newIndex)
            console.log(group.questions, oldIndex, newIndex)

            // setconfig({...config});
            reBuild();

            // setItems((items) => {
            //     const oldIndex = items.indexOf(active.id);
            //     const newIndex = items.indexOf(over.id);
            //
            //     return arrayMove(items, oldIndex, newIndex);
            // });
        }
    };

    const handleSave = () => {
        console.log(config);
        putPaper({
            id: id,
            title: config.title,
            content: JSON.stringify(config),
            score: config.score,
        }).then(result => {
            if (result.code === 0) {
                onOk && onOk();
                message.success("生成成功！")
            }

        })
    }
    return (
        <PageContent title={"试卷编辑"}>
            <Card bordered={false}>
                <Row>
                    <Col flex={"200px"}>
                        <div style={{position: "fixed", top: 100, width: 200}}>
                            {config.groups.map(group => (
                                <div>
                                    <div>{group.type}</div>
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={event => handleDragEnd(event, group)}
                                    >
                                        <SortableContext
                                            items={group.questions}
                                            strategy={rectSortingStrategy}
                                        >
                                            {group.questions.map(item => <SortableItem key={item.id} id={item.id}
                                                                                       data={item}/>)}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            ))}
                            <div>
                                试卷总分：{config.score}
                            </div>
                            <div>
                                <Button>试卷预览</Button>
                                <Button onClick={handleSave}>保存试卷</Button>
                            </div>
                        </div>
                    </Col>
                    <Col flex={"1"}>
                        <div>
                            试卷标题：
                            <Input value={config.title} onChange={(e) => {
                                config.title = e.target.value;
                                setconfig({...config});
                            }}/>
                            试卷说明：
                            {/*<MdEditor />*/}
                            <MdEditor
                                value={config ? config.desc : ""}
                                onChange={(value) => {
                                    config.desc = value;
                                    setconfig({...config});
                                }}/>
                        </div>
                        {config.groups.map(renderGroup)}
                    </Col>
                </Row>
            </Card>
        </PageContent>
    )
};


ExaminationModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default ExaminationModal;