/**
 * @name: 班级内容表单
 * @description:
 * @author: Wood
 * @date: 2024/3/6
 * @update:
 */
import React from 'react';
import {Input, Radio, Form} from 'antd';
import TeacherSelect from './teacher-select';
import CoursesSelect from './courses-select';
import TagsSelect from './tags_select';
import FormDataSelect from '../../../components/data/form-data-select';
import default_form_item_layout from "../../../config/default_form_item_layout";

const ClassesForm = ({form, initialValues = {}}) => {
    return (
        <Form
            {...default_form_item_layout}
            form={form}
            initialValues={initialValues}
        >
            <Form.Item
                label={"班级名称"}
                name="name"
                rules={[{
                    required: true,
                    message: "请输入'${label}'"
                }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={"备注"}
                name="remark"
            >
                <Input.TextArea autosize={{minRows: 5}}/>
            </Form.Item>
            <Form.Item
                label={"讲师"}
                name="creater"
            >
                <TeacherSelect/>
            </Form.Item>
            <Form.Item
                label={"课程"}
                name="lessons_id"
            >
                <CoursesSelect/>
            </Form.Item>
            <Form.Item
                label={"结课"}
                name="status"
                rules={[{
                    required: true,
                    message: "请选择'${label}'"
                }]}
            >
                <Radio.Group>
                    <Radio value={0}>正常上课</Radio>
                    <Radio value={1}>已结课</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label={"标签"}
                name="tags"
            >
                <TagsSelect/>
            </Form.Item>
            <Form.Item
                label={"教室"}
                name="room_id"
            >
                <FormDataSelect table={"rooms"} labelkey={"name"} valuekey={"id"} valueType={"number"}/>
            </Form.Item>
        </Form>
    )
}

export default ClassesForm;