import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {putLessons} from "../../../lib/api_lessons";
import {Modal, Input, Tag, DatePicker, InputNumber, Form, Switch,} from 'antd';
import moment from 'moment';
import TeacherSelect from './teacher-select';
import CoursesLessonsSelect from './courses_lessons_select';
import FormDataSelect from '../../../components/data/form-data-select';
import default_form_item_layout from "../../../config/default_form_item_layout";

const LessonEditModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const durationValue = Form.useWatch('duration', form);
    const courseTimeValue = Form.useWatch('course_time', form);
    const courseEndTimeValue = Form.useWatch('course_end_time', form);
    // 修改课程信息
    const handleFinish = () => {
        form.validateFields()
            .then(values => {
                values.course_time = values.course_time.format("YYYY-MM-DD HH:mm");
                values.course_end_time = values.course_end_time.format("YYYY-MM-DD HH:mm");
                // values.courses_lessons_id = 0;
                values.course_id = values.course[0];
                values.courses_lessons_id = values.course[1];
                values.isloop = values.isloop ? 1 : 0;
                // console.log(values);
                setLoading(true);
                putLessons(data.id, values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk()
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            });

    };
    // 设置时长
    const setDuration = (duration) => {
        form.setFieldsValue({
            duration: duration,
        })
    };

    useEffect(() => {
        let values = form.getFieldsValue();
        if (values.course_time) {
            form.setFieldsValue({
                course_end_time: moment(values.course_time).add(values.duration, "m")
            })
        }
    }, [courseTimeValue, durationValue]);
    useEffect(() => {
        let values = form.getFieldsValue();
        if (values.course_time && values.course_end_time) {
            let duration = values.course_end_time.diff(values.course_time, "m");
            setDuration(duration);
        }
    }, [courseEndTimeValue]);


    let ndata = Object.assign({
        name: "",
        course_time: moment(),
        duration: 60,
        order_index: 0,
        keshi: 1,
        remark: "",
        teacher_id: 0,
        course_id: 0,
        courses_lessons_id: 0,
        isloop: true,
        loopdays: 0,
    }, data);

    return (
        <Modal
            title="修改课程信息"
            open={true}
            onOk={handleFinish}
            onCancel={onCancel}
            confirmLoading={loading}
            destroyOnClose={true}
        >
            <Form
                {...default_form_item_layout}
                form={form}
                initialValues={{
                    name: ndata.name,
                    course_time: moment(ndata.course_time),
                    duration: ndata.duration,
                    course_end_time: moment(ndata.course_time).add(ndata.duration, "m"),
                    order_index: ndata.order_index,
                    keshi: ndata.keshi,
                    remark: ndata.remark,
                    teacher_id: ndata.teacher_id,
                    // course_id: ndata.course_id,
                    // courses_lessons_id: ndata.courses_lessons_id,
                    course: [ndata.course_id, ndata.courses_lessons_id],
                    room_id: ndata.room_id,
                    isloop: ndata.isloop,
                    loopdays: ndata.loopdays,
                }}
            >
                <Form.Item label="课程名称" name="name"
                           rules={[{required: true, message: '请输入课程名称！'}]}>
                    <Input placeholder="请输入课程名称"/>
                </Form.Item>
                <Form.Item label={"安排开始上课时间"} name={"course_time"}>
                    <DatePicker showTime format={'YYYY-MM-DD HH:mm'}/>
                </Form.Item>
                <Form.Item label={"时长"}>
                    <Form.Item name={"duration"} noStyle>
                        <InputNumber/>
                    </Form.Item>
                    <div>
                        <Tag onClick={() => setDuration(30)}>30分钟</Tag>
                        <Tag onClick={() => setDuration(45)}>45分钟</Tag>
                        <Tag onClick={() => setDuration(60)}>60分钟</Tag>
                        <Tag onClick={() => setDuration(90)}>90分钟</Tag>
                    </div>
                </Form.Item>
                <Form.Item label={"安排上课结束时间"} name={"course_end_time"}>
                    <DatePicker showTime format={'YYYY-MM-DD HH:mm'}/>
                </Form.Item>
                <Form.Item label={"排序"} name={"order_index"}>
                    <InputNumber min={0} step={1} placeholder="请输入排序编号"/>
                </Form.Item>
                <Form.Item label={"消耗课时"} name={"keshi"}>
                    <InputNumber placeholder="请输入消耗课时"/>
                </Form.Item>
                <Form.Item label={"讲师"} name={"teacher_id"}>
                    <TeacherSelect/>
                </Form.Item>
                <Form.Item label={"备注"} name={"remark"}>
                    <Input.TextArea autosize={{minRows: 2, maxRows: 6}}/>
                </Form.Item>
                <Form.Item label={"课程"} name={"course"}>
                    <CoursesLessonsSelect/>
                </Form.Item>
                <Form.Item label={"教室"} name={"room_id"}>
                    <FormDataSelect table={"rooms"} labelkey={"name"} valuekey={"id"}/>
                </Form.Item>
                <Form.Item label={"循环"} name={"isloop"} valuePropName="checked">
                    <Switch/>
                </Form.Item>
                <Form.Item label={"循环天数"} name={"loopdays"}>
                    <InputNumber placeholder="请输入循环天数"/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

LessonEditModal.propTypes = {
    data: PropTypes.object.isRequired,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};


export default LessonEditModal;
