/**
 * @name: 登录后的菜单
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/4/24
 */
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Avatar, Button } from 'antd';
import styles from './account-menu.module.css';
import ChangePassword from '../../containers/change-password';
import {showPasswordModal} from "../../reducers/password-modal";

const AccountMenu = (props) => (
    <div className={styles.container}>
        {/*<Button href={"http://scratch.yunqilab.com/"} style={{marginRight:10}}>Scratch3.0</Button>*/}
        {/*<Button href={"http://hacker.yunqilab.com/"} style={{marginRight:10}}><img src={zhitilogo} style={{height:20}} /></Button>*/}
        <Dropdown overlay={
            <Menu>
                {/*<Menu.Item>*/}
                    {/*<a href='http://scratch.yunqilab.com/my/settings'>个人设置</a>*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Divider />*/}
                <Menu.Item>
                    <a onClick={() => props.showPasswordModal()}>修改密码</a>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item>
                    <span onClick={props.onLogout}>退出登录</span>
                </Menu.Item>
            </Menu>}>
            <div className="">
                {/*<Avatar src={props.profile} />*/}
                {props.nick_name} <DownOutlined />
            </div>
        </Dropdown>
        <ChangePassword/>
    </div>
);

AccountMenu.propTypes = {
    nick_name: PropTypes.string.isRequired,
    profile:PropTypes.string.isRequired,
    onLogout: PropTypes.func
};


const mapStateToProps = state => ({
    // passwordModal: state.passwordModal
});

const mapDispatchToProps = dispatch => ({
    showPasswordModal: ()=> dispatch(showPasswordModal()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountMenu);