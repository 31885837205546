/**
 * @name: 左侧菜单
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/4/21
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Input, Button, Checkbox} from 'antd';

class TestcasesEdit extends Component {
    state = {
        testcases: []
    };

    componentDidMount = () => {
        console.log("value", this.props.value)
    };

    componentWillReceiveProps(var1, var2) {
        // console.log("var1", var1);
        // console.log("var2", var2);

        if (var1 && var1.value) {
            this.setState({
                testcases: JSON.parse(var1.value)
            })
        }
    }

    handleAdd = () => {
        this.state.testcases.push({
            input: "",
            output: "",
            is_sample: 0,
            is_select: 1,
        });
        this.setState({})
        this.handleChange();
    };

    handleRemove = (index) => {
        this.state.testcases.splice(index, 1)
        this.setState({});
        this.handleChange();
    };

    handleChange = () => {
        this.props.onChange && this.props.onChange(JSON.stringify(this.state.testcases));
    };

    render() {
        let {testcases} = this.state;
        return (
            <div>
                <div className="ant-table ant-table-small ant-table-scroll-position-left ant-table-bordered">
                    <div className="ant-table-content">
                        <div className="ant-table-body">
                            <table>
                                <thead className="ant-table-thead">
                                <tr>
                                    <th>输入</th>
                                    <th>输出</th>
                                    <th>样例</th>
                                    <th>校验</th>
                                    <th>删除</th>
                                </tr>
                                </thead>
                                <tbody className="ant-table-tbody">

                                {testcases.map((testcase, index) => (
                                    <tr className="ant-table-row" key={index}>
                                        <td>
                                            <Input.TextArea value={testcase.input} onChange={(value) => {
                                                testcase.input = value.target.value;
                                                this.setState({});
                                                this.handleChange();
                                            }}/>
                                        </td>
                                        <td>
                                            <Input.TextArea value={testcase.output} onChange={(value) => {
                                                testcase.output = value.target.value;
                                                this.setState({});
                                                this.handleChange();
                                            }}/>
                                        </td>
                                        <td>
                                            <Checkbox checked={testcase.is_sample === 1} onChange={(e) => {
                                                testcase.is_sample = e.target.checked ? 1 : 0;
                                                this.setState({});
                                                this.handleChange();
                                            }}/>
                                        </td>
                                        <td>
                                            <Checkbox checked={testcase.is_select === 1} onChange={(e) => {
                                                testcase.is_select = e.target.checked ? 1 : 0;
                                                this.setState({});
                                                this.handleChange();
                                            }}/>
                                        </td>
                                        <td>
                                            <Button onClick={() => this.handleRemove(index)}>删除</Button>
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Button onClick={this.handleAdd}>添加</Button>
            </div>
        )
    }
}


TestcasesEdit.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default TestcasesEdit;