/**
 * @name: 账户信息相关接口
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update: 2019/4/24
 */
import axios from './rest-axios';

const getAccountInfo = () => axios.get('/v1/account/info');
const postAccountLogin = (login_name, password) => axios.post('/v1/account/login', {login_name, password});
const postChangePassword = (oldpassword, newpassword) => axios.post('/v1/account/changepassword', {
    oldpassword,
    newpassword
});

export {
    getAccountInfo,
    postAccountLogin,
    postChangePassword,
};
