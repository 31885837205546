import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Modal, Input, DatePicker, Radio, Form, InputNumber} from 'antd';
// import {postKeshi} from "../../../lib/api_lessons";
import moment from 'moment';
import {postCardBuLu} from "../../../lib/api_card";

const BuLuModal = ({data, visible, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [avg, setAvg] = useState(0);

    console.log(data);

    const handleBuLu = () => {
        form
            .validateFields()
            .then((values) => {
                // values.user_id = user_id;
                // // 提交数据到服务器
                postCardBuLu({
                    payments: values.payments,
                    keshi: values.keshi,
                    user_id: data.user_id,
                    name: values.name,
                    keshi_id: data.id,
                    remark: values.remark,
                    order_index: values.order_index,
                    is_gift: values.is_gift
                })
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const jisuanAvg = () => {
        let keshi = form.getFieldValue("keshi");
        let payments = form.getFieldValue("payments");
        if (keshi !== 0 && payments) {
            setAvg(Math.ceil(payments / keshi))
        } else {
            setAvg(0);
        }
    };

    return (
        <Modal
            title="补录支付信息"
            visible={visible}
            onOk={handleBuLu}
            onCancel={onCancel}
        >
            <Form
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                form={form}
                initialValues={{
                    create_on: moment(data.create_on),
                    keshi: data.keshi,
                    remark: data.remark
                }}
            >
                <Form.Item
                    label={"课程名称"}
                    name={"name"}
                    rules={[{
                        required: true,
                        message: '请输入课程名称'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={"报课时间"}
                    name={"create_on"}
                    rules={[{
                        required: true,
                        message: '请选择报课时间'
                    }]}
                >
                    <DatePicker
                        format={"YYYY-MM-DD HH:mm"}
                        showTime={{format: 'HH:mm'}}
                    />
                </Form.Item>
                <Form.Item
                    label={"课时"}
                    name={"keshi"}
                    rules={[{
                        required: true,
                        message: '请输入课时'
                    }]}
                >
                    <InputNumber onChange={jisuanAvg}/>
                </Form.Item>
                <Form.Item
                    label={"赠送课时"}
                    name={"is_gift"}
                    rules={[{
                        required: true,
                        message: '请选择'
                    }]}
                >
                    <Radio.Group>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="实际支付总金额"
                    rules={[
                        {
                            required: true,
                            message: '请输入支付金额',
                        },
                    ]}>
                    <Form.Item
                        // label="支付金额"
                        name="payments"
                        noStyle
                        rules={[
                            {
                                required: true,
                                message: '请输入支付金额',
                            },
                        ]}
                    >
                        <InputNumber onChange={jisuanAvg}/>

                    </Form.Item>
                    <div>
                        每课时：{avg}元<br/>
                        {/*{this.state.error ? <Text type="danger">{this.state.error}</Text> :""}*/}
                        {/*{payments % keshi_original !== 0}*/}
                    </div>
                </Form.Item>
                <Form.Item label={"备注"} name={"remark"}>
                    <Input.TextArea/>
                </Form.Item>
                {/*<Form.Item*/}
                {/*label={"赠送课时"}*/}
                {/*name={"is_gift"}*/}
                {/*rules={[{*/}
                {/*required: true,*/}
                {/*message: '请选择'*/}
                {/*}]}*/}
                {/*>*/}
                {/*<Radio.Group>*/}
                {/*<Radio value={1}>是</Radio>*/}
                {/*<Radio value={0}>否</Radio>*/}
                {/*</Radio.Group>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    label="支付顺序（从小到大）"
                    name="order_index"
                    rules={[
                        {
                            required: true,
                            message: '请输入支付顺序（从小到大）',
                        },
                    ]}
                >
                    <InputNumber/>

                </Form.Item>
            </Form>
        </Modal>
    )
};

BuLuModal.propTypes = {
    visible: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    data: PropTypes.object
};

export default BuLuModal;