import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Space, Tag} from 'antd';
import tags from './tags_config';


const Tags_select = ({value,onChange})=>{
    const [selectedTags,setSelectedTags] = useState([]);
    const  handleChange = (tag, checked)=>{
        let selectedTags = [];
        if(value){
            selectedTags = JSON.parse(value);
        }
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        onChange && onChange(JSON.stringify(nextSelectedTags));
    };

    useEffect(()=>{
        let selectedTags = [];
        if(value){
            selectedTags = JSON.parse(value);
        }
        setSelectedTags(selectedTags);
    },[value])

    return (
        <Space size={[0,5]} wrap={true}>
            {tags.map(tag=>(
                <Tag.CheckableTag
                    key={tag}
                    checked={selectedTags.indexOf(tag) > -1}
                    onChange={checked => handleChange(tag, checked)}
                >
                    {tag}
                </Tag.CheckableTag>
            ))}
        </Space>
    )
}

Tags_select.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number
};

export default (Tags_select);
