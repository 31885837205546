import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Upload, Button} from 'antd';
import {getSTSTestcases} from "../lib/api_sts";
import SparkMD5 from 'spark-md5';
import AliOSS from 'ali-oss';

const AliyunOSSUpload = ({multiple, value, onChange, prefix, showUploadList}) => {
    const [fileList, setfileList] = useState([]);

    const init = () => {
        let fileList = [];
        if (value) {
            if (!multiple) {
                fileList.push({
                    uid: -1,
                    name: value.slice(-10),
                    url: value,
                    status: 'done',
                })
            } else {
                let uid = -1;
                fileList = JSON.parse(value);
                fileList.forEach(f => {
                    f.uid = uid;
                    f.status = "done";
                    uid--;
                });
            }
            setfileList(fileList);
        }
    };

    useEffect(init, []);

    const handleChange = ({fileList, file, event}) => {
        console.log("onChange", fileList, file, event);
        if (!multiple) {
            // 单文件处理
            let url = "";
            fileList = fileList.slice(-1);
            fileList = fileList.map(file => {
                if (file.response) {
                    // Component will show file.url as link
                    url = file.url = file.response.url;
                    console.log(file.response.url)
                }
                return file;
            });
            setfileList(fileList);
            onChange && onChange(url);
        } else {
            // 多文件处理
            fileList = fileList.map(file => {
                if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                    console.log(file.response.url)
                }
                return file;
            });
            setfileList(fileList);
            onChange && onChange(JSON.stringify(
                fileList.map(f => {
                    return {
                        name: f.name,
                        url: f.url,
                        type: f.type,
                        size: f.size,
                    }
                })
            ));
        }
    };

    const customRequest = async (info) => {
        console.log(info);
        console.log(info.file.arrayBuffer());
        let md5 = await getmd5(info.file);
        // console.log("md5",md5)
        const suffix = info.file.name.slice(info.file.name.lastIndexOf('.'));
        console.log("suffix", suffix);
        let alikey = prefix + md5 + suffix;
        let res = await getSTSTestcases(alikey);
        const client = new AliOSS({
            region: 'oss-cn-hangzhou',
            // 云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
            accessKeyId: res.AccessKeyId,
            accessKeySecret: res.AccessKeySecret,
            stsToken: res.SecurityToken,
            bucket: 'yzsteam'
        });
        // info.onProgress();
        client.multipartUpload(res.key, info.file)
            .then(result => {
                // resolve(result);
                info.onSuccess({
                    uid: alikey,
                    name: info.file.name,
                    status: 'done',
                    // response: 'Server Error 500', // custom error message to show
                    url: `https://yzsteam.oss-cn-hangzhou.aliyuncs.com/${alikey}`,
                    type: info.file.type,
                    size: info.file.size,
                }, info.file);
                console.log(result, info)
                // console.log(`${url}${alikey}`)
                // this.handleImageInsert(`${url}${alikey}`);
            })
            .catch(error => {
                // reject(error);
                info.onError(error);
                console.log(error);
                // this.props.ErrorStatus(error);
            });
    };


    const getmd5 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                const spark = new SparkMD5.ArrayBuffer();
                spark.append(reader.result);
                let md5 = spark.end();
                resolve(md5)
            };
            reader.onerror = (err) => {
                reject(err)
            };
            reader.readAsArrayBuffer(file);
        });
    };

    return (
        <Upload {...{
            name: 'file',
            fileList: fileList,
            onChange: handleChange,
            customRequest: customRequest,
            showUploadList: showUploadList,
            multiple: multiple
        }}>
            <Button>
                <UploadOutlined/> 上传
            </Button>
        </Upload>
    );
};


AliyunOSSUpload.propTypes = {
    value: PropTypes.string,
    prefix: PropTypes.string,
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
    showUploadList: PropTypes.bool
};

AliyunOSSUpload.defaultProps = {
    multiple: false,
    showUploadList: true,
    prefix: "courses/"
};

export default AliyunOSSUpload;