/**
 * @name: 用户信息reducer
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import cookies from 'js-cookie';

const SET_ACCOUNT = 'account/SET_ACCOUNT';
const SIGN_OUT = 'account/SIGN_OUT';

const initialState = null;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    let config = window.location.host.endsWith('yzsteam.com') ? {'domain': 'yzsteam.com', expires: 7} : {expires: 7};
    switch (action.type) {
        case SET_ACCOUNT:
            cookies.set('authorization', action.account.login_key, config);
            // cookies.set('authorization', action.account.login_key);
            return {...action.account};
        case SIGN_OUT:
            cookies.remove('authorization', config);
            // cookies.remove('authorization');
            return null;
        default:
            return state;
    }
};

const setAccount = function (account) {
    return {
        type: SET_ACCOUNT,
        account: account
    }
};

const signOut = function () {
    return {
        type: SIGN_OUT,
        account: null
    }
};

export {
    reducer as default,
    initialState as initialAccountState,
    setAccount,
    signOut
}