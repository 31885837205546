/**
 * @name: python作业
 * @description:
 * @author: Wood
 * @date: 2019/4/21
 * @update:
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, message, Input, Radio, Switch, Select, Card } from 'antd';
import PageContent from '../../../components/page-content/page-content';
import MdEditor from '../../../components/md-editor/md-editor';
import TestcasesEdit from '../../../components/testcases-edit/testcases-edit';
import {postChallenge} from "../../../lib/api_challenges";

class ChallengesAdd extends Component {
    state = {
    };

    componentDidMount() {

    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);

                // 数据处理以及保存
                let config = {
                    name: values.name,
                    description: values.description || "",
                    statement: values.statement || "",
                    input_format: values.input_format || "",
                    constraints: values.constraints || "",
                    output_format: values.output_format || "",
                    testcases: JSON.parse(values.testcases || "[]")
                };
                console.log(config)

                postChallenge({
                    pkey: values.pkey,
                    name: values.name,
                    description: values.description,
                    statement: values.statement,
                    input_format: values.input_format,
                    constraints: values.constraints,
                    output_format: values.output_format,
                    tags: JSON.stringify(values.tags),
                    difficulty: values.difficulty,
                    is_public: values.is_public ? 1 : 0,
                    config: JSON.stringify(config),
                    tutorial_content: values.tutorial_content,
                }).then(result => {
                    if (result.code === 0) {
                        message.success("添加成功！");
                        this.props.history.go(-1);
                    } else {
                        message.error(result.message);
                    }
                })
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        return (
            <PageContent title={"添加题目"}>
                <Card bordered={false}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item label="编号">
                        {getFieldDecorator('pkey', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入编号',
                                },
                            ],
                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item label="题目名称">
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入题目名称',
                                },
                            ],
                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item label="描述">
                        {getFieldDecorator('description')(<Input.TextArea/>)}
                    </Form.Item>
                    <Form.Item label="难度">
                        {getFieldDecorator('difficulty', {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择难度',
                                },
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={0}>简单</Radio>
                                <Radio value={1}>一般</Radio>
                                <Radio value={2}>困难</Radio>
                                <Radio value={3}>高级</Radio>
                                <Radio value={4}>专家</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                    <Form.Item label="题目背景">
                        {getFieldDecorator('statement')(
                            <MdEditor/>
                        )}
                    </Form.Item>
                    <Form.Item label="输入格式">
                        {getFieldDecorator('input_format')(
                            <MdEditor/>
                        )}
                    </Form.Item>
                    <Form.Item label="约束">
                        {getFieldDecorator('constraints')(
                            <MdEditor/>
                        )}
                    </Form.Item>
                    <Form.Item label="输出格式">
                        {getFieldDecorator('output_format')(
                            <MdEditor/>
                        )}
                    </Form.Item>
                    <Form.Item label="标签">
                        {getFieldDecorator('tags')(
                            <Select mode="tags" placeholder="Tags Mode">
                                <Select.Option key={"循环"}>循环</Select.Option>
                                <Select.Option key={"条件判断"}>条件判断</Select.Option>
                                <Select.Option key={"排序"}>排序</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="公开">
                        {getFieldDecorator('is_public', {valuePropName: 'checked'})(<Switch/>)}
                    </Form.Item>
                    <Form.Item label="测试用例">
                        {getFieldDecorator('testcases')(<TestcasesEdit/>)}
                    </Form.Item>

                    <Form.Item wrapperCol={{span: 12, offset: 4}}>
                        <Button type="primary" htmlType="submit">
                            提交保存
                        </Button>
                    </Form.Item>
                </Form>
                </Card>

            </PageContent>
        )
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({name: 'challenges_create'})(ChallengesAdd)));

