import React from 'react';
import {Checkbox, DatePicker, Modal, Form} from "antd";
import default_form_item_layout from "../../../config/default_form_item_layout";
import {postDianmingLessons} from "../../../lib/api_lessons";


const DianMingModal = ({id, students, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);

    // 点名
    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                setLoading(true);
                postDianmingLessons(id, values)
                    .then(res => {
                        if (res.code === 0) {
                            handleOk();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            })
    };

    const handleOk = () => {
        onOk && onOk();
    }

    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title="点名"
            open={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <Form {...default_form_item_layout} form={form}>
                <Form.Item label={"点名时间"} name={"time"}>
                    <DatePicker showTime format={'YYYY-MM-DD HH:mm'}/>
                </Form.Item>
                <Form.Item label={"选择学生"} name={"users"}>
                    <Checkbox.Group
                        options={students.map(item => {
                            return {label: item.nick_name, value: item.id}
                        })}
                    >
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default DianMingModal;