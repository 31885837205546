import axios from './rest-axios';

const getTeachers = () => axios.get(`/v1/teachers`);
const postTeacher = (model) => axios.post(`/v1/teachers`, model);
const putTeacher = (model) => axios.put(`/v1/teachers/${model.id}`, model);
const resetPassword = (id, password) => axios.post(`/v1/teachers/${id}/resetpassword`, {password});


export {
    getTeachers,
    postTeacher,
    putTeacher,
    resetPassword
};
