import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Descriptions, Space, Table, Tabs} from "antd";
import {getContest, getContests} from "../../../lib/api_contest";
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";
import moment from "moment";
import ContestProblemList from "./contest_problem_list";
import ContestRecordList from "./contest_record_list";
import MarkdownHtml from '../../../components/markdown-html/markdown-html';
import ContestType from "./contest_type";
import ContestEditModal from "./contest_edit_modal";

const ContestDetail = ({id}) => {
    const [data, setData] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const fetchData = () => {
        getContest(id)
            .then(res => {
                if (res.code === 0) {
                    setData(res.data);
                }
            })
    }

    useEffect(fetchData, []);


    return (
        <PageContent title={"比赛详情"}>
            <Card bordered={false}>
                <Descriptions column={2} title={data?.title} extra={[<Space>
                    <Button onClick={() => setShowEditModal(true)}>修改</Button>
                </Space>]}>
                    <Descriptions.Item
                        label="赛制">
                        <ContestType type={data?.type}/>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="状态">{data?.status === -1 ? "未开始" : data?.status === 0 ? "进行中" : "已结束"}</Descriptions.Item>
                    {/*<Descriptions.Item label="来源">{data?.source}</Descriptions.Item>*/}
                    <Descriptions.Item
                        label="是否公开">{data?.auth === 0 ? "公开赛" : data?.auth === 1 ? "私有赛" : "保护赛"}</Descriptions.Item>
                    <Descriptions.Item label="密码">{data?.pwd}</Descriptions.Item>
                    <Descriptions.Item
                        label="开始时间">{moment(data?.start_time).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
                    <Descriptions.Item
                        label="结束时间">{moment(data?.end_time).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
                    <Descriptions.Item label="时长">{data?.duration}</Descriptions.Item>
                    <Descriptions.Item label="是否可见">{data?.visible ? "显示" : "隐藏"}</Descriptions.Item>
                    <Descriptions.Item label="创建者">{data?.author}</Descriptions.Item>
                </Descriptions>
            </Card>
            <Card bordered={false} style={{marginTop:12}}>
                <Tabs>
                    <Tabs.TabPane tab={"比赛说明"} key={"比赛说明"}>
                        <MarkdownHtml markdown={data?.description}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"题目"} key={"题目"}>
                        <ContestProblemList id={id}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"提交记录"} key={"提交记录"}>
                        <ContestRecordList id={id}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"排名"} key={"排名"}></Tabs.TabPane>
                </Tabs>
            </Card>
            {showEditModal && <ContestEditModal
                id={id}
                onOk={() => {
                    fetchData();
                    setShowEditModal(false);
                }}
                onCancel={() => {
                    setShowEditModal(false);
                }}
            />
            }

        </PageContent>
    );
}

export default ContestDetail;