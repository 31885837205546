/**
 * @name: 学生列表
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update:
 */
import React, {useState, useEffect} from 'react';
import {getStudents} from "../../../lib/api_students.js";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Tabs, Input, Card} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import StudentAddModal from './student_add_modal';
import StudentList from './student_list';

const Students = () => {
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);

    // 获取学生列表
    const loadStudents = () => {
        getStudents(0)
            .then((result) => {
                if (result.code === 0) {
                    setList(result.data);
                }
            })
    };

    useEffect(loadStudents, []);

    let curList = list.filter(item => item.is_file === 0 && (filter
        ? (item.nick_name && item.nick_name.includes(filter))
        || (item.login_name && item.login_name.includes(filter))
        || (item.other_name && item.other_name.includes(filter))
        || (item.parent_phone && item.parent_phone.includes(filter))
        || (item.remark && item.remark.includes(filter))
        : true));
    let endList = list.filter(item => item.is_file === 1 && (filter
        ? (item.nick_name && item.nick_name.includes(filter))
        || (item.login_name && item.login_name.includes(filter))
        || (item.other_name && item.other_name.includes(filter))
        || (item.parent_phone && item.parent_phone.includes(filter))
        || (item.remark && item.remark.includes(filter))
        : true));

    return (
        <PageContent title={"学生管理"} routes={[
            {
                path: '',
                breadcrumbName: "首页",
            },
            {
                path: 'students',
                breadcrumbName: '学生管理',
            },
        ]}>
            <Card bordered={false}>
                <Button type='primary' icon={<PlusOutlined/>} onClick={() => setShowAddModal(true)}>添加学生</Button>
                <Input
                    placeholder="关键字搜索"
                    onChange={e => setFilter(e.target.value)}
                    style={{width: 200, marginLeft: 10}}
                    allowClear={true}
                />
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={`进行中(${curList.length})`} key="1">
                        <StudentList list={curList} classes_id={0} onChange={() => this.loadStudents()}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`已归档(${endList.length})`} key="2">
                        <StudentList list={endList} classes_id={0} onChange={() => this.loadStudents()}/>
                    </Tabs.TabPane>
                </Tabs>
                {showAddModal &&
                    <StudentAddModal
                        onOk={() => {
                            loadStudents();
                            setShowAddModal(false);
                        }}
                        onCancel={() => {
                            setShowAddModal(false);
                        }}
                    />
                }
            </Card>
        </PageContent>
    );
}

export default Students;