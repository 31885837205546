import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Select} from 'antd';
import {getList} from "../../lib/api_data";

const FormDataSelect = ({value, onChange, table, labelkey, valuekey, valueType="string", filters={}, sorter={}, ...props}) => {

    const [options, setOptions] = useState([]);
    const [rvalue, setRValue] = useState(null);


    // 获取数据
    useEffect(() => {
        if (value) {
            setRValue(value)
            // if(typeof value === "number"){
            //     setRValue(value.toString())
            // }else{
            //     setRValue(value)
            // }
        }
        // console.log(value)
    }, [value]);

    useEffect(() => {
        if (value) {
            setRValue(value)
            // if(typeof value === "number"){
            //     setRValue(value.toString())
            // }else{
            //     setRValue(value)
            // }
        }
        let params = {};

        params.filters = filters;
        params.sorter = sorter;

        getList(table, params)
            .then(res => {
                if (res.code === 0) {
                    setOptions(res.data.map(_ => {
                        if(valueType === "number"){
                            return {
                                label: _[labelkey].toString(),
                                value: parseInt(_[valuekey])
                            }
                        }else{
                            return {
                                label: _[labelkey].toString(),
                                value: _[valuekey].toString()
                            }
                        }
                    }))
                }
            })
    }, []);


    const handleChange = (e) => {
        setRValue(e);
        onChange && onChange(e)
    };

    return (
        <Select
            options={options}
            value={rvalue}
            onChange={handleChange}
            {...props}
        />
    );
};

FormDataSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    table: PropTypes.string,
    labelkey: PropTypes.string,
    valuekey: PropTypes.string,
};

export default FormDataSelect;
