/**
 * @name: 创建班级
 * @description:
 * @author: Wood
 * @date: 2024/3/6
 * @update:
 */
import React, {useState} from 'react';
import {Modal, Form} from 'antd';
import {postClasses} from "../../../lib/api_classes.js";
import ClassesForm from "./classes_form";

const ClassesAddModal = ({onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields()
            .then((values) => {
                setLoading(true);
                postClasses(values)
                    .then(res => {
                        if (res.code === 0) {
                            handleOK()
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    });
            })
    };

    const handleOK = () => {
        onOk && onOk();
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };

    return (
        <Modal
            title="创建班级"
            open={true}
            width={800}
            onOk={handleSubmit}
            onCancel={handleCancel}
            confirmLoading={loading}
            destroyOnClose={true}
        >
            <ClassesForm form={form}/>
        </Modal>
    )
}

export default ClassesAddModal;