
import axios from './rest-axios';

const getProjects = () => axios.get(`/v1/courses/`);
const postCourses = (model) => axios.post(`/v1/courses/`, model);
const putCourses = (model) => axios.put(`/v1/courses/${model.id}`, model);
const postUnlock = (classes_project_id, user_id) => axios.post(`/v1/courses/unlock`, {classes_project_id, user_id});
const postUnlockMuti = (classes_project_ids, user_id) => axios.post(`/v1/courses/unlock_muti`, {classes_project_ids, user_id});
const getCoursesDetail = (id) => axios.get(`/v1/courses/${id}`);

export {
    getProjects,
    postCourses,
    putCourses,
    postUnlock,
    getCoursesDetail,
    postUnlockMuti
};
