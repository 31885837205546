/**
 * @name: 课程完成情况概览
 * @description:
 * @author: Wood
 * @date: 2024/3/6
 * @update:
 */
import React, {useState} from 'react';
import {Space, Tag, Tooltip} from "antd";
import {SwapOutlined} from '@ant-design/icons';

const LessonProgress = ({progress}) => {
    const [update, setUpdate] = useState(0);
    return progress ? progress.map(progres =>
            <div style={{marginBottom: 5}}>
                                <span style={{marginRight: 8, fontWeight: "bold"}}>
                                {progres.name} <SwapOutlined onClick={() => {
                                    progres.swap = !progres.swap;
                                    setUpdate(update + 1);
                                }}/>：
                                </span>
                <Space size={[0, 5]} wrap={true}>
                    {progres.lessons.map(lesson =>
                        <Tooltip title={lesson.name}>
                            <Tag
                                color={lesson.status === 1 ? "#87d068" : (lesson.status > 0 ? "#d0110d" : "")}>
                                {progres.swap ? `${lesson.tag_index}:${lesson.name}` : lesson.tag_index}
                                {/*{lesson.tag_index}*/}
                            </Tag>
                        </Tooltip>)}
                </Space>
            </div>)
        : null
}
export default LessonProgress;