/**
 * @name: 课程管理路由
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import WXAccessToken from './wx_access_token/wx_access_token';
import Teachers from './teachers/teachers.jsx';
import Rooms from './rooms/rooms';
import SysDictType from './sys_dict_type/sys_dict_type';
import SysDict from './sys_dict_type/sys_dict2';
import WXAuth from './wx_auth/wx_auth';

const Router = () => (
    <Switch>
        <Route exact path="/settings/teachers"
               component={Teachers}/>
        <Route exact path="/settings/wx_access_token"
               component={WXAccessToken}/>
        <Route exact path="/settings/rooms"
               component={Rooms}/>
        <Route exact path="/settings/sys_dict_type"
               component={SysDictType}/>
        <Route exact path="/settings/sys_dict_type/:type_id"
               component={SysDict}/>
        <Route exact path="/settings/wxauth"
               component={WXAuth}/>
    </Switch>
);

export default Router;
