import {saveAs} from 'file-saver';
import * as ExcelJs from 'exceljs';
import {Workbook, Worksheet, Row, Cell} from 'exceljs';


// 默认的列宽
export const DEFAULT_COLUMN_WIDTH = 20;
// 默认行高
export const DEFAULT_ROW_HEIGHT = 20;

export const generateHeaders = (columns) => {
    return columns?.map((col) => {
        const obj = {
            // 显示的 name
            header: col.title,
            // 用于数据匹配的 key
            key: col.dataIndex,
            // 列宽
            width: col.width / 5 > DEFAULT_COLUMN_WIDTH ? col.width / 5 : DEFAULT_COLUMN_WIDTH,
        };
        if (col.children) {
            obj.children = col.children?.map((item) => ({
                key: item.dataIndex,
                header: item.title,
                width: item.width,
                parentKey: col.dataIndex,
            }));
        }
        return obj;
    });
}

export const saveWorkbook = (workbook, fileName) => {
// 导出文件
    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {type: ''});
        saveAs(blob, fileName);
    });
}
