import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Divider, Popconfirm, Space, Table, Typography, Input, message} from "antd";
import {deleteContestProblem, getContestProblems, getContests} from "../../../lib/api_contest";
import {getProblem} from '../../../lib/api_problems';
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";
import moment from "moment";
// import ContestProblemAddModal from "./training_problem_add_modal";
// import ContestProblemEditModal from "./training_problem_edit_modal";
import {MenuOutlined} from '@ant-design/icons';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import styles from './training_problem_list.module.css';
import {putProblems, getProblems} from "../../../lib/api_training";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: 'grab',
            color: '#999',
        }}
    />
));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const ContestProblemList = ({id}) => {
    const [list, setList] = useState([]);
    const [pid, setPid] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [editedId, setEditedId] = useState(0);

    const fetchData = () => {
        getProblems(id)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data.map((m,i)=>{
                        return {
                            ...m,
                            index: i
                        };
                    }));
                }
            })
    }

    useEffect(fetchData, []);


    const handleAdd = () => {
        console.log(pid);
        if (pid) {
            getProblem(pid)
                .then((res) => {
                    console.log(res);
                    if (res.code === 0) {
                        if (res.data) {
                            if (!list.some(m => m.id === res.data.id)) {
                                setList([...list, {...res.data, index: list.length}]);
                            }
                        }
                    }
                })
        }
    }

    const handleDelete = (id) => {
        let ls = list.filter(m => m.id !== id);
        // 重新生成索引
        for (let i = 0; i < ls.length; i++) {
            ls[i].index = i;
        }
        setList(ls);
    }

    /**
     * 保存题目列表
     */
    const handleSave = () => {
        // 组装数据
        let data = list.map(m => m.id)
        putProblems(id, data)
            .then((res) => {
                if (res.code === 0) {
                    message.success('保存成功');
                }
            })
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(list.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );
            console.log('Sorted items: ', newData);
            setList(newData);
        }
    };
    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass={styles.rowDragging}
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = list.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    return (
        <div>
            <Input.Group compact>
                <Input
                    style={{
                        width: '200px',
                    }}
                    value={pid}
                    onChange={(e) => setPid(e.target.value)}
                />
                <Button type="primary" onClick={handleAdd}>添加</Button>
            </Input.Group>
            <Table dataSource={list}
                   rowKey="index"
                   pagination={false}
                   size="small"
                   components={{
                       body: {
                           wrapper: DraggableContainer,
                           row: DraggableBodyRow,
                       },
                   }}
            >
                <Table.Column
                    dataIndex="Sort"
                    width={30}
                    className={styles.dragVisible}
                    render={() => <DragHandle/>}
                />
                <Table.Column
                    title="编号"
                    dataIndex="id"
                    key="编号"
                    className={styles.dragVisible}
                    width={60}
                />
                <Table.Column
                    title="标题"
                    dataIndex="title"
                    key="标题"
                />
                <Table.Column
                    title="操作"
                    key="action"
                    width={100}
                    align={"right"}
                    render={(text, record) => (
                        <Space size={0} split={<Divider type="vertical"/>}>
                            <Typography.Link onClick={() => handleDelete(record.id)}>删除</Typography.Link>
                        </Space>
                    )}
                />
            </Table>
            <Button type="primary" onClick={handleSave}>保存</Button>
        </div>
    );
}

export default ContestProblemList;