/**
 * @name: 重置教师密码
 * @description:
 * @author: Wood
 * @date: 2021/5/23
 * @update:
 */
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {resetPassword} from "../../../lib/api_teachers";
import {Modal, Input, Form} from 'antd';
import md5 from 'md5';

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const TeacherResetModal = ({visible, data, onOk, onCancel}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            password: "123456"
        })
    }, [data]);

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                resetPassword(data.id, md5(values.password))
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
                    .catch(err => {

                    });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            title="重置密码"
            visible={visible}
            onCancel={onCancel}
            onOk={handleOk}
        >
            <Form
                {...layout}
                form={form}
            >
                <Form.Item
                    label="姓名"
                >
                    <span className="ant-form-text">{data && data.nick_name}</span>
                </Form.Item>
                <Form.Item
                    label="手机号"
                >
                    <span className="ant-form-text">{data && data.mobile_number}</span>
                </Form.Item>
                <Form.Item
                    label="新密码"
                    name="password"
                    rules={[{
                        required: true,
                        message: '请输入新密码'
                    }]}
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    )
};


TeacherResetModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    data: PropTypes.object,
};

export default TeacherResetModal;