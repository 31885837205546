
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@ant-design/icons';


const PPTSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M902.095238 314.514286V853.333333a97.52381 97.52381 0 0 1-97.523809 97.52381H219.428571a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295239z"
            fill="#F07661"/>
        <path
            d="M660.72381 265.752381V73.142857l241.371428 241.371429h-192.609524a48.761905 48.761905 0 0 1-48.761904-48.761905z"
            fill="#CF4E4E"/>
        <path
            d="M523.946667 426.666667A127.024762 127.024762 0 0 0 414.47619 490.300952v125.318096a127.024762 127.024762 0 0 0 109.470477 63.634285 126.293333 126.293333 0 1 0 0-252.586666z m0 202.118095a76.068571 76.068571 0 0 1-75.824762-75.824762 75.337143 75.337143 0 0 1 16.822857-47.542857 75.824762 75.824762 0 1 1 59.001905 123.367619z"
            fill="#FFFFFF"/>
        <path d="M414.47619 426.666667h50.468572V780.190476H414.47619z" fill="#FFFFFF"/>
    </svg>
);
const DOCSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M902.095238 314.514286V853.333333a97.52381 97.52381 0 0 1-97.523809 97.52381H219.428571a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295239z"
            fill="#4997D9"/>
        <path d="M571.245714 720.944762h50.956191L707.047619 449.340952h-50.95619l-84.845715 271.60381z"
              fill="#FFFFFF"/>
        <path
            d="M660.72381 265.752381V73.142857l241.371428 241.371429h-192.609524a48.761905 48.761905 0 0 1-48.761904-48.761905z"
            fill="#4B80CB"/>
        <path d="M622.201905 720.944762h-50.956191l-84.601904-237.470476h50.71238l84.845715 237.470476z"
              fill="#FFFFFF"/>
        <path d="M401.798095 720.944762h50.956191l84.601904-237.470476h-50.71238l-84.845715 237.470476z"
              fill="#FFFFFF"/>
        <path d="M452.754286 720.944762h-50.956191L316.952381 449.340952h50.95619l84.845715 271.60381z" fill="#FFFFFF"/>
    </svg>
);

const MP3Svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M902.095238 314.514286V853.333333a97.52381 97.52381 0 0 1-97.523809 97.52381H219.428571a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295239z"
            fill="#A15FDE"/>
        <path
            d="M660.72381 241.371429V73.142857l241.371428 241.371429h-168.228571a73.142857 73.142857 0 0 1-73.142857-73.142857z"
            fill="#C386F0"/>
        <path
            d="M633.904762 436.419048v187.001904c0 20.72381-24.380952 37.546667-56.076191 37.546667s-55.832381-16.822857-55.832381-37.546667 24.380952-37.546667 55.832381-37.546666a76.55619 76.55619 0 0 1 31.695239 6.582857v-130.925714l-162.620953 38.278095v166.521905c0 20.72381-24.380952 37.546667-56.807619 37.546666s-57.051429-16.822857-57.051428-37.546666 25.6-37.546667 57.051428-37.546667a76.312381 76.312381 0 0 1 32.426667 6.826667v-161.401905a24.380952 24.380952 0 0 1 18.773333-24.380953l162.377143-37.790476a24.380952 24.380952 0 0 1 30.232381 24.380953z"
            fill="#FFFFFF"/>
    </svg>
);
const ZIPSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M902.095238 314.514286V853.333333a97.52381 97.52381 0 0 1-97.523809 97.52381H219.428571a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295239z"
            fill="#F4C446"/>
        <path
            d="M660.72381 216.990476V73.142857l241.371428 241.371429h-143.847619a97.52381 97.52381 0 0 1-97.523809-97.52381z"
            fill="#DBAC30"/>
        <path
            d="M447.390476 73.142857h58.758095v58.758095h-58.758095zM505.904762 131.900952h58.758095v58.758096h-58.758095z"
            fill="#FFFFFF"/>
        <path
            d="M447.390476 190.415238h58.758095v58.758095h-58.758095zM505.904762 249.173333h58.758095v58.758096h-58.758095z"
            fill="#FFFFFF"/>
        <path
            d="M447.390476 307.687619h58.758095v58.758095h-58.758095zM505.904762 366.445714h58.758095v58.758096h-58.758095zM447.390476 425.20381h58.758095v58.758095h-58.758095z"
            fill="#FFFFFF"/>
        <path
            d="M505.904762 483.718095h58.758095v58.758095h-58.758095zM447.390476 609.52381v117.028571a29.257143 29.257143 0 0 0 29.257143 29.257143H536.380952a29.257143 29.257143 0 0 0 29.257143-29.257143V609.52381zM536.380952 726.552381h-59.733333v-58.758095H536.380952z"
            fill="#FFFFFF"/>
    </svg>
);
const MP4Svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M902.095238 314.514286V853.333333a97.52381 97.52381 0 0 1-97.523809 97.52381H219.428571a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295239z"
            fill="#C386F0"/>
        <path
            d="M660.72381 216.990476V73.142857l241.371428 241.371429h-143.847619a97.52381 97.52381 0 0 1-97.523809-97.52381z"
            fill="#A15FDE"/>
        <path
            d="M343.04 424.228571m24.380952 0l240.64 0q24.380952 0 24.380953 24.380953l0 182.369524q0 24.380952-24.380953 24.380952l-240.64 0q-24.380952 0-24.380952-24.380952l0-182.369524q0-24.380952 24.380952-24.380953Z"
            fill="#FFFFFF"/>
        <path
            d="M585.142857 560.761905l101.912381 58.758095a24.380952 24.380952 0 0 0 36.571429-20.967619v-117.516191a24.380952 24.380952 0 0 0-36.571429-21.211428L585.142857 518.826667A24.380952 24.380952 0 0 0 585.142857 560.761905z"
            fill="#FFFFFF"/>
    </svg>
);
const TXTSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M877.714286 314.514286V853.333333a97.52381 97.52381 0 0 1-97.52381 97.52381H195.047619a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295238z"
            fill="#6D9FE5"/>
        <path
            d="M636.342857 216.990476V73.142857l241.371429 241.371429h-143.847619a97.52381 97.52381 0 0 1-97.52381-97.52381z"
            fill="#4B80CB"/>
        <path
            d="M343.771429 438.857143h268.190476v50.468571h-268.190476zM502.979048 489.325714v220.891429h-50.468572v-220.891429z"
            fill="#FFFFFF"/>
    </svg>
);

const PNGSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M897.462857 314.514286V853.333333a97.52381 97.52381 0 0 1-97.523809 97.52381h-585.142858a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295239z"
            fill="#85BCFF"/>
        <path
            d="M660.72381 216.990476V73.142857l241.371428 241.371429h-143.847619a97.52381 97.52381 0 0 1-97.523809-97.52381z"
            fill="#529EE0"/>
        <path
            d="M287.939048 372.784762m48.761904 0l341.333334 0q48.761905 0 48.761904 48.761905l0 278.674285q0 48.761905-48.761904 48.761905l-341.333334 0q-48.761905 0-48.761904-48.761905l0-278.674285q0-48.761905 48.761904-48.761905Z"
            fill="#FFFFFF"/>
        <path
            d="M404.967619 460.068571m-29.013333 0a29.013333 29.013333 0 1 0 58.026666 0 29.013333 29.013333 0 1 0-58.026666 0Z"
            fill="#85BCFF"/>
        <path
            d="M487.619048 641.462857L414.47619 585.142857l-83.382857 85.577143v28.525714a19.992381 19.992381 0 0 0 20.72381 19.017143h312.32a20.23619 20.23619 0 0 0 20.967619-19.017143V585.142857l-73.142857-75.824762z"
            fill="#85BCFF"/>
    </svg>
);

const PDFSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M902.095238 314.514286V853.333333a97.52381 97.52381 0 0 1-97.523809 97.52381H219.428571a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295239z"
            fill="#ED5050"/>
        <path
            d="M660.72381 265.752381V73.142857l241.371428 241.371429h-192.609524a48.761905 48.761905 0 0 1-48.761904-48.761905z"
            fill="#D43030"/>
        <path
            d="M633.904762 577.828571a309.150476 309.150476 0 0 0-59.489524 6.826667 288.670476 288.670476 0 0 1-59.001905-76.068571c16.335238-52.906667 17.066667-88.990476 4.876191-106.057143a31.451429 31.451429 0 0 0-24.380953-12.434286 30.72 30.72 0 0 0-29.500952 15.36c-17.066667 28.281905 7.558095 84.358095 19.017143 107.032381a673.645714 673.645714 0 0 1-50.224762 117.516191c-91.184762 39.009524-93.866667 62.659048-93.866667 71.19238a27.794286 27.794286 0 0 0 16.091429 24.380953 24.380952 24.380952 0 0 0 14.140952 3.657143c24.380952 0 51.2-26.575238 80.700953-78.506667a658.285714 658.285714 0 0 1 112.64-36.083809 121.904762 121.904762 0 0 0 70.217143 28.525714c16.091429 0 48.761905 0 48.761904-32.426667 0.24381-10.971429-6.339048-31.939048-49.980952-32.914286z m-260.632381 124.342858h-2.925714a73.142857 73.142857 0 0 1 34.133333-27.550477 62.659048 62.659048 0 0 1-31.207619 27.550477z m116.540952-282.575239a5.851429 5.851429 0 0 1 3.900953 0h2.925714a80.944762 80.944762 0 0 1 0 48.761905 76.8 76.8 0 0 1-6.826667-48.761905z m48.761905 183.832381a588.068571 588.068571 0 0 0-64.609524 19.017143v-1.950476h-1.950476c10.48381-20.72381 19.992381-42.666667 28.525714-64.365714v-1.950476a292.571429 292.571429 0 0 0 37.790477 46.323809h-2.681905z m97.52381 12.434286a82.651429 82.651429 0 0 1-28.769524-6.339047 107.032381 107.032381 0 0 1 24.380952-1.950477c18.773333 0 22.674286 4.632381 22.674286 7.558096a40.96 40.96 0 0 1-18.529524 0.731428z"
            fill="#FFFFFF"/>
    </svg>
);
const XLSSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M902.095238 314.514286V853.333333a97.52381 97.52381 0 0 1-97.523809 97.52381H219.428571a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295239z"
            fill="#4EC47F"/>
        <path
            d="M660.72381 265.752381V73.142857l241.371428 241.371429h-192.609524a48.761905 48.761905 0 0 1-48.761904-48.761905z"
            fill="#3DA869"/>
        <path
            d="M365.81181 476.574476l37.741714-37.766095 254.805333 254.805333-37.741714 37.766096zM365.763048 693.881905l96.719238-96.719238 37.766095 37.741714-96.719238 96.719238zM523.751619 535.771429l96.889905-96.865524 37.766095 37.741714-96.889905 96.889905z"
            fill="#FFFFFF"/>
    </svg>
);

const UnknownSvg = () => (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         width="1em" height="1em">
        <path
            d="M902.095238 314.514286V853.333333a97.52381 97.52381 0 0 1-97.523809 97.52381H219.428571a97.52381 97.52381 0 0 1-97.523809-97.52381V170.666667a97.52381 97.52381 0 0 1 97.523809-97.52381h441.295239z"
            fill="#BABABA"/>
        <path
            d="M660.72381 216.990476V73.142857l241.371428 241.371429h-143.847619a97.52381 97.52381 0 0 1-97.523809-97.52381z"
            fill="#979797"/>
        <path
            d="M701.927619 615.375238a94.841905 94.841905 0 0 1-94.841905 95.085714h-190.171428a94.841905 94.841905 0 0 1-9.508572-189.44 104.838095 104.838095 0 0 1 209.188572 0 94.841905 94.841905 0 0 1 85.333333 94.354286z"
            fill="#FFFFFF"/>
    </svg>

);
const ScratchSvg = () => (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         width="1em" height="1em">

        <path
            d="M832 64a128 128 0 0 1 128 128v561.05a127.54 127.54 0 0 1-127.54 127.54H717.62a25.6 25.6 0 0 0-20.38 10.11l-33.47 44a64 64 0 0 1-50.95 25.3H416.16A64 64 0 0 1 366 935.75l-36-45.46a25.61 25.61 0 0 0-20.07-9.7H192a128 128 0 0 1-128-128V192A128 128 0 0 1 192 64z m0 83.2H192a44.81 44.81 0 0 0-44.39 38.72l-0.41 6.08v560.59A44.8 44.8 0 0 0 185.92 797l6.08 0.41h117.91a108.79 108.79 0 0 1 78 33l7.27 8.27 30.22 38.18h177.86L631 840.35a108.83 108.83 0 0 1 76.49-42.49l10.13-0.47h114.84a44.35 44.35 0 0 0 43.94-38.32l0.4-6V192a44.81 44.81 0 0 0-38.72-44.39z m-185.92 88.49c7.79 24 11.49 54.07 11.49 90.54q0 56.07-22 113.54a32 32 0 0 1-61.49-6.27c-15.62-96.35-49.19-141.24-99.65-145.28-62.39-5-94.14 106.92-47 152.64 12.59 12.21 33.9 19.09 84.5 28.12l15.92 2.76c115.75 19.73 162.72 44 162.72 124.22 0 129.2-151.9 171.37-263.47 120.6l-30.8-14.28-0.07 1.7a32 32 0 0 1-63 0l-0.51-5.75V544.35a32 32 0 0 1 63.48-5.75l0.52 5.75-0.06 86.75 3.82 2c12.31 6.13 30.71 15 53.1 25.18 75.78 34.49 173 7.51 173-62.34 0-30.59-19.18-43.71-84.47-56.57l-56.04-10.07c-54.57-10.36-79.84-19.89-103.16-42.51-83.91-81.43-35.15-263.1 86.9-262.79l9.78 0.41c48 3.85 85.45 27.42 112.63 68.85-1.37-15.05-3.73-27.69-7-37.82a32 32 0 0 1 60.88-19.75z"
            fill="#e98f36"/>
    </svg>
);
const PythonSvg = () => (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         width="1em" height="1em">
        <path
            d="M418.742857 73.142857C349.257143 73.142857 292.571429 129.828571 292.571429 199.314286v73.142857h188.342857c16.457143 0 31.085714 25.6 31.085714 42.057143H199.314286c-69.485714 0-126.171429 56.685714-126.171429 126.171428v166.4c0 69.485714 56.685714 126.171429 126.171429 126.171429h51.2v-117.028572c0-69.485714 54.857143-126.171429 124.342857-126.171428h230.4c69.485714 0 126.171429-56.685714 126.171428-124.342857v-164.571429c0-71.314286-56.685714-128-126.171428-128H418.742857z m-32.914286 71.314286c18.285714 0 31.085714 5.485714 31.085715 31.085714s-14.628571 38.4-31.085715 38.4c-16.457143 0-31.085714-12.8-31.085714-38.4s14.628571-31.085714 31.085714-31.085714z"
            fill="#3C78AA"/>
        <path
            d="M773.485714 292.571429v117.028571c0 69.485714-54.857143 124.342857-124.342857 126.171429H418.742857c-69.485714 0-126.171429 54.857143-126.171428 124.342857v164.571428c0 69.485714 56.685714 126.171429 126.171428 126.171429h188.342857c69.485714 0 126.171429-56.685714 126.171429-126.171429v-73.142857H543.085714c-16.457143 0-31.085714-25.6-31.085714-42.057143h312.685714c69.485714 0 126.171429-56.685714 126.171429-126.171428v-166.4c0-69.485714-56.685714-126.171429-126.171429-126.171429h-51.2zM351.085714 490.057143z m287.085715 320c16.457143 0 31.085714 12.8 31.085714 38.4 0 16.457143-14.628571 31.085714-31.085714 31.085714-18.285714 0-31.085714-5.485714-31.085715-31.085714s12.8-38.4 31.085715-38.4z"
            fill="#FDD835"/>
    </svg>
);
const TaskSvg = () => (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         width="1em" height="1em">
        <path
            d="M268.202667 914.304a85.333333 85.333333 0 0 1-85.333334-85.333333V194.986667a85.333333 85.333333 0 0 1 85.333334-85.333334h498.176a85.333333 85.333333 0 0 1 85.333333 85.333334V658.346667l-260.096 256h-323.413333zM320 256a27.434667 27.434667 0 1 0 0 54.869333h310.869333a27.434667 27.434667 0 0 0 0-54.869333H320z m0 146.304a27.434667 27.434667 0 0 0 0 54.826667h201.130667a27.434667 27.434667 0 1 0 0-54.826667H320z"
            fill="#2AADFF"/>
        <path
            d="M673.365333 658.304h178.346667l-260.096 256v-175.573333c0-44.416 36.565333-80.426667 81.749333-80.426667z"
            fill="#ADE0FF"/>
    </svg>
);
const MarkDownSvg = () => (
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         width="1em" height="1em">
        <path d="M128 160a64 64 0 0 1 64-64h448l256 256v512a64 64 0 0 1-64 64H192a64 64 0 0 1-64-64V160z" fill="#3ECCB2"
        />
        <path d="M640 96l256 256h-192a64 64 0 0 1-64-64V96z" fill="#1AAD93"/>
        <path
            d="M423.936 546.944l59.104 132.96a16 16 0 0 0 14.624 9.504h24.064a16 16 0 0 0 14.624-9.504l59.104-133.376V704a16 16 0 0 0 16 16h27.328a16 16 0 0 0 16-16v-272a16 16 0 0 0-16-16h-34.72a16 16 0 0 0-14.688 9.6l-79.456 182.592L430.4 425.6a16 16 0 0 0-14.656-9.6H380.8a16 16 0 0 0-16 16V704a16 16 0 0 0 16 16h27.136a16 16 0 0 0 16-16v-157.056z"
            fill="#FFFFFF"/>
    </svg>
);

const ResourceIcon = ({type}) => {
    switch (type) {
        case "image":
            return <Icon component={PNGSvg} style={{fontSize: '20px'}}/>;
        case "ppt":
            return <Icon component={PPTSvg} style={{fontSize: '20px'}}/>;
        case "doc":
            return <Icon component={DOCSvg} style={{fontSize: '20px'}}/>;
        case "pdf":
            return <Icon component={PDFSvg} style={{fontSize: '20px'}}/>;
        case "scratch":
            return <Icon component={ScratchSvg} style={{fontSize: '20px'}}/>;
        case "mp3":
            return <Icon component={MP3Svg} style={{fontSize: '20px'}}/>;
        case "mp4":
            return <Icon component={MP4Svg} style={{fontSize: '20px'}}/>;
        case "txt":
            return <Icon component={TXTSvg} style={{fontSize: '20px'}}/>;
        case "xls":
            return <Icon component={XLSSvg} style={{fontSize: '20px'}}/>;
        case "zip":
            return <Icon component={ZIPSvg} style={{fontSize: '20px'}}/>;
        case "python":
            return <Icon component={PythonSvg} style={{fontSize: '20px'}}/>;
        case "task":
            return <Icon component={TaskSvg} style={{fontSize: '20px'}}/>;
        case "document":
            return <Icon component={MarkDownSvg} style={{fontSize: '20px'}}/>;
        default:
            return <Icon component={UnknownSvg} style={{fontSize: '20px'}}/>;
    }
};

ResourceIcon.propTypes = {
    type: PropTypes.string,
};

export default ResourceIcon;