import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Divider, Popconfirm, Space, Table, Tag, Typography} from "antd";
import {deleteContestProblem, getContestProblems, getContests} from "../../../lib/api_contest";
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";
import moment from "moment";
import ContestProblemAddModal from "./contest_problem_add_modal";
import ContestEditModal from "./contest_edit_modal";
import ContestProblemEditModal from "./contest_problem_edit_modal";

const ContestProblemList = ({id}) => {
    const [list, setList] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [editedId, setEditedId] = useState(0);

    const fetchData = () => {
        getContestProblems(id)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data.records);
                }
            })
    }

    useEffect(fetchData, []);

    const handleDelete = (id)=>{
        deleteContestProblem(id)
            .then(res=>{
                if(res.code===0){
                    fetchData();
                }
            })
    }


    return (
        <div>
            <Button onClick={() => setShowAddModal(true)}>添加</Button>
            <Table dataSource={list}
                   rowKey={"id"}
                   pagination={false}
                   size="small"
            >
                <Table.Column
                    title="编号"
                    dataIndex="display_id"
                    key="编号"
                    width={60}
                />
                <Table.Column
                    title="原题编号"
                    dataIndex="pid"
                    key="原题编号"
                    render={(pid, record) => (
                        <Link to={`/teaching/problems/${record.pid}`}>
                            {record.pid}
                        </Link>
                    )}
                />
                <Table.Column
                    title="显示标题"
                    dataIndex="display_title"
                    key="显示标题"
                />
                <Table.Column
                    title="气球颜色"
                    dataIndex="color"
                    key="气球颜色"
                    render={(color)=>(
                        <Tag color={color}>{color}</Tag>
                    )}
                />
                <Table.Column
                    title="操作"
                    key="action"
                    width={100}
                    align={"right"}
                    render={(text, record) => (
                        <Space size={0} split={<Divider type="vertical"/>}>
                            <Typography.Link onClick={()=>setEditedId(record.id)}>修改</Typography.Link>
                            <Popconfirm
                                title={`是否删除？`}
                                onConfirm={()=>handleDelete(record.id)}
                                // onCancel={cancel}
                                okText="删除"
                                cancelText="取消"
                            >
                                <Typography.Link>删除</Typography.Link>
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
            {showAddModal && <ContestProblemAddModal
                id={id}
                onOk={() => {
                    fetchData();
                    setShowAddModal(false);
                }}
                onCancel={() => {
                    setShowAddModal(false);
                }}
            />}
            {editedId > 0 && <ContestProblemEditModal
                id={editedId}
                onOk={()=>{
                    fetchData();
                    setEditedId(0);
                }}
                onCancel={()=>{
                    setEditedId(0);
                }}
            />}
        </div>
    );
}

export default ContestProblemList;