import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Card, Input, Space, Table, Tag, Typography,Button,Row,Col} from "antd";
import {Link} from "react-router-dom";
import {deleteProblem, getProblems} from "../../../lib/api_problems";
import useUrlState from "@ahooksjs/use-url-state";

const {Column} = Table;

const Problems = () => {
    const [total, setTotal] = useState(0);
    const [records, setrecords] = useState([]);
    const [state, setState] = useUrlState({
        page: 1, pageSize: 20, key:"",
    });
    const fetchData = () => {
        getProblems(state)
            .then(res => {
                if (res.code === 0) {
                    setrecords(res.data.records);
                    setTotal(res.data.total);
                }
            });
    }
    useEffect(fetchData, [state]);

    /**
     * 点击分页按钮
     * @param page
     * @param pageSize
     */
    const handlerPageChange = (page, pageSize) => {
        setState({
            page, pageSize
        })
    };

    const handleDelete = (id)=>{
        deleteProblem(id)
            .then(res => {
                if (res.code === 0) {
                    fetchData();
                }
            });
    }

    return (
        <PageContent title={"问题列表"}>
            <Card bordered={false}>
                <Row justify="space-between">
                    <Col>
                        <Input.Search placeholder="input search text"
                                      onSearch={value => setState({...state, key: value})}
                                      style={{width: 300}}
                                      defaultValue={state.key}
                                      enterButton/>
                    </Col>
                    <Col>
                        <Link to={"/teaching/problems/add"} >
                            <Button type={"primary"}>添加</Button>
                        </Link>

                    </Col>
                </Row>

                <Table dataSource={records}
                       rowKey={"id"}
                       pagination={{
                           current: parseInt(state.page),
                           pageSize: parseInt(state.pageSize),
                           total: total,
                           position: ["bottomCenter"],
                           onChange: handlerPageChange
                       }}
                       size="small"
                >
                    <Column
                        title="编号"
                        dataIndex="id"
                        key="id"
                        width={60}
                        render={(id) => (
                            <Link to={`/teaching/problems/${id}`}>{id}</Link>
                        )}
                    />
                    <Column
                        title="标题"
                        dataIndex="title"
                        key="title"
                        render={(title,record) => (
                            <Link to={`/teaching/problems/${record.id}`}>{title}</Link>
                        )}
                    />
                    <Column
                        title="来源"
                        dataIndex="source"
                        key="source"
                    />
                    <Column
                        title="难度"
                        dataIndex="difficulty"
                        key="difficulty"
                        width={60}
                        render={(difficulty)=>{
                            switch (difficulty) {
                                case 0: return "暂无评定";
                                case 1: return "入门";
                                case 2: return "普及";
                                case 3: return "普及/提高-";
                                case 4: return "普及+/提高";
                                case 5: return "提高+/省选-";
                                case 6: return "省选/NOI-";
                                case 7: return "NOI/NOI+/CTSC";
                                default: return "未知";
                            }
                        }}
                    />
                    <Column
                        title="时间限制"
                        dataIndex="time_limit"
                        key="time_limit"
                        width={80}
                    />
                    <Column
                        title="内存限制"
                        dataIndex="memory_limit"
                        key="memory_limit"
                        width={80}
                    />
                    <Column
                        title="问题id"
                        dataIndex="problem_id"
                        key="problem_id"
                        width={60}
                    />
                    <Column
                        title="标签"
                        dataIndex="tags"
                        key="tags"
                        render={(tags) => (
                            <span>
                                {tags && JSON.parse(tags).map(tag => <Tag>{tag}</Tag>)}
                            </span>
                        )}
                    />
                    <Column
                        title="操作"
                        key="action"
                        width={100}
                        render={(text, record) => (
                            <Space>
                                <Link to={`/teaching/problems/${record.id}`}>修改</Link>
                                <Typography.Link onClick={()=>handleDelete(record.id)}>删除</Typography.Link>
                            </Space>
                        )}
                    />
                </Table>
            </Card>
        </PageContent>
    );
}

export default Problems;