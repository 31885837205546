
import axios from './rest-axios';

const getQuestions = () => axios.get(`/v1/questions`);
const getQuestion = (id) => axios.get(`/v1/questions/${id}`);
const postQuestion = (model) => axios.post(`/v1/questions`, model);
const putQuestion = (model) => axios.put(`/v1/questions/${model.id}`, model);
const delQuestion = (ids) => axios.post(`/v1/questions/delete`, {ids});


export {
    getQuestions,
    getQuestion,
    postQuestion,
    putQuestion,
    delQuestion,
};
