import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {changeToTestcase, getProblem, nextProblem, prevProblem, putProblem} from "../../../lib/api_problems";
import {Button, Card, Form, Input, InputNumber, message, Radio} from "antd";
import MdEditor from "../../../components/md-editor/md-editor";
import {SaveOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import SolutionsConfig from './solutions_config';
import TagsSelect from './tags_select';


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};

const ProblemEdit = ({id}) => {
    const [form] = Form.useForm();
    const [data, setdata] = useState(null);
    const history = useHistory()
    const fetchData = () => {
        getProblem(id)
            .then(res => {
                if (res.code === 0) {
                    setdata(res.data);
                }
            })
    };

    useEffect(fetchData, []);

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        putProblem(values).then(res => {
            if (res.code === 0) {
                message.success("保存成功!");
                fetchData();
            } else {
                message.error(res.message);
            }
        })
    };

    const prev = () => {
        // 获取上一条数据
        // history.replace()
        prevProblem(id).then(res => {
            if (res.code === 0) {
                history.replace(res.data);
            } else {
                // message.error(res.message);
            }
        })
    }

    const next = () => {
        nextProblem(id).then(res => {
            if (res.code === 0) {
                history.replace(res.data);
            } else {
                // message.error(res.message);
            }
        })
    }

    /*
    将示例转换为测试用例
     */
    const changeTotestcase = () => {
        changeToTestcase(id).then(res => {
            if (res.code === 0) {
                message.success("成功");
            } else {
                // message.error(res.message);
            }
        })
    }
    return (
        <PageContent title={"编辑问题"}>
            {data
                ? <div>
                    <Card bordered={false}>
                        <Form
                            {...formItemLayout}
                            form={form}
                            onFinish={onFinish}
                            initialValues={data}
                        >
                            <Form.Item name="id" label="编号">
                                <span className="ant-form-text">{data.id}</span>
                            </Form.Item>
                            <Form.Item name="title" label="题目名称">
                                <Input/>
                            </Form.Item>
                            <Form.Item name="description" label="描述">
                                <MdEditor/>
                            </Form.Item>
                            <Form.Item name="input" label="输入">
                                <MdEditor/>
                            </Form.Item>
                            <Form.Item name="output" label="输出">
                                <MdEditor/>
                            </Form.Item>
                            <Form.Item name="hint" label="提示">
                                <MdEditor/>
                            </Form.Item>
                            <Form.Item name="source" label="来源">
                                <Input/>
                            </Form.Item>
                            <Form.Item name="time_limit" label="时间限制">
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item name="memory_limit" label="内存限制">
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item name="difficulty" label="难度">
                                <Radio.Group>
                                    <Radio value={0}>暂无评定</Radio>
                                    <Radio value={1}>入门</Radio>
                                    <Radio value={2}>普及-</Radio>
                                    <Radio value={3}>普及/提高-</Radio>
                                    <Radio value={4}>普及+/提高</Radio>
                                    <Radio value={5}>提高+/省选-</Radio>
                                    <Radio value={6}>省选/NOI-</Radio>
                                    <Radio value={7}>NOI/NOI+/CTSC</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="输入输出样例">
                                <Form.Item name="examples">
                                    <Input.TextArea autoSize={{minRows: 2, maxRows: 6}}/>
                                </Form.Item>
                                <a onClick={changeTotestcase}>转为用例</a>
                            </Form.Item>

                            <Form.Item name="languages" label="支持语言">
                                <Input/>
                            </Form.Item>
                            <Form.Item name="tags" label="标签">
                                <TagsSelect/>
                            </Form.Item>

                            <Form.Item name="solutions" label="标准程序">
                                <SolutionsConfig/>
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    offset: 4,
                                    span: 20,
                                }}
                                style={{position: "fixed", bottom: 10, width: 500, zIndex: 1000}}
                            >
                                <Button type="primary" htmlType="submit" size="large" icon={<SaveOutlined/>}>
                                    保存
                                </Button>
                                <Button type="default" size="large" style={{marginLeft: 15}}
                                        onClick={prev}>上一题</Button>
                                <Button type="default" size="large" style={{marginLeft: 15}}
                                        onClick={next}>下一题</Button>
                                <Button type="default" size="large" style={{marginLeft: 15}}
                                        onClick={() => history.push(`./${data.problem_id}/testcases`)}>测试用例</Button>
                            </Form.Item>
                        </Form>
                        <div style={{height: 100}}>

                        </div>
                    </Card>
                </div>
                : <div>加载中</div>
            }
        </PageContent>
    );
};


export default ProblemEdit;