const tags = [...new Set([
        '八年级（上）第一课',
        '八年级（上）第二课',
        '八年级（上）第三课',
        '八年级（上）第四课',
        '八年级（上）第五课',
        '八年级（上）第六课',
        '八年级（上）第七课',
        '八年级（上）第八课',
        '八年级（上）第九课',
        '八年级（上）第十课',
        '八年级（上）第十一课',
        '八年级（上）第十二课',
        '八年级（上）第十三课',
        '八年级（上）第十四课',
        '八年级（上）第十五课',
        '数据类型',
        '变量',
        '字符串',
        '整数',
        '浮点数',
        '字符编码',
        '列表',
        '元组',
        '条件判断',
        '循环',
        '字典',
        '集合',
        '函数',
        '切片',
        '基础算法',
        '模拟',
        '贪心',
        '递推',
        '递归',
        '枚举,暴力',
        '分治',
        '查找',
        '顺序查找',
        '二分查找',
        '排序',
        '冒泡排序',
        '插入排序',
        '选择排序',
        '快速排序（快排）',
        '归并排序',
        '堆排序',
        '希尔排序',
        '计数排序',
        '二分图',
        '最大匹配',
        '匈牙利算法',
        '一般图的最大匹配',
        'Konig定理',
        '带权二分图匹配',
        '稳定婚姻系统',
        '搜索',
        '广度优先搜索, BFS',
        '深度优先搜索, DFS',
        '剪枝',
        '记忆化搜索',
        '启发式搜索',
        '迭代加深',
        '随机调整',
        '数论, 数学',
        '整数研究',
        '集合论',
        '群论',
        '组合数学',
        '线性规划',
        '概率论, 统计',
        '线性代数',
        '微积分初步',
        '图论',
        '图的建立, 建图',
        '图遍历',
        '拓扑排序',
        '最短路',
        '生成树',
        '圈和块',
        '强连通分量, 缩点',
        '仙人掌',
        '计算几何',
        '凸包',
        '叉积',
        '线段相交',
        '点积',
        '半平面相交, 半平面交',
        '最近点对',
        '凸多边形的交',
        '离散化扫描',
        '旋转卡壳',
        '树形结构',
        '线段树',
        '点分治',
        '平衡树',
        '二叉堆',
        '树状数组',
        'cdq分治',
        '树上距离',
        '动态树',
        '树的应用',
        '整体二分',
        '环套树',
        'K - D Tree',
        '运算符',
        '生成器',
        '迭代器',
        '类和实例',
        '继承和多态',
        '多进程',
        '多线程',
        '文件读写',
        '序列化',
        '正则表达式',
        'TCP',
        'UDP',
        'SMTP',
        'POP3',
        'SQLite',
        'MySQL',
        '异步',
        '异常',
    ])]
;

const tagOptions = tags.map(item => ({key: item, value: item, text: item}));

export {
    tags,
    tagOptions
}

/*
* 数据类型
* 变量
* 字符串
* 字符编码
* 列表
* 元组
* 条件判断
* 循环
*
* 字典
* 集合
*
*
* 函数
* 切片
* 递归
*
* 基础算法
* 模拟
* 贪心
* 递推
* 递归
* 枚举,暴力
* 分治
*
* 查找
* 顺序查找
* 二分查找
*
* 排序
* 冒泡排序
* 插入排序
* 选择排序
* 快速排序（快排）
* 归并排序
* 堆排序
* 希尔排序
* 计数排序
*
* 二分图
*
* 最大匹配
* 匈牙利算法
* 一般图的最大匹配
* Konig定理
*
* 带权二分图匹配
* 稳定婚姻系统
*
* 搜索
* 广度优先搜索,BFS
* 深度优先搜索,DFS
* 剪枝
* 记忆化搜索
* 启发式搜索
* 迭代加深
* 随机调整
*
* 数论,数学
* 整数研究
* 集合论
* 群论
* 组合数学
* 线性规划
* 概率论,统计
* 线性代数
* 微积分初步
*
* 图论
* 图的建立,建图
* 图遍历
* 拓扑排序
* 最短路
* 生成树
* 圈和块
* 强连通分量,缩点
* 仙人掌
*
* 计算几何
* 凸包
* 叉积
* 线段相交
* 点积
* 半平面相交,半平面交
* 最近点对
* 凸多边形的交
* 离散化扫描
* 旋转卡壳
*
* 树形结构
* 线段树
* 点分治
* 平衡树
* 二叉堆
* 树状数组
* cdq分治
* 树上距离
* 动态树
* 树的应用
* 整体二分
* 环套树
* K-D Tree
*
* 运算符
* 生成器
* 迭代器
*
* 类和实例
* 继承和多态
* 多进程
* 多线程
* 文件读写
* 序列化
* 正则表达式
* TCP
* UDP
* SMTP
* POP3
* SQLite
* MySQL
* 异步
*
* 异常
* */