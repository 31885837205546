import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { message, Modal, Input, Switch } from 'antd';
// import {publicProject} from "../../lib/api_projects_public";
import ModeSelect from '../../../components/mode-select/mode-select';
import {putScratch} from "../../../lib/api_scratchs";

class PublishModal extends Component {
    state = {
        data: this.props.data ? {...this.props.data} : null
    };

    componentDidMount() {
        // this.loadData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
    };
    handleOk = () => {
        this.props.onOk && this.props.onOk();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                console.log(this.props.data.id);
                // 提交数据
                values.id = this.props.data.id;
                putScratch(values)
                    .then(result => {
                        if (result.code === 0) {
                            // this.loadData();
                            message.success("修改成功");
                            this.handleOk();
                        } else {
                            message.error(result.message);
                        }
                    })

            }
        });
    };

    render() {
        const {getFieldDecorator, setFieldsValue,getFieldValue} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        return (
            <Modal
                title={"发布作品"}
                visible={!!this.props.data}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                width={600}
                // footer={null}
                destroyOnClose
            >
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item label="作品名称">
                        {getFieldDecorator('name', {
                            initialValue: this.props.data ? this.props.data.name : "",
                            rules: [{required: true, message: '请输入作品名称'}]
                        })(
                            <Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="作品描述">
                        {getFieldDecorator('project_desc', {
                            initialValue: this.props.data &&  this.props.data.project_desc ? this.props.data.project_desc : "我在小码蚁学编程，这是我的作品，大家给我点个赞吧",
                            rules: [{required: true, message: '请输入作品描述'}]
                        })(
                            <Input.TextArea/>
                        )}
                    </Form.Item>
                    <Form.Item label="操作说明">
                        {getFieldDecorator('project_instruction',{
                            initialValue: this.props.data &&  this.props.data.project_instruction ? this.props.data.project_instruction : "",
                        })(
                            <Input.TextArea/>
                        )}
                    </Form.Item>
                    <Form.Item label="控制模式">
                        {getFieldDecorator('mobile_mode', {
                            initialValue: this.props.data &&  this.props.data.mobile_mode ? this.props.data.mobile_mode : 1,
                            rules: [{required: true, message: '请选择控制模式'}]
                        })(
                            <ModeSelect/>
                        )}
                    </Form.Item>
                    <Form.Item label="公开源码">
                        {getFieldDecorator('is_opensource',{
                            initialValue: this.props.data &&  this.props.data.is_opensource,
                            valuePropName: 'checked'
                        })(
                            <Switch checkedChildren="公开" unCheckedChildren="保密" onChange={(checked)=>{
                                // console.log(e)
                                if(!checked){
                                    setFieldsValue({
                                        is_editable: 0
                                    })
                                }
                            }} />
                        )}
                    </Form.Item>
                    <Form.Item label="允许改编">
                        {getFieldDecorator('is_editable',{
                            initialValue: this.props.data &&  this.props.data.is_editable,
                            valuePropName: 'checked',
                            rules:[{
                                validator: (rule, value, callback)=>{
                                    if(value && !getFieldValue("is_opensource")){
                                        callback('先公开源码才能改编哦！');
                                    }
                                    callback();
                                }
                            }]
                        })(
                            <Switch checkedChildren="允许" unCheckedChildren="禁止"/>
                        )}
                    </Form.Item>
                </Form>

            </Modal>
        )
    }
}


PublishModal.propTypes = {
    data: PropTypes.object,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


const mapStateToProps = state => ({
    // school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default Form.create()(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PublishModal)));


