import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {postTeacher} from "../../../lib/api_teachers";
import {Modal, Input, Form,Radio} from 'antd';
import md5 from 'md5';
import FormDanXuan from './form_danxuan';
import FormDanXuanAnswer from './form_danxuan_answer';
import MdEditor from '../../../components/md-editor/md-editor';
import {postQuestion} from "../../../lib/api_questions";
import FormPanDuan from './form_panduan';


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const QuestionAddModal = ({visible, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [type, settype] = useState("单选题");

    // useEffect(() => {
    //     form.setFieldsValue({
    //         nick_name: "",
    //         other_name: "",
    //         sex: 1,
    //         mobile_number: "",
    //         password: "123456",
    //     })
    // }, [visible]);

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                // console.log(values)
                values.type = "单选题";
                postQuestion(values)
                // values.password = md5(values.password);
                // postTeacher(values)
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };



    // 智能识别单选题
    const handleDanXuan = (value) => {
        // console.log(e.target.value);
        var pattern = /^([^A]*)A\.([^A]*)B\.([^B]*)C\.([^C]*)D\.([^D]*)$/m;
        // console.log(pattern.match(e.target.value));
        let result = value.match(pattern);
        console.log(result);
        let title = result[1].trim();
        var pattern2 = /^\d*\.([\s\S]*)$/m;
        let result2 = title.match(pattern2);
        console.log(result2)
        title = result2[1].trim();

        form.setFieldsValue({
            title: title,
            content: JSON.stringify({
                title: title,
                options: [{
                    key: "A",
                    value: result[2].trim()
                }, {
                    key: "B",
                    value: result[3].trim()
                }, {
                    key: "C",
                    value: result[4].trim()
                }, {
                    key: "D",
                    value: result[5].trim()
                }]
            })
        })

    }

    return (
        <Modal
            title="添加单选题"
            visible={visible}
            onCancel={onCancel}
            onOk={handleOk}
            width={800}
        >
           <MdEditor onChange={handleDanXuan}/>


            {/*<Input.TextArea rows={4} placeholder="智能识别" onChange={handleDanXuan}/>*/}
            <Form
                {...layout}
                form={form}

            >
                <Form.Item
                    label="摘要标题"
                    name="title"
                    rules={[{
                        required: true,
                        message: '请输入摘要标题'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="题目配置"
                    name="content"
                    rules={[{
                        required: true,
                        message: '请输入题目配置'
                    }]}
                >
                    <FormDanXuan/>

                </Form.Item>
                <Form.Item
                    label="答案配置"
                    name="answer"
                    rules={[{
                        required: true,
                        message: '请输入答案配置'
                    }]}
                >
                    <FormDanXuanAnswer/>
                </Form.Item>

            </Form>
        </Modal>
    )
};


QuestionAddModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default QuestionAddModal;