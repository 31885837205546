import PropTypes from 'prop-types';
import React, {Component} from 'react';
import FormatBytes from "../../../components/formatbytes";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import styles from './resources-config.module.css';

import {CloseOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Modal, Form, Input} from 'antd';
import {postClone, getByIds} from "../../../lib/api_scratchs";
import ProblemInfo from '../../../components/challenges/problem-info';
import {getChallenge} from "../../../lib/api_challenges";
import MdEditor from '../../../components/md-editor/md-editor';
import moment from 'moment';
import {getDocument} from "../../../lib/api_documents";
import ResourceIcon from '../../../components/icon/resource_icon';
import {getTask} from "../../../lib/api_tasks";
import TaskSelectModal from './task_select_modal';
import DocumentSelectModal from './document_select_modal';
import ChallengesSelectModal from './challenges_select_modal';
import AliyunOSSUpload from '../../../components/aliyun-oss-upload';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

// 资源配置
class ResourcesConfig extends React.Component {
    state = {
        /*
        * 数据类型
        * [{id:string,name:string, type:string, url:string, data:object}]
        * id: 数据的唯一编号
        * name: 显示的名称
        * type: 支持数据类型：image,ppt,doc,pde,scratch,mp3,mp4,txt,xls,zip,python
        * url: 跳转连接
        * data: 扩展数据
        *   文件类: type: 文件类型
        *          size: 文件大小
        *   scratch:id: scratch编号
        *           name: 作品名称
        *           user_id: 作者
        *           oss_key: 文件key
        *   task: id: 编号
        *           name: 标题
        *           desc: 描述
        *   python: id: 题目编号
        *           name: 题目名称
        *   document: id: 文档编号
        *             title: 文档标题
        * */
        list: [],

        // 任务
        // task: null,

        // 编程题
        showPythonModal: false,
        challenge: null,

        // 文档
        showDocModal: false,
        doc: null,

        // 任务
        showTaskModal: false,
        task: null,
    };

    componentDidMount() {
        const {value} = this.props;
        if (value) {
            this.setState({
                list: JSON.parse(value)
            }, () => {
                this.loadScratch();
            });
        }
    }

    onChange = () => {
        const {onChange, value} = this.props;
        let {list} = this.state;
        onChange && onChange(JSON.stringify(list));
    };

    // h获取Scratch的标题信息
    loadScratch = () => {
        let {list} = this.state;
        let scratchids = list.filter(_ => _.type === "scratch").map(_ => _.data.id);
        if (scratchids.length > 0) {
            getByIds(scratchids.join(','))
                .then(result => {
                    if (result.code === 0) {
                        list.filter(_ => _.type === "scratch").forEach(item => {
                            let sc = result.data.find(_ => _.id === item.data.id);
                            if (sc) {
                                item.name = sc.name;
                                item.data.name = sc.name;
                            }
                        });
                        this.setState({
                            list: list
                        }, () => {
                            this.onChange();
                        })
                    }
                })
        }
    };

    // 文件上传
    handleUpload = (e) => {
        let {list} = this.state;
        console.log(e);
        let uploads = JSON.parse(e);
        console.log(uploads);
        uploads.forEach(item => {
            if (item.url && !list.some(_ => _.url === item.url)) {
                let type = "";
                const suffix = item.name.slice(item.name.lastIndexOf('.'));
                switch (suffix) {
                    case ".png":
                    case ".jpg":
                    case ".jpeg":
                        type = "image";
                        break;
                    case ".ppt":
                    case ".pptx":
                        type = "ppt";
                        break;
                    case ".pdf":
                        type = "pdf";
                        break;
                    case ".doc":
                    case ".docx":
                        type = "doc";
                        break;
                    default:
                        break;
                }
                list.push({
                    id: moment().format("x"),
                    name: item.name,
                    type: type,
                    url: item.url,
                    data: {
                        type: item.type,
                        size: item.size
                    }
                });
            }
        });
        this.setState({
            list: list
        }, () => {
            this.onChange();
        })
    };

    // 获取图标

    // 加载单项
    renderItem = (item, index) => {
        switch (item.type) {
            case "task":
                return (
                    <>
                    <ResourceIcon type={item.type}/>
                    {/*{this.renderIcon(item)}*/}
                    <span className={styles.title} onClick={() => this.handleCreateCommon(item.data)}>
                        {item.name}
                    </span>
                    <span onClick={() => this.handleRemove(index)}><CloseOutlined/></span>
                    </>
                );
            case "scratch":
            case "image":
            case "ppt":
            case "doc":
            case "pdf":
            case "mp3":
            case "mp4":
            case "txt":
            case "xls":
            case "zip":
            default:
                return (
                    <>
                    {/*{this.renderIcon(item)}*/}
                    <ResourceIcon type={item.type}/>
                    <a href={item.url} target="_blank" className={styles.title}>
                        {item.name}
                    </a>
                    {item.data && item.data.size &&
                    <span style={{marginRight: 20}}>{FormatBytes(item.data.size)}</span>}
                    <span onClick={() => this.handleRemove(index)}><CloseOutlined/></span>
                    </>);
        }
    };

    handleRemove = (index) => {
        let {list} = this.state;
        list.splice(index, 1);
        this.setState({
            list: list
        }, () => {
            this.onChange();
        })
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const list = reorder(
            this.state.list,
            result.source.index,
            result.destination.index
        );

        this.setState({
            list
        }, () => {
            this.onChange();
        });
    };


    // 创建scratch
    handleCreateScratch = () => {
        postClone(1, this.props.courses_lessons_id)
            .then(result => {
                let {list} = this.state;
                if (result.code === 0) {
                    let item = result.data;
                    list.push({
                        id: moment().format("x"),
                        name: item.name,
                        type: "scratch",
                        url: `https://xmybc.com/scratch/projects/${item.id}`,
                        data: {
                            id: item.id,
                            name: item.name,
                            user_id: item.user_id,
                            oss_key: item.oss_key,
                        }
                    });
                    this.setState({
                        list
                    }, () => {
                        this.onChange();
                    });
                    // this.handleOK();
                } else {
                    // message.error(result.message);
                }
                // console.log(res)
            })
    };

    // // 创建或修改普通的作业
    // handleCreateCommon = (task) => {
    //     console.log(task);
    //     if (!task) {
    //         task = {
    //             id: moment().format("x"),
    //             name: "",
    //             desc: ""
    //         }
    //     }
    //     this.setState({
    //         task: task
    //     })
    // };

    // handleTaskClose = () => {
    //     this.setState({
    //         task: null
    //     })
    // };
    //
    // handleTaskSubmit = () => {
    //     let {list, task} = this.state;
    //     let model = list.find(_ => _.type === "task" && _.data.id === task.id);
    //     if (!model) {
    //         list.push({
    //             id: task.id,
    //             name: task.name,
    //             type: "task",
    //             url: null,
    //             data: {...task}
    //         });
    //     } else {
    //         model.name = task.name;
    //         model.data.name = task.name;
    //         model.data.desc = task.desc;
    //     }
    //     this.setState({
    //         list,
    //         task: null
    //     }, () => {
    //         this.onChange();
    //     });
    // };

    // 添加Python编程题
    handleCreatePython = () => {
        this.setState({
            showPythonModal: true
        })
    };
    handlePythonModalClose = () => {
        this.setState({
            showPythonModal: false
        })
    };
    handlePythonSubmit = (challenges) => {
        let {list} = this.state;
        if (!challenges || challenges.length === 0) return;
        for (let i in challenges) {
            let challenge = challenges[i];
            list.push({
                id: moment().format("x"),
                name: challenge.name,
                type: "python",
                url: `/challenges/${challenge.pkey}`,
                data: {
                    id: challenge.id,
                    name: challenge.name,
                }
            });
        }
        this.setState({
            list,
            showPythonModal: false,
            challenge: null
        }, () => {
            this.onChange();
        });
    };

    // 添加文档
    handleCreateDoc = () => {
        this.setState({
            showDocModal: true
        })
    };
    handleDocModalClose = () => {
        this.setState({
            showDocModal: false
        })
    };
    handleDocSubmit = (documents) => {
        let {list} = this.state;
        if (!documents || documents.length === 0) return;
        for (let i in documents) {
            let doc = documents[i];
            list.push({
                id: moment().format("x"),
                name: doc.title,
                type: "document",
                url: null,
                data: {
                    id: doc.id,
                    title: doc.title,
                }
            });

        }


        this.setState({
            list,
            showDocModal: false,
            doc: null
        }, () => {
            this.onChange();
        });
    };

    // 添加任务
    handleCreateTask = () => {
        this.setState({
            showTaskModal: true
        })
    };
    handleTaskModalClose = () => {
        this.setState({
            showTaskModal: false
        })
    };

    handleTaskSubmit2 = (tasks) => {
        let {list} = this.state;
        if (!tasks || tasks.length === 0) return;
        for (let i in tasks) {
            let task = tasks[i];
            list.push({
                id: moment().format("x"),
                name: task.title,
                type: "task",
                url: null,
                data: {
                    id: task.id,
                    title: task.title,
                }
            });
        }


        this.setState({
            list,
            showTaskModal: false,
            task: null
        }, () => {
            this.onChange();
        });
    };

    render() {
        const {list} = this.state;

        return (
            <div>
                <DragDropContext onDragEnd={this.onDragEnd}>


                    <div className={styles.toolbar}>
                        <AliyunOSSUpload multiple={true} showUploadList={true} onChange={this.handleUpload}/>
                        <Button onClick={() => this.handleCreateTask()} icon={<PlusOutlined/>}>任务</Button>
                        <Button onClick={() => this.handleCreateDoc()} icon={<PlusOutlined/>}>文档</Button>
                        <Button onClick={this.handleCreateScratch} icon={<PlusOutlined/>}>Scratch编程题</Button>
                        <Button onClick={() => this.handleCreatePython()} icon={<PlusOutlined/>}>Python编程题</Button>
                    </div>

                    <Droppable droppableId={moment().format("x")}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                // style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {list.map((item, index) => (
                                    <Draggable key={item.url} draggableId={item.id + ""} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                // style={getItemStyle(
                                                //     snapshot.isDragging,
                                                //     provided.draggableProps.style
                                                // )}
                                                className={styles.item}
                                            >
                                                {this.renderItem(item, index)}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                        {/*<div key={index}>*/}
                                        {/*{this.renderItem(item)}*/}
                                        {/*{item.name} {FormatBytes(item.data.size)}*/}
                                        {/*<a onClick={() => this.handleRemove(index)}>删除</a>*/}
                                        {/*</div>*/}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                {this.state.showPythonModal &&
                <ChallengesSelectModal
                    onCancel={this.handlePythonModalClose}
                    onOk={this.handlePythonSubmit}
                />
                }

                {/*新文档*/}
                {this.state.showDocModal &&
                <DocumentSelectModal
                    onCancel={this.handleDocModalClose}
                    onOk={this.handleDocSubmit}
                />
                }

                {/*新任务*/}
                {this.state.showTaskModal &&
                <TaskSelectModal
                    onCancel={this.handleTaskModalClose}
                    onOk={this.handleTaskSubmit2}
                />}
            </div>
        );
    }
}

ResourcesConfig.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default ResourcesConfig;