/**
 * @name: 课程管理路由
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import {TemplateList} from './huodong/1/templates';

const Router = () => (
    <Switch>
        <Route exact path="/operation/huodong"
               component={TemplateList}/>
    </Switch>
);

export default Router;
