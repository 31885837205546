/**
 * @name: 课程选择
 * @description:
 * @author: Wood
 * @date: 2024/3/6
 * @update:
 */
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Cascader} from 'antd';
import {getProjects} from "../../../lib/api_courses";


function filter(inputValue, path) {
    return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

const CoursesSelect = ({value, onChange}) => {
    const [list, setList] = useState([]);

    const fetchData = () => {
        getProjects()
            .then((res) => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };

    useEffect(fetchData, []);

    return (
        <Cascader
            fieldNames={{label: 'name', value: 'id', children: 'projects'}}
            options={list}
            value={value}
            expandTrigger="hover"
            onChange={(value) => {
                onChange && onChange(value)
            }}
            showSearch={{filter}}
        />
    )
}

CoursesSelect.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.array
};

export default (CoursesSelect);
