import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Input, Form} from 'antd';
import FormOptions from './form_options';


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const FormDanXuan = ({value, onChange}) => {
    const [form] = Form.useForm();
    const [config, setConfig] = useState({
        title: "",
        options: []
    });

    useEffect(() => {
        if(value){
            let dt = JSON.parse(value);
            setConfig(dt);
            form.setFieldsValue(dt)
        }else{
            form.setFieldsValue({
                title: "",
                options: []
            })
        }

    }, [value]);

    const handleChange = (changedValues, allValues) => {
        // console.log(allValues);
        onChange && onChange(JSON.stringify(allValues))
    };

    return (
        <Form
            {...layout}
            form={form}
            onValuesChange={handleChange}
        >
            <Form.Item
                label="标题"
                name="title"
                rules={[{
                    required: true,
                    message: '请输入标题'
                }]}
            >
                <Input.TextArea autoSize/>
            </Form.Item>
            <Form.Item
                label="选项"
                name="options"
                rules={[{
                    required: true,
                    message: '请输入题目配置'
                }]}
            >
                <FormOptions/>
            </Form.Item>
        </Form>
    )
};


FormDanXuan.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default FormDanXuan;