import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {PlusOutlined, DeleteOutlined, ShoppingCartOutlined} from '@ant-design/icons';
import {Table, Popconfirm, Button, Card, Space, Row, Col} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getExams, delExam, postExam, putExam} from "../../../lib/api_exams";
import ExamEditForm from './exam_edit_form';
import EasyFormModal from '../../../components/easy_form_modal';
import moment from 'moment';

const {Column} = Table;

const Exams = () => {
    const [list, setlist] = useState([]);
    const [addModal, setaddModal] = useState(false);
    const [editRecord, seteditRecord] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const fetchData = () => {
        getExams()
            .then((result) => {
                if (result.code === 0) {
                    let data = result.data;
                    for (let i in data) {
                        data[i].begin_time = moment(data[i].begin_time)
                        data[i].end_time = moment(data[i].end_time)
                    }
                    setlist(data);
                }
            })
    };

    useEffect(fetchData, []);

    const handleDelete = (id) => {
        // console.log(selectedRows)
        delExam(selectedRows.map(m => m.id))
            .then((result) => {
                if (result.code === 0) {
                    fetchData();
                }
            })
    };

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        // selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <PageContent title={"测验"}>

            <Card bordered={false}>
                <Row justify={"space-between"}
                     style={{marginBottom: 10}}>
                    <Col>
                        <Space>

                            <Popconfirm
                                title={`是否删除${selectedRows.length}条记录？`}
                                onConfirm={handleDelete}
                                // onCancel={cancel}
                                okText="删除"
                                cancelText="取消"
                                disabled={selectedRows.length === 0}
                            >
                                <Button disabled={selectedRows.length === 0} icon={<DeleteOutlined/>} danger>删除</Button>
                            </Popconfirm>

                        </Space>
                    </Col>
                    <Col>
                        <Button type="primary" icon={<PlusOutlined/>}
                                onClick={() => setaddModal({})}
                        >创建</Button>
                    </Col>

                </Row>
                <Table dataSource={list} rowKey={"id"}
                       pagination={false}
                       size="small"
                       rowSelection={rowSelection}
                       scroll={{
                           // x: 1500,
                           y: `calc(100vh - 230px)`,
                       }}
                >
                    <Column
                        title="编号"
                        dataIndex="id"
                        key="id"
                        width={60}
                    />
                    <Column
                        title="标题"
                        dataIndex="title"
                        key="标题"
                    />
                    <Column
                        title="开始/结束时间"
                        key="开始/结束时间"
                        width={150}
                        render={(record) => {
                            // time.format("YYYY-MM-DD HH:mm")
                            return (<>
                            <div>{record.begin_time.format("YYYY-MM-DD HH:mm")}</div>
                            <div>{record.end_time.format("YYYY-MM-DD HH:mm")}</div>
                            </>)
                        }}
                    />

                    <Column
                        title="时长（分钟）"
                        key="时长"
                        width={100}
                        render={(record) => record.end_time.diff(record.begin_time, "m")}
                    />
                    <Column
                        title="试卷"
                        key="试卷"
                        render={(record) => {
                            if (record.paper) {
                                return record.paper.title;
                            }
                            return null;
                        }}
                    />
                    {/*<Column*/}
                        {/*title="状态"*/}
                        {/*dataIndex="status"*/}
                        {/*key="状态"*/}
                        {/*width={60}*/}
                        {/*render={(status) => {*/}
                            {/*switch (status) {*/}
                                {/*case 0:*/}
                                    {/*return "未开始";*/}
                                {/*case 1:*/}
                                    {/*return "进行中";*/}
                                {/*case 2:*/}
                                    {/*return "已结束";*/}
                                {/*default:*/}
                                    {/*return "未知";*/}
                            {/*}*/}
                        {/*}}*/}
                    {/*/>*/}
                    <Column
                        title="操作"
                        key="操作"
                        width={100}
                        render={(record) => (
                            <Space>
                                <a onClick={() => seteditRecord(record)}>编辑</a>
                                <Link to={`/teaching/exams/${record.id}`}>学员</Link>
                            </Space>
                        )}
                    />
                </Table>
            </Card>
            {addModal && <EasyFormModal
                data={addModal}
                title={"添加测验"}
                onOk={() => {
                    setaddModal(false);
                    fetchData();
                }}
                onCancel={() => {
                    setaddModal(false);
                }}
                formComponent={ExamEditForm}
                restFunc={postExam}
            />}
            {editRecord && <EasyFormModal
                data={editRecord}
                title={"修改课程"}
                onOk={() => {
                    seteditRecord(null);
                    fetchData();
                }}
                onCancel={() => {
                    seteditRecord(null);
                }}
                formComponent={ExamEditForm}
                restFunc={putExam}
            />}

        </PageContent>
    );
};

export default Exams;
