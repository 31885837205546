import PropTypes from 'prop-types';
import {connect} from 'react-redux';


const RoomName = ({id, rooms}) => {
    let room = rooms.find(_ => _.id.toString() === id.toString());
    return room ? room.name : "";
};

RoomName.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(
    state => ({
        rooms: state.rooms,
    })
)(RoomName);