import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Input, Table} from 'antd';
import {getTasks} from "../../../lib/api_tasks";
import MarkdownHtml from '../../../components/markdown-html/markdown-html';

const TaskSelectModal = ({onOk, onCancel}) => {
    const [tasks, settasks] = useState([]);
    const [tasksfilter, settasksfilter] = useState([]);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);

    const fetchData = () => {
        getTasks()
            .then(result => {
                if (result.code === 0) {
                    settasks(result.data);
                    settasksfilter(result.data);
                }
            })
    };

    const handleTaskSearch = (e) => {
        settasksfilter(tasks.filter(_ => _.title.includes(e.target.value) || _.id === parseInt(e.target.value)))
    };

    useEffect(fetchData, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setselectedRowKeys(selectedRowKeys);
        },
    };

    const handleTaskSubmit = () => {
        let list = tasks.filter(_ => selectedRowKeys.includes(_.id));
        onOk && onOk(list)
    };

    return (
        <Modal
            title="添加任务"
            visible={true}
            onOk={handleTaskSubmit}
            onCancel={onCancel}
            // confirmLoading={this.state.loading}
            destroyOnClose={true}
            width={800}
        >
            <Input.Search placeholder="任务编号/名称" onChange={handleTaskSearch} enterButton/>
            <Table
                rowSelection={rowSelection}
                dataSource={tasksfilter}
                rowKey={"id"}
                size={"small"}
            >
                <Table.Column
                    title="编号"
                    dataIndex="id"
                    key="编号"
                />
                <Table.Column
                    title="名称"
                    dataIndex="title"
                    key="名称"
                    render={(title, record)=>{
                        return <a onClick={()=>{
                            Modal.info({
                                title: record.title,
                                content: (
                                    <MarkdownHtml markdown={record.content} />
                                ),
                                maskClosable: true,
                                icon: null,
                                onOk() {},
                            });
                        }}>{title}</a>
                    }}
                />
            </Table>
        </Modal>
    )
};

TaskSelectModal.propTypes = {
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

export default TaskSelectModal;