import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from "react-router-dom";
import styles from './mode-select.module.css';
import { CheckCircleFilled } from '@ant-design/icons';
import { Menu, message } from 'antd';
import bg from '../../assets/images/classes_item_bg.jpg';

import mode1 from '../../assets/images/mode1.png';
import mode2 from '../../assets/images/mode2.png';
import mode3 from '../../assets/images/mode3.png';
import mode5 from '../../assets/images/mode5.png';

const modes = [
    {value: 1, image: mode1},
    {value: 2, image: mode2},
    {value: 3, image: mode3},
    {value: 5, image: mode5}
]

class ModeSelect extends Component {
    state ={
        value: this.props.value
    };

    handleChange = (value)=>{
        this.setState({value:value});
        this.props.onChange && this.props.onChange(value);
    };

    render() {

        return (
            <div className={styles.container}>
                {modes.map(mode=>(
                    <div className={`${styles.item} ${this.state.value === mode.value ? "active" : ""}`} onClick={()=> this.handleChange(mode.value)}>
                        <img src={mode.image}/>
                        <CheckCircleFilled className={styles.icon} />
                    </div>
                ))}
            </div>
        );
    }
}


ModeSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func
};

export default ModeSelect;