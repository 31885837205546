/**
 * @name: 周历
 * @description:
 * @author: Wood
 * @date: 2021/4/26
 * @update:
 */
import React, {Component} from 'react';
import {Card, Radio, Button, Row, Col, Space,Modal} from 'antd';
import CalendarWeekTime from './calendar-week-time';
import CalendarWeekTeacher from './calendar-week-teacher';
import CalendarWeekRoom from './calendar-week-room';
import moment from 'moment';
import {LeftOutlined, RightOutlined, ReloadOutlined} from '@ant-design/icons';
import DianmingDetail from '../teaching/courses/dianming_detail_modal';
import styles from './calendar-week.module.css';


class CalendarWeek extends Component {
    state = {
        view: 1,
        start_date: moment().weekday(0).format("YYYY-MM-DD"),
        end_date: moment().weekday(6).format("YYYY-MM-DD"),
        dianmingDetailID: 0,
    };

    componentDidMount() {
        // let {date} = this.state;
        // let now = moment();
        // let start_date = moment(date).day(1).format("YYYY-MM-DD");
        // let end_date = moment(date).day(9).format("YYYY-MM-DD");
        // this.loadData(start_date, end_date);
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handlePrev = () => {
        let {start_date, end_date} = this.state;
        start_date = moment(start_date).add(-7, "d").format("YYYY-MM-DD");
        end_date = moment(end_date).add(-7, "d").format("YYYY-MM-DD");
        this.setState({
            start_date,
            end_date
        })
    };

    handleNext = () => {
        let {start_date, end_date} = this.state;
        start_date = moment(start_date).add(7, "d").format("YYYY-MM-DD");
        end_date = moment(end_date).add(7, "d").format("YYYY-MM-DD");
        this.setState({
            start_date,
            end_date
        })
    };

    handleToday = () => {
        this.setState({
            start_date: moment().weekday(0).format("YYYY-MM-DD"),
            end_date: moment().weekday(6).format("YYYY-MM-DD"),
        })
    };

    handleEventClick = (id)=>{
        this.setState({
            dianmingDetailID: id
        });
    };


    render() {
        let {view, start_date, end_date} = this.state;
        return (
            <div style={{padding: 16}}>
                <Card bordered={false}>
                    <Row justify="space-between">
                        <Col>
                            <Radio.Group
                                value={view}
                                buttonStyle="solid"
                                onChange={(e) => this.setState({view: e.target.value})}
                                style={{marginBottom: 8}}
                            >
                                <Radio.Button value={1}>时间视图</Radio.Button>
                                <Radio.Button value={2}>教师视图</Radio.Button>
                                <Radio.Button value={3}>教室视图</Radio.Button>
                            </Radio.Group>
                        </Col>
                        <Col>
                            <Space>
                                <span className={`${styles.tag} ${styles.default}`}>未上</span>
                                <span className={`${styles.tag} ${styles.warning}`}>未评</span>
                                <span className={`${styles.tag} ${styles.success}`}>已评</span>
                                <Button icon={<ReloadOutlined/>} onClick={this.handleToday}>
                                    回到今日
                                </Button>

                                <Button icon={<LeftOutlined/>} onClick={this.handlePrev}/>
                                {start_date}至{end_date}
                                <Button icon={<RightOutlined/>} onClick={this.handleNext}/>
                            </Space>
                        </Col>
                    </Row>
                    {view === 1 && <CalendarWeekTime key={start_date} start_date={start_date} end_date={end_date} onEventClick={this.handleEventClick} />}
                    {view === 2 && <CalendarWeekTeacher key={start_date} start_date={start_date} end_date={end_date} onEventClick={this.handleEventClick} />}
                    {view === 3 && <CalendarWeekRoom key={start_date} start_date={start_date} end_date={end_date} onEventClick={this.handleEventClick} />}
                </Card>
                {/*<Modal*/}
                {/*    title="点名详情"*/}
                {/*    visible={this.state.dianmingDetailID > 0}*/}
                {/*    onCancel={() => this.setState({dianmingDetailID: 0})}*/}
                {/*    destroyOnClose={true}*/}
                {/*    width={800}*/}
                {/*    footer={null}*/}
                {/*>*/}
                {/*    <DianmingDetail id={this.state.dianmingDetailID}/>*/}
                {/*</Modal>*/}
                {this.state.dianmingDetailID>0 &&
                    <DianmingDetail
                        id={this.state.dianmingDetailID}
                        onCancel={() => this.setState({dianmingDetailID: 0})}
                    />
                }

            </div>
        )
    }
}


export default CalendarWeek;
