/**
 * @name: 头像菜单
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {connect} from 'react-redux';
import {Menu} from 'antd';
import {showPasswordModal} from "../../reducers/password-modal";
import {signOut} from "../../reducers/account";

const AccountMenu = ({signOut, showPasswordModal}) => (
    <Menu>
        <Menu.Item>
            <a onClick={showPasswordModal}>修改密码</a>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item>
            <a onClick={signOut}>退出登录</a>
        </Menu.Item>

    </Menu>
);

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    signOut: () => dispatch(signOut()),
    showPasswordModal: () => dispatch(showPasswordModal()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountMenu);