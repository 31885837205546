/**
 * @name: 学生相关接口
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import axios from './rest-axios';

const getStudents = (classes_id) => axios.get(`/v1/students/?classes_id=${classes_id}`);
const getStudent = (id) => axios.get(`/v1/students/${id}`);
const putStudent = (model) => axios.put(`/v1/students/${model.id}`, model);
const postStudents = (classes_id, content) => axios.post(`/v1/students/${classes_id}`, {classes_id, content});
const postStudent = (model) => axios.post(`/v1/students/`, model);
const resetPassword = (student_id) => axios.post(`/v1/students/${student_id}/resetpassword`);
const searchUser = (school_id, key) => axios.post(`/v1/students/${school_id}/search/`, {key});
const postStudentsInSchool = (team_id, ids) => axios.post(`/v1/students/${team_id}/toteam`, {
    team_id,
    ids
});
const deleteStudentFromClasses = (team_id, user_id) => axios.delete(`/v1/students/`, {
    team_id,
    user_id
});

const getStudentCoursesLessons = (id, user_id) => axios.get(`/v1/students/${user_id}/courses_lessons/${id}`);
const getCards = (user_id) => axios.get(`/v1/students/${user_id}/cards/`);


export {
    getStudents,
    getStudent,
    putStudent,
    postStudents,
    postStudent,
    resetPassword,
    searchUser,
    postStudentsInSchool,
    deleteStudentFromClasses,
    getStudentCoursesLessons,
    getCards
};
