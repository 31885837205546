/**
 * @name: 课程管理路由
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import Courses from './courses/courses.jsx';
import Lessons from './courses/lessons.jsx';
import Tasks from './tasks/tasks';
import Documents from './documents/documents';
import Scratchs from './scratchs/scratchs';
import Challenges from './challenges/challenges';
import ChallengesAdd from './challenges/challenges_add';
import ChallengesAddChoice from './challenges/challenges_add_choice';
import ChallengesEdit from './challenges/challenges_edit';
import Questions from './questions/index';
import Papers from './papers/index';
import Exams from './exam/index';
import ExamUsers from './exam/users';
import Problems from './cppproblem/problems';
import ProblemEdit from './cppproblem/problem_edit';
import TestcasesEdit from './cppproblem/testcases_edit';
import ContestList from "./contest/contest_list";
import ContestDetail from "./contest/contest_detail";
import TrainingList from "./training/training_list";
import TrainingDetail from "./training/training_detail";
import ProblemAdd from './cppproblem/problem_add';
import Paper_edit from "./papers/paper_edit";

const Router = () => (
    <Switch>
        <Route exact path="/teaching/courses"
               component={Courses}/>
        <Route path="/teaching/courses/:id"
               render={({match}) => <Lessons id={parseInt(match.params.id)}/>}/>
        <Route exact path="/teaching/tasks"
               component={Tasks}/>
        <Route exact path="/teaching/documents"
               component={Documents}/>
        <Route exact path="/teaching/scratchs"
               component={Scratchs}/>
        <Route exact path="/teaching/challenges/python"
               component={Challenges}/>
        <Route exact path="/teaching/challenges/python/create"
               component={ChallengesAdd}/>
        <Route exact path="/teaching/challenges/python/create/choice"
               component={ChallengesAddChoice}/>
        <Route exact path="/teaching/challenges/python/:id/edit"
               component={({match}) => <ChallengesEdit id={parseInt(match.params.id)}/>}/>
        <Route exact path="/teaching/challenges/python/:page"
               component={({match}) =>
                   <Challenges page={parseInt(match.params.page)}/>}/>
        <Route exact path="/teaching/questions"
               component={Questions}/>
        <Route exact path="/teaching/papers"
               component={Papers}/>
            <Route exact path="/teaching/papers/:id"
                   component={({match}) =>
                       <Paper_edit id={parseInt(match.params.id)}/>}/>
        <Route exact path="/teaching/exams"
               component={Exams}/>
        <Route exact path="/teaching/exams/:id"
               component={({match}) =>
                   <ExamUsers id={parseInt(match.params.id)}/>}/>
            <Route exact path="/teaching/problems"
                   component={Problems}/>
            <Route exact path="/teaching/problems/add"
                   component={ProblemAdd}/>
        <Route exact path="/teaching/problems/:id"
               component={({match}) =>
                   <ProblemEdit id={match.params.id}/>}/>
        <Route exact path="/teaching/problems/:id/testcases"
               component={({match}) =>
                   <TestcasesEdit id={match.params.id}/>}/>
        <Route exact path="/teaching/contest"
               component={ContestList}/>

        <Route exact path="/teaching/contest/:id"
               component={({match}) =>
                   <ContestDetail id={parseInt(match.params.id)}/>}/>

        <Route exact path="/teaching/training"
               component={TrainingList}/>

            <Route exact path="/teaching/training/:id"
                   component={({match}) =>
                       <TrainingDetail id={match.params.id}/>}/>

    </Switch>
);

export default Router;
