/**
 * @name: 左侧菜单
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/4/21
 */
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import styles from './sidebar.module.css';
import FirstSider from './first-sider';
import SecondSider from './second-sidebar';
import menuConfig, {getFirstMenu} from './menu.config';

const SideBar = (props) => {
    const [menu, setMenu] = useState(menuConfig[0]);
    const [current, setCurrent] = useState(menuConfig[0]);

    // 根据链接生成菜单
    // let pathname = props.history.location.pathname;

    const Path2Menu = () => {
        let menu2 = getFirstMenu(props.path);
        setMenu(menu2);
        setCurrent(menu2)
    };

    useEffect(Path2Menu, [props.path]);
    // useEffect(Path2Menu, []);

    return (
        <aside
            className={styles.asider}
            onMouseLeave={_ => setMenu(current)}
            style={{
                width: menu.children.length > 0 ? 224: 92
            }}
        >
            <FirstSider
                path={menu.path}
                onHover={menu => setMenu(menu)}
                key={menu.path}
            />
            {menu.children.length > 0 && <SecondSider
                menu={menu}
                path={props.path}
                key={props.path}/>}

        </aside>
    )
};

FirstSider.propTypes = {
    path: PropTypes.string,
};

export default withRouter(SideBar);