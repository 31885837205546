import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Space, Table} from "antd";
import {getTrainings} from "../../../lib/api_training";
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";
import moment from "moment";
import TrainingAddModal from "./training_add_modal";

const TrainingList = () => {
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [state, setState] = useUrlState({
        page: 1, pageSize: 10, key: "",
    });
    const [showAddModal, setShowAddModal] = useState(false);

    const fetchData = () => {
        getTrainings(state)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data.records);
                    setTotal(res.data.total);
                }
            })
    }

    useEffect(fetchData, [state]);

    const handlerPageChange = (page, pageSize) => {
        setState({
            page, pageSize
        })
    };


    return (
        <PageContent title={"题单"}>
            <Card bordered={false} title={"题单列表"} extra={[<Space>
                <Button onClick={() => setShowAddModal(true)}>添加</Button>
            </Space>]}>
                <Table dataSource={list}
                       rowKey={"id"}
                       pagination={{
                           current: parseInt(state.page),
                           pageSize: parseInt(state.pageSize),
                           total: total,
                           position: ["bottomCenter"],
                           onChange: handlerPageChange
                       }}
                       size="small"
                >
                    <Table.Column
                        title="编号"
                        dataIndex="id"
                        key="编号"
                        width={150}
                        render={id => <Link to={`/teaching/training/${id}`}>{id}</Link>}
                    />
                    <Table.Column
                        title="名称"
                        dataIndex="name"
                        key="名称"
                        width={200}
                        ellipsis={true}
                        render={(title, record) => <Link to={`/teaching/training/${record.id}`}>{title}</Link>}
                    />
                    <Table.Column
                        title="描述"
                        dataIndex="description"
                        key="描述"
                        ellipsis={true}
                        // render={type => <trainingType type={type}/>}
                    />
                    <Table.Column
                        title="类型"
                        dataIndex="type"
                        width={150}
                        key="类型"
                    />
                    <Table.Column
                        title="题目"
                        dataIndex="problem_count"
                        width={60}
                        key="题目"
                    />
                    <Table.Column
                        title="可见"
                        dataIndex="visible"
                        key="可见"
                        width={60}
                        render={visible => visible ? "显示" : "隐藏"}
                    />
                    <Table.Column
                        title="作者"
                        dataIndex="author"
                        width={60}
                        key="作者"
                    />
                    <Table.Column
                        title="排序"
                        dataIndex="sort_index"
                        width={60}
                        key="排序"
                    />
                </Table>
            </Card>

            {showAddModal && <TrainingAddModal
                onOk={() => {
                    fetchData();
                    setShowAddModal(false);
                }}
                onCancel={() => {
                    setShowAddModal(false);
                }}
            />}


        </PageContent>
    );
}

export default TrainingList;