import axios from './rest-axios';

const getNotificationList = (date) => axios.get(`/v1/notification/`, {date});
const sendNotification = (model) => axios.post(`/v1/notification/send`, model);


export {
    getNotificationList,
    sendNotification
};
