import React, {useRef} from 'react';
import {Card, Input} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import DataTable from '../../../components/data/data-table2';
import moment from 'moment';

const WXAccessToken = () => {

    const table = useRef();
    const config = {
        table: table,
        title: "wx_access_token监控",
        tableName: "wx_access_token",
        size: "small",
        columns: [{
            title: 'appid',
            dataIndex: 'appid',
            copyable: true,
        }, {
            title: 'secret',
            dataIndex: 'secret',
            copyable: true,
        }, {
            title: 'access_token',
            dataIndex: 'access_token',
            // ellipsis: true,
            // copyable: true,
            render: (access_token) => <Input value={access_token}/>
        }, {
            title: '最近更新',
            dataIndex: 'updatedAt',
            render: (updatedAt) => {
                let time = moment(updatedAt);
                return time.format("YYYY-MM-DD HH:mm")
            }
        }, {
            title: '备注',
            dataIndex: 'remark'
        }]
    };

    return (
    <PageContent title={"wx_access_token监控"}>
        <Card bordered={false}>
            <DataTable {...config} />

            {/*<DataTable*/}
                {/*tableName={"wx_access_token"}*/}
                {/*table={{*/}
                    {/*size: "small",*/}
                    {/*columns: [{*/}
                        {/*title: 'appid',*/}
                        {/*dataIndex: 'appid',*/}
                        {/*copyable: true,*/}
                    {/*}, {*/}
                        {/*title: 'secret',*/}
                        {/*dataIndex: 'secret',*/}
                        {/*copyable: true,*/}
                    {/*}, {*/}
                        {/*title: 'access_token',*/}
                        {/*dataIndex: 'access_token',*/}
                        {/*// ellipsis: true,*/}
                        {/*// copyable: true,*/}
                        {/*render: (access_token) => <Input value={access_token}/>*/}
                    {/*}, {*/}
                        {/*title: '最近更新',*/}
                        {/*dataIndex: 'updatedAt',*/}
                        {/*render: (updatedAt) => {*/}
                            {/*let time = moment(updatedAt);*/}
                            {/*return time.format("YYYY-MM-DD HH:mm")*/}
                        {/*}*/}
                    {/*}, {*/}
                        {/*title: '备注',*/}
                        {/*dataIndex: 'remark'*/}
                    {/*}]*/}
                {/*}}*/}
            {/*/>*/}
        </Card>
    </PageContent>
)};


export default WXAccessToken;


