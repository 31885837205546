import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Descriptions, Space, Table, Tabs} from "antd";
import {getTraining, getTrainings} from "../../../lib/api_training";
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";
import moment from "moment";
import TrainingProblemList from "./training_problem_list";
import MarkdownHtml from '../../../components/markdown-html/markdown-html';
import TrainingEditModal from "./training_edit_modal";

const TrainingDetail = ({id}) => {
    const [data, setData] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const fetchData = () => {
        getTraining(id)
            .then(res => {
                if (res.code === 0) {
                    setData(res.data);
                }
            })
    }

    useEffect(fetchData, []);


    return (
        <PageContent title={"题单详情"}>
            <Card bordered={false}>
                <Descriptions column={3} title={data?.name} extra={[<Space>
                    <Button onClick={() => setShowEditModal(true)}>修改</Button>
                </Space>]}>
                    {/*<Descriptions.Item label="来源">{data?.source}</Descriptions.Item>*/}
                    <Descriptions.Item
                        label="是否公开">{data?.auth === 0 ? "公开赛" : data?.auth === 1 ? "私有赛" : "保护赛"}</Descriptions.Item>
                    <Descriptions.Item label="是否可见">{data?.visible ? "显示" : "隐藏"}</Descriptions.Item>
                    <Descriptions.Item label="创建者">{data?.author}</Descriptions.Item>
                    <Descriptions.Item label="类型">{data?.type}</Descriptions.Item>
                    <Descriptions.Item label="排序">{data?.sort_index}</Descriptions.Item>
                    <Descriptions.Item label="描述" span={3}>
                        <MarkdownHtml markdown={data?.description}/>
                    </Descriptions.Item>
                </Descriptions>
            </Card>
            <Card title="题目编排" bordered={false} style={{marginTop: 12}}>
                <TrainingProblemList id={id}/>
            </Card>
            {showEditModal && <TrainingEditModal
                id={id}
                onOk={() => {
                    fetchData();
                    setShowEditModal(false);
                }}
                onCancel={() => {
                    setShowEditModal(false);
                }}
            />
            }

        </PageContent>
    );
}

export default TrainingDetail;