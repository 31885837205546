import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {getProblemTestCases, postProblemTestCase} from "../../../lib/api_problems";
import {Card, Row, Col, Button, message} from "antd";
import TestcaseInfo from "./testcase_info";

const TestcasesEdit = ({id}) => {
    const [data, setdata] = useState([]);
    const fetchData = () => {
        getProblemTestCases(id)
            .then(res => {
                if (res.code === 0) {
                    setdata(res.data);
                }
            })
    };

    useEffect(fetchData, []);

    const handleCreate = async () => {
        // 计算名称
        let number = 1;
        while (data.some(item => item === "test" + number + ".in")) {
            number++;
        }
        // console.log(number);
        await postProblemTestCase(id, `test${number}.in`, "");
        await postProblemTestCase(id, `test${number}.out`, "");
        fetchData();
    }


    return (
        <PageContent title={"编辑测试用例"}>
            <Button type={"primary"} onClick={handleCreate}>添加</Button>
            {data
                ? <div>
                    <Card bordered={false}>
                        <Row gutter={[12, 12]}>
                            {data.map(m => <Col span={12} key={m}>
                                <div>{m}</div>
                                <div>
                                    {/*获取文件内容*/}
                                    <TestcaseInfo id={id} name={m}/>
                                </div>
                            </Col>)}
                        </Row>
                    </Card>
                </div>
                : <div>加载中</div>
            }
        </PageContent>
    );
};


export default TestcasesEdit;