import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Input, Form,Radio} from 'antd';
import MdEditor from '../../../components/md-editor/md-editor';
import {postQuestion} from "../../../lib/api_questions";
import FormPanDuan from './form_panduan';
import FormPanDuanAnswer from './form_panduan_answer';


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const QuestionAddModal = ({visible, onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                // console.log(values)
                values.type = "判断题";
                postQuestion(values)
                // values.password = md5(values.password);
                // postTeacher(values)
                    .then(result => {
                        if (result.code === 0) {
                            onOk && onOk()
                        }
                    })
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };
    const handlePanDuan = (value) => {
        var pattern2 = /^\d*\.([\s\S]*)$/m;
        let result2 = value.match(pattern2);
        console.log(result2)
        let title = result2[1].trim();

        form.setFieldsValue({
            title: title,
            content: JSON.stringify({
                title: title,
            })
        })

    }
    return (
        <Modal
            title="添加判断题"
            visible={visible}
            onCancel={onCancel}
            onOk={handleOk}
            width={800}
        >
           <MdEditor onChange={handlePanDuan}/>


            {/*<Input.TextArea rows={4} placeholder="智能识别" onChange={handleDanXuan}/>*/}
            <Form
                {...layout}
                form={form}

            >
                <Form.Item
                    label="摘要标题"
                    name="title"
                    rules={[{
                        required: true,
                        message: '请输入摘要标题'
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="题目配置"
                    name="content"
                    rules={[{
                        required: true,
                        message: '请输入题目配置'
                    }]}
                >
                    <FormPanDuan/>
                </Form.Item>
                <Form.Item
                    label="答案配置"
                    name="answer"
                    rules={[{
                        required: true,
                        message: '请输入答案配置'
                    }]}
                >
                    <FormPanDuanAnswer/>
                </Form.Item>

            </Form>
        </Modal>
    )
};


QuestionAddModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default QuestionAddModal;