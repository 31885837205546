import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Input, Table, Tag} from 'antd';
import {getList} from "../../../lib/api_challenges";

const ChallengesSelectModal = ({onOk, onCancel}) => {
    const [challenges, setchallenges] = useState([]);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [key, setkey] = useState("");
    const [pagination, setpagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const fetchData = () => {
        // setkey(key);
        getList({
            results: pagination.pageSize,
            page: pagination.current,
            key: key
        })
            .then(result => {
                setchallenges(result.data);
                setpagination({
                    ...pagination,
                    total: result.totalCount
                });
            })
    };

    const handleChallengesSearch = (value) => {
        // fetchData(value);
        setkey(value)
    };

    // useEffect(fetchData, []);
    useEffect(fetchData, [key, pagination.current]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setselectedRowKeys(selectedRowKeys);
        },
    };

    const handleChallengesSubmit = () => {
        let list = challenges.filter(_ => selectedRowKeys.includes(_.id));
        onOk && onOk(list)
    };

    const  handleTableChange = (newpagination, filters, sorter) => {
        // fetchData(key)
        setpagination({
            ...pagination,
            ...newpagination
        })
    };
    return (
        <Modal
            title="添加Python题目"
            visible={true}
            onOk={handleChallengesSubmit}
            onCancel={onCancel}
            // confirmLoading={this.state.loading}
            destroyOnClose={true}
            width={800}
        >
            <Input.Search placeholder="题目编号/名称" onSearch={handleChallengesSearch} enterButton/>
            <Table
                rowSelection={rowSelection}
                dataSource={challenges}
                pagination={pagination}
                rowKey={"id"}
                size={"small"}
                onChange={handleTableChange}
            >
                <Table.Column
                    title="编号"
                    dataIndex="pkey"
                    key="编号"
                />
                <Table.Column
                    title="标题"
                    dataIndex="name"
                    key="标题"
                    // render={(title, record) => {
                    //     return <a onClick={() => {
                    //         Modal.info({
                    //             title: record.title,
                    //             content: (
                    //                 <MarkdownHtml markdown={record.content}/>
                    //             ),
                    //             maskClosable: true,
                    //             icon: null,
                    //             onOk() {
                    //             },
                    //         });
                    //     }}>{title}</a>
                    // }}
                />
                <Table.Column
                    title="难度"
                    dataIndex="difficulty"
                    key="difficulty"
                    width={60}
                    render={(difficulty) => (
                        <span>
                                {difficulty === 0 && "简单"}
                            {difficulty === 1 && "一般"}
                            {difficulty === 2 && "困难"}
                            {difficulty === 3 && "高级"}
                            {difficulty === 4 && "专家"}
                            </span>
                    )}
                />
                <Table.Column
                    title="标签"
                    dataIndex="tags"
                    key="tags"
                    render={(tags) => (
                        <span>
                                {tags && JSON.parse(tags).map(tag => <Tag>{tag}</Tag>)}
                            </span>
                    )}
                />

            </Table>
        </Modal>
    )
};

ChallengesSelectModal.propTypes = {
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

export default ChallengesSelectModal;