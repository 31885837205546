import React, {useRef,useState} from 'react';
import {Space} from 'antd';
import DataTable from '../../../components/data/data-table2';
import CoursesLessonsList from './courses-lessons-list';

const CoursesList = ({user_id}) => {
    const [editdata, setEditData] = useState(null);
    const table = useRef();

    const config = {
        table: table,
        title: "课程",
        tableName: "courses",
        pagination: false,
        sorter:{field:"name", order:"ascend"},
        size: "small",
        columns: [{
            title: '课程名称',
            dataIndex: 'name',
        }, {
            title: '课程类型',
            dataIndex: 'type',
        }, {
            title: '课时数',
            dataIndex: 'keshi',
        }, {
            title: '上课时长',
            dataIndex: 'duration',
        }, {
            title: '操作',
            fixed: 'right',
            width: 60,
            render: (record) => (<Space>
                <a target="_blank" key="1">
                    <div
                        onClick={() => {
                            setEditData(record);
                        }}
                    >
                        查看
                    </div>
                </a>
            </Space>)
        }]
    };
    return (
        <div>
            <DataTable {...config} table={table} />
            {editdata &&
            <CoursesLessonsList
                visible={!!editdata}
                data={editdata}
                user_id={user_id}
                onOk={() => {
                    setEditData(null);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setEditData(null);
                }}
            />
            }
        </div>
    )
};


export default CoursesList;


