import PropTypes from 'prop-types';
import React from 'react';
import Key2text from './key2text';

var hljs = require('highlight.js'); // https://highlightjs.org/
var md = require('markdown-it')({
    html: true,
    highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
            try {
                return hljs.highlight(lang, str).value;
            } catch (__) {
            }
        }

        return ''; // use external default escaping
    }
});

const ProblemInfo = (props) => {
    const {
        statement,
        input_format,
        output_format,
        testcases
    } = props.problem;
    return (
        <div {...props}>
            <h3>
                题目背景
            </h3>
            <div>
            </div>
            <div className={'challenge_problem_statement'}>
                <div className={'challenge_problem_statement_body'}
                     dangerouslySetInnerHTML={{__html: md.render(statement || '')}}>

                </div>
            </div>
            <h3>
                输入输出格式
            </h3>
            <div className={'challenge_input_format'}>
                <div className={'challenge_input_format_title'}>
                    <p><strong>输入格式</strong></p>
                </div>
                <div className={'challenge_input_format_body'}
                     dangerouslySetInnerHTML={{__html: md.render(input_format || '')}}>

                </div>
            </div>
            <div className="challenge_output_format">
                <div className="challenge_output_format_title">
                    <p><strong>输出格式</strong></p>
                </div>
                <div className="challenge_output_format_body"
                     dangerouslySetInnerHTML={{__html: md.render(output_format || '')}}>
                </div>
            </div>
            <h3>
                输入输出样例
            </h3>
            {
                testcases && testcases.map((item, index) => {
                    return (
                        <div key={index}>
                            {/*{item.input}*/}
                            {/*<Key2text ali_key={item.input}/>*/}
                            {/*<Key2text ali_key={item.output}/>*/}
                            {/*{item.input_str && (*/}
                                <div className="challenge_sample_input">
                                    <div className="challenge_sample_input_title">
                                        <p><strong>输入样例#{index}</strong></p>
                                    </div>
                                    <div className="challenge_sample_input_body">
                                        <div className="highlight">
                                            <pre><Key2text key={item.input} ali_key={item.input}/></pre>
                                        </div>
                                    </div>
                                </div>
                            {/*// )}*/}
                            {/*//*/}
                            {/*// {item.output_str && (*/}
                                <div className="challenge_sample_output">
                                    <div className="challenge_sample_output_title">
                                        <p><strong>输出样例#{index}</strong></p>
                                    </div>
                                    <div className="challenge_sample_output_body">
                                        <div className="highlight">
                                            <pre><Key2text key={item.output} ali_key={item.output}/></pre>
                                        </div>

                                    </div>
                                </div>
                            {/*// )}*/}

                        </div>
                    )
                })
            }

        </div>

    );
};


ProblemInfo.propTypes = {
    problem: PropTypes.object.isRequired,
    // statement: PropTypes.string,
    // input_format: PropTypes.string,
    // output_format: PropTypes.string,
};

ProblemInfo.defaultProps = {
    small: false
};


export default ProblemInfo;