/**
 * @name: 教师列表
 * @description:
 * @author: Wood
 * @date: 2021/5/23
 * @update:
 */
import PropTypes from 'prop-types';
import React from 'react';
import {Table, Divider} from 'antd';
import styles from './teacher-list.module.css';
import SexIcon from '../../../components/sex-icon';
import DictName from '../../../components/dict/dict-name';

const {Column} = Table;

const TeacherList = ({list, onEdit, onReset}) => {
    list.sort((a, b) => a.is_file - b.is_file);
    const rowClass = (record) => {
        if (record.is_file === 1) {
            return styles["is_file"]
        }
    };

    return (
        <div>
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
                rowClassName={rowClass}
            >
                {/*<Column*/}
                {/*title="工号"*/}
                {/*key="工号"*/}
                {/*/>*/}
                {/*<Column*/}
                {/*title="职级"*/}
                {/*key="职级"*/}
                {/*/>*/}
                <Column
                    title="姓名/昵称"
                    dataIndex="nick_name"
                    key="姓名"
                    render={(nick_name, record) => {
                        if (record.other_name) {
                            return <span>{nick_name}({record.other_name}) <SexIcon sex={record.sex}/></span>;
                        } else {
                            return <span>{nick_name} <SexIcon sex={record.sex}/></span>;
                        }
                    }}
                />
                <Column
                    title="手机号"
                    dataIndex="mobile_number"
                    key="手机号"
                />
                <Column
                    title="登录账号"
                    dataIndex="login_name"
                    key="登录账号"
                />
                <Column
                    title="状态"
                    dataIndex="is_file"
                    key="状态"
                    render={(is_file) => {
                        return <DictName value={is_file} type_value={"is_file"} />
                    }}
                />
                <Column
                    title="微信"
                    dataIndex="wx_union_id"
                    key="微信"
                    render={(wx_union_id) => (
                        <span>{wx_union_id ? "已绑定" : ""}</span>
                    )}
                />
                <Column
                    title="操作"
                    key="action"
                    width={120}
                    render={(text, record) => (
                        <div>
                            <a onClick={() => {
                                onEdit && onEdit(record)
                            }}>修改</a>
                            <Divider type="vertical"/>
                            <a onClick={() => {
                                onReset && onReset(record)
                            }}>重置密码</a>
                        </div>
                    )}
                />
            </Table>
        </div>
    )
};

TeacherList.propTypes = {
    list: PropTypes.array,
    onEdit: PropTypes.func,
    onReset: PropTypes.func,
};

export default TeacherList;