/**
 * @name: 项目相关接口
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import axios from './rest-axios';

const getClasses = () => axios.get(`/v1/classes/`);
const getClass = (id) => axios.get(`/v1/classes/${id}`);
const postClasses = (model) => axios.post(`/v1/classes/`, model);
// const putClasses = (classes_id, name, remark,creater,status) => axios.put(`/v1/classes/${classes_id}`, {classes_id, name, remark,creater,status});
const putClasses = (model) => axios.put(`/v1/classes/${model.id}`, model);

export {
    getClasses,
    postClasses,
    putClasses,
    getClass
};
