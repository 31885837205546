
import axios from './rest-axios';

const putCoursesLessons = (model) => axios.put(`/v1/courses_lessons/${model.id}`, model);
const postCoursesLessons = (model) => axios.post(`/v1/courses_lessons/${model.id}`, model);

export {
    putCoursesLessons,
    postCoursesLessons
};
