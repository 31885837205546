/**
 * @name: 班级列表
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Icon as LegacyIcon} from '@ant-design/compatible';
import {PlusOutlined} from '@ant-design/icons';
import {
    Button,
    Modal,
    Input,
    Alert,
    Table,
    Divider,
    Tabs,
    Tag,
    Card,
    List,
    Row,
    Col,
    Avatar,
    Descriptions,
    Anchor,
    Affix, Space,
} from 'antd';
import PageContent from '../../../components/page-content/page-content';
// import CoursesEditModal from './classes-edit';
import {getCoursesDetail} from "../../../lib/api_courses";
import MarkdownHtml from '../../../components/markdown-html/markdown-html';
import LessonsAddMutlModal from "./lessons_add_mutl_modal";
import LessonsEditModal from "./lessons_edit_modal";
import styles from './lessons.module.css';
import LessonsAddModal from "./lessons_add_modal";

const IconText = ({type, text}) => (
    <span>
    <LegacyIcon type={type} style={{marginRight: 8}}/>
        {text}
  </span>
);

const CoursesType = ({type}) => (
    <span>
        {type === 0 && "Scratch"}
        {type === 1 && "Python"}
        {type === 2 && "C++"}
        {type === 3 && "视频"}
    </span>
);

class Lessons extends Component {
    state = {
        data: [],
    };

    componentDidMount() {
        this.loadClasses();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadClasses = () => {
        getCoursesDetail(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        data: result.data
                    })
                } else {

                }
            })
    };

    renderdescription = (item) => {
        switch (item.type) {
            case 0:
                // Scratch
                return (<div>
                    <div style={{marginBottom: 5}}>
                        {/*视频：*/}
                        {/*<Divider type={"vertical"}/>*/}
                        {/*PPT：*/}
                        {/*<Divider type={"vertical"}/>*/}
                        {/*课件：*/}
                        {/*<Divider type={"vertical"}/>*/}
                        上课模板：{item.scratch_id &&
                        <a href={`https://xmybc.com/scratch/projects/${item.scratch_id}`} target="_blank"
                           rel="noopener noreferrer">查看</a>}
                        <Divider type={"vertical"}/>
                        {/*素材：*/}
                        {/*<Divider type={"vertical"}/>*/}
                        作业模板：{item.homework_scratch_id &&
                        <a href={`https://xmybc.com/scratch/projects/${item.homework_scratch_id}`} target="_blank"
                           rel="noopener noreferrer">查看</a>}
                        <Divider type={"vertical"}/>
                        参考答案：{item.homework_answer_id ?
                        <a href={`https://xmybc.com/scratch/projects/${item.homework_answer_id}`} target="_blank"
                           rel="noopener noreferrer">查看</a> : "-"}
                        <Divider type={"vertical"}/>
                        视频讲解：{item.video_url ?
                        <a href={item.video_url} target="_blank" rel="noopener noreferrer">查看</a> : "-"}
                    </div>
                    <div>
                        {item.description}
                    </div>
                    <div>
                        <div>
                            【作业】
                        </div>
                        <MarkdownHtml markdown={item.homework_desc}/>
                    </div>
                </div>)
            case 1:
                // Python
                return (
                    <div>
                        {item.description && <div><b>课程描述:</b>{item.description}</div>}
                        {item.description2 && <div><b>课程简述（公开）:</b>{item.description2}</div>}
                        {item.objective && <div><b>课程目标:</b>{item.objective}</div>}
                        {item.points && <div><b>教学重难点:</b>{item.points}</div>}
                        {/*<div>相关资源:{item.description2}</div>*/}
                        {/*<div>必做练习:{item.description2}</div>*/}
                        {/*<div>进阶练习:{item.description2}</div>*/}
                    </div>
                )
            default:
                return "其他类型";
        }
    };

    render() {
        let {data, filter} = this.state;


        return (
            <PageContent
                title={data.name}
                wrapperClassName={styles.container}
                routes={[
                    {
                        path: 'admin',
                        breadcrumbName: "首页",
                    },
                    {
                        path: 'courses',
                        breadcrumbName: '我的课程',
                    },
                    {
                        path: '',
                        breadcrumbName: data.name,
                    },
                ]}
                descriptions={
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item>
                            {data.keshi}课时
                            <Divider type={"vertical"}/>
                            {data.duration}分钟/课时
                            <Divider type={"vertical"}/>
                            <CoursesType type={data.type}/>
                            <Divider type={"vertical"}/>
                            {data.school_name}
                        </Descriptions.Item>
                        <Descriptions.Item>{data.description}</Descriptions.Item>

                    </Descriptions>
                }>
                <div className={styles.header}>
                    <Space>
                        {/*<Button type='primary' icon={<PlusOutlined/>}>添加课程</Button>*/}
                        <Button type='primary' icon={<PlusOutlined/>}
                                onClick={() => this.setState({addModal: true})}
                        >批量添加课时</Button>
                    </Space>
                </div>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <Card bordered={false}>

                            <List
                                itemLayout="vertical"
                                // size="large"
                                // pagination={{
                                //     onChange: page => {
                                //         console.log(page);
                                //     },
                                //     pageSize: 3,
                                // }}
                                dataSource={data.lessons}
                                // footer={
                                //     <div>
                                //         <b>ant design</b> footer part
                                //     </div>
                                // }
                                renderItem={item => (
                                    <List.Item
                                        id={`lesson${item.tag_index}`}
                                        key={item.title}
                                        // actions={[
                                        //     <IconText type="star-o" text="156" key="list-vertical-star-o" />,
                                        //     <IconText type="like-o" text="156" key="list-vertical-like-o" />,
                                        //     <IconText type="message" text="2" key="list-vertical-message" />,
                                        // ]}
                                        extra={
                                            <Button type={"primary"}
                                                    onClick={() => this.setState({editRecord: item})}>修改</Button>
                                        }
                                    >

                                        <Row type={"flex"} gutter={[16, 0]}>
                                            <Col>
                                                <img
                                                    width={90}
                                                    alt="logo"
                                                    src={item.image_url}
                                                />
                                            </Col>
                                            <Col style={{flex: 1}}>
                                                <List.Item.Meta
                                                    // avatar={<Avatar src={item.avatar} />}
                                                    title={<span>第{item.tag_index}课 {item.name} {item.ishide==1?<Tag color="error">隐藏</Tag>:""}</span>}
                                                    description={(<>{this.renderdescription(item)}</>)}
                                                />
                                                {/*123*/}
                                            </Col>
                                        </Row>
                                        {/*{item.content}*/}
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </div>
                    <div className={styles.right}>
                        {/*<Anchor>*/}
                            {data.lessons && data.lessons.map(item =>
                                <Anchor.Link href={`#lesson${item.tag_index}`}
                                             title={<span>第{item.tag_index}课 {item.name} {item.ishide==1?<Tag color="error">隐藏</Tag>:""}</span>}/>
                            )}
                        {/*</Anchor>*/}
                    </div>
                </div>

                {/*<LessonsAddModal*/}
                {/*    visible={this.state.addModal}*/}
                {/*    id={this.props.id}*/}
                {/*    onOk={() => {*/}
                {/*        this.setState({addModal: false});*/}
                {/*        this.loadClasses();*/}
                {/*    }}*/}
                {/*    onCancel={() => {*/}
                {/*        this.setState({addModal: false})*/}
                {/*    }}*/}
                {/*/>*/}

                <LessonsAddMutlModal
                    visible={this.state.addModal}
                    id={this.props.id}
                    onOk={() => {
                        this.setState({addModal: false});
                        this.loadClasses();
                    }}
                    onCancel={() => {
                        this.setState({addModal: false})
                    }}
                />
                <LessonsEditModal
                    data={this.state.editRecord}
                    onOk={() => {
                        this.setState({editRecord: null});
                        this.loadClasses();
                    }}
                    onCancel={() => {
                        this.setState({editRecord: null})
                    }}
                />
            </PageContent>
        );
    }
}

Lessons.propTypes = {
    id: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
    school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Lessons));
