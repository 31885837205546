import React from 'react';
import {Form, Input} from 'antd';
import MdEditor from '../../../components/md-editor/md-editor';

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

const DocumentForm = ({form, onFinish, initialValues}) => {
    return (
        <Form {...formItemLayout}
              form={form}
              onFinish={onFinish}
              initialValues={initialValues}
        >
            <Form.Item name={"title"} label={"标题"}>
                <Input/>
            </Form.Item>
            <Form.Item name={"content"} label={"正文"}>
                <MdEditor/>
            </Form.Item>
        </Form>
    )
};


export default DocumentForm;