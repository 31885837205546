import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Input, Form, Radio} from 'antd';
import FormOptions from './form_options';
import MdEditor from '../../../components/md-editor/md-editor';


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const FormDanXuanAnswer = ({value, onChange}) => {
    const [form] = Form.useForm();
    const [config, setConfig] = useState({
        answer: "",
    });

    useEffect(() => {
        if(value){
            let dt = JSON.parse(value);
            setConfig(dt);
            form.setFieldsValue(dt)
        }
    }, [value]);

    const handleChange = (changedValues, allValues) => {
        // console.log(allValues);
        onChange && onChange(JSON.stringify(allValues))
    };

    return (
        <Form
            {...layout}
            form={form}
            onValuesChange={handleChange}
        >
            <Form.Item
                label="答案"
                name="answer"
                rules={[{
                    required: true,
                    message: '请输入答案'
                }]}
            >
                <Radio.Group>
                    <Radio value="正确">正确</Radio>
                    <Radio value="错误">错误</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    )
};


FormDanXuanAnswer.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default FormDanXuanAnswer;