/**
 * @name: 手机号登录页面，todo 需要完善验证码登录功能
 * @description:
 * @author: Wood
 * @date: 2019/4/4
 * @update:
 */
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import md5 from 'md5';
import { LockOutlined, MobileOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

import {postAccountLogin} from "../../lib/api_account.js";
import {setAccount} from "../../reducers/account.js";
import {hideLoginModal} from "../../reducers/login-modal.jsx";
// import {setSchool} from "../../reducers/school";
import styles from './login-modal.module.css'


class MobileLogin extends Component {
    state = {
        login_name: '',
        password: '',
        message: ''
    };

    handleLogin = () => {
        //todo 登录操作
        // console.log(this.state);
        let {login_name, password} = this.state;
        if (login_name.length === 0) {
            return this.setState({message: '请输入手机号码'})
        }
        if (password.length === 0) {
            return this.setState({message: '请输入密码'})
        }
        postAccountLogin(login_name, md5(password))
            .then((result) => {
                if (result.code === 0) {
                    this.props.setAccount(result.data);
                    this.props.hideLoginModal();
                    // if (result.data.schools && result.data.schools.length > 0) {
                    //     this.props.setSchool(result.data.schools[0]);
                    // }   // // 跳转到首页
                    // this.props.history.push("/");
                } else {
                    this.setState({message: result.message})
                }
            })
    };

    render() {
        return (
            <div>
                <div className={styles.item}>
                    <Input size="large"
                           prefix={<MobileOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                           value={this.state.login_name}
                           placeholder='请输入手机号码'
                           onChange={(event) => this.setState({login_name: event.target.value})}/>
                </div>
                <div className={styles.item}>
                    <Input size="large"
                           prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                           value={this.state.password}
                           placeholder='请输入密码'
                           type='password'
                           onChange={(event) => this.setState({password: event.target.value})}/>
                </div>
                <div className={styles.message}>{this.state.message}</div>
                <div className={styles.item}>
                    <Button block
                            size='large'
                            type='primary'
                            className='button-color-sunset'
                            onClick={this.handleLogin}>
                        登录
                    </Button>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    setAccount: (account) => dispatch(setAccount(account)),
    hideLoginModal: () => dispatch(hideLoginModal()),
    // setSchool:(school)=>dispatch(setSchool(school)),
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileLogin));