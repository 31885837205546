
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Select} from 'antd';
import {getTeachers} from "../../../lib/api_teachers";


class TeacherSelect extends Component {
    state = {
        users: [],
        selectedKeys: []
    };

    componentDidMount() {
        this.loadTeachers();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 下载所有教师数据
    loadTeachers = () => {
        getTeachers()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        users: result.data
                    })
                } else {

                }
            })
    };


    render() {
        const {users} = this.state;
        const {value, onChange} = this.props;
        return (
            <Select value={value}
                    style={{width: 120}}
                    onChange={(value) => {
                        onChange && onChange(value)
                    }}>
                {users.map(user => (
                    <Select.Option key={user.id} value={user.id}>{user.nick_name}</Select.Option>
                ))}

            </Select>

        )
    }

}


TeacherSelect.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number
};

export default (TeacherSelect);
