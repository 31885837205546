import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './reducers';
import {ConfigProvider} from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
// import {TableProvider} from 'table-render';
// import {TableProvider} from './components/m-table';

moment.locale('zh-cn');


ReactDOM.render((
    <BrowserRouter>
        <Provider store={store}>
            <ConfigProvider locale={zh_CN}>
                {/*<TableProvider>*/}

                    <App/>

                {/*</TableProvider>*/}
            </ConfigProvider>
        </Provider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
