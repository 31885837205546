import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Input, Modal } from 'antd';
import {postHuodong1Templates} from "../../../../../lib/api_huodong";
import AliyunOSSUpload from '../../../../../components/aliyun-oss-upload';


class TemplatesAddModal extends Component {
    state = {
        loading: false
    };

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleOK = () => {
        this.props.onOk && this.props.onOk();
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.setState({
                    loading: true
                });
                postHuodong1Templates(values)
                    .then(result => {
                        if (result.code === 0) {
                            this.handleOK();
                        } else {
                            message.error(result.message);
                        }
                    })
                    .catch(err => {
                        message.error(err.message);
                    })
                    .finally(() => {
                        this.setState({
                            loading: false
                        })
                    });
            }
        });

    };


    render() {
        const {getFieldDecorator} = this.props.form;
        const {data, visible} = this.props;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };
        return (
            <Modal
                title="添加课程"
                visible={visible}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                confirmLoading={this.state.loading}
                destroyOnClose={true}
            >
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item label={"模板名称"}>
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入模板名称',
                                },
                            ],
                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item label="预览图片">
                        {getFieldDecorator('preview', {})(
                            <AliyunOSSUpload multiple={false} prefix={"huodong/1/templates/"}/>
                        )}
                    </Form.Item>
                    <Form.Item label="相关素材文件">
                        {getFieldDecorator('files', {})(
                            <AliyunOSSUpload multiple={true} prefix={"huodong/1/templates/"}/>
                        )}
                    </Form.Item>
                    <Form.Item label={"配置"}>
                        {getFieldDecorator("config", {})(<Input.TextArea autoSize={{minRows: 5}}/>)}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}


TemplatesAddModal.propTypes = {
    visible: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};


const mapStateToProps = state => ({
    school: state.school
});

const mapDispatchToProps = dispatch => ({});


export default Form.create({name: 'courses-add'})(
    withRouter(connect(
        mapStateToProps,
        mapDispatchToProps
    )(TemplatesAddModal)));

