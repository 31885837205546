
import axios from './rest-axios';

const getProblems = (data) => axios.get(`/v1/cpp/problems/`, data);
const postProblem = (data) => axios.post(`/v1/cpp/problems/`, data);
const getProblem = (id) => axios.get(`/v1/cpp/problems/${id}`);
const putProblem = (data) => axios.put(`/v1/cpp/problems/${data.id}`, data);
const prevProblem = (id) => axios.get(`/v1/cpp/problems/${id}/prev`);
const nextProblem = (id) => axios.get(`/v1/cpp/problems/${id}/next`);
const getProblemTestCases = (id) => axios.get(`/v1/cpp/problems/${id}/testcases`);
const getProblemTestCase = (id,name) => axios.get(`/v1/cpp/problems/${id}/testcases/${name}`);
const postProblemTestCase = (id,name,data) => axios.post(`/v1/cpp/problems/${id}/testcases/${name}`, {data});
const changeToTestcase = (id)=> axios.post(`/v1/cpp/problems/${id}/changetotestcase`);
const deleteProblem = (id)=>axios.delete(`/v1/cpp/problems/${id}`);

export {
    getProblems,
    postProblem,
    getProblem,
    putProblem,
    prevProblem,
    nextProblem,
    getProblemTestCases,
    getProblemTestCase,
    postProblemTestCase,
    changeToTestcase,
    deleteProblem,
};
