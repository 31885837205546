/**
 * @name: 关联学生对话框
 * @description:
 * @author: Wood
 * @date: 2024/3/6
 * @update:
 */
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {postStudentsInSchool, getStudents,} from "../../../lib/api_students.js";
import {Modal, Input, Table} from 'antd';
import moment from 'moment';
import SexIcon from "../../../components/sex-icon";
import DictName from "../../../components/dict/dict-name";
import {ExclamationCircleFilled} from '@ant-design/icons';

const {Column} = Table;

const ClassesStudentAddModal = ({team_id, onCancel, onOk}) => {
    const [list, setList] = useState([]);
    const [filterKey, setFilterKey] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const fetchData = () => {
        getStudents(0)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };

    useEffect(fetchData, []);

    const handleOk = () => {
        console.log(selectedRowKeys);
        postStudentsInSchool(team_id, selectedRowKeys)
            .then(result => {
                if (result.code === 0) {
                    onOk && onOk();
                }
            })
    }
    const handleCancel = () => {
        onCancel && onCancel();
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    let cur_list = list.filter(item => item.is_file === 0 && (filterKey
        ? (item.nick_name && item.nick_name.includes(filterKey))
        || (item.login_name && item.login_name.includes(filterKey))
        || (item.other_name && item.other_name.includes(filterKey))
        || (item.parent_phone && item.parent_phone.includes(filterKey))
        || (item.remark && item.remark.includes(filterKey))
        : true));

    return (
        <Modal
            title="关联学生"
            open={true}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
            destroyOnClose={true}
        >
            <Input
                placeholder="关键字搜索"
                onChange={e => setFilterKey(e.target.value)}
                style={{width: 200, marginLeft: 10}}
                allowClear={true}
            />

            <Table
                dataSource={cur_list}
                rowKey={"id"}
                pagination={false}
                size="small"
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                scroll={{
                    y: 400,
                }}
            >
                <Column
                    title="账号"
                    dataIndex="login_name"
                    key="login_name"
                    ellipsis={true}
                    sorter={(a, b) => a.login_name < b.login_name ? 1 : -1}
                />
                <Column
                    title="姓名"
                    dataIndex="nick_name"
                    key="nick_name"
                    width={140}
                    sorter={(a, b) => a.login_name < b.login_name ? 1 : -1}
                    render={(nick_name, record) => {
                        let text = nick_name;
                        if (record.other_name) {
                            text = `${nick_name}(${record.other_name})`;
                        }
                        return (
                            <span>
                                    <Link to={`/affairs/students/${record.id}`}>{text}</Link>
                                    <SexIcon sex={record.sex}/>
                                </span>
                        )
                    }}
                />

                <Column
                    title="联系电话"
                    dataIndex="parent_phone"
                    key="parent_phone"
                    width={120}
                />
                <Column
                    title="年龄"
                    dataIndex="birthday"
                    key="birthday"
                    width={70}
                    render={(birthday) => (
                        <span>
                                {birthday ? <>{moment().diff(moment(birthday), 'years') + 1}</> : ""}
                            </span>
                    )}
                    sorter={(a, b) => a.birthday < b.birthday ? 1 : -1}
                />
                <Column
                    title="年级"
                    dataIndex="grade"
                    key="年级"
                    width={70}
                    sorter={(a, b) => a < b ? 1 : -1}
                    render={(grade) => <DictName value={grade} type_value={"grade"}/>}
                />
                <Column
                    title="课程名称"
                    dataIndex="course_name"
                    key="course_name"
                    ellipsis={true}
                />
                <Column
                    title="剩余课时"
                    dataIndex="keshi_pay"
                    key="keshi_pay"
                    width={80}
                    render={(keshi_pay, record) => (
                        <span>

                                {record.keshi_pay + record.keshi_gift < 10 ? (
                                    <span style={{color: "red"}}>{record.keshi_pay}+{record.keshi_gift}
                                        <ExclamationCircleFilled/></span>
                                ) : (
                                    <span>{record.keshi_pay}+{record.keshi_gift}</span>
                                )}
                            </span>
                    )}
                />
                <Column
                    title="报名时间"
                    dataIndex="sign_up_time"
                    key="sign_up_time"
                    sorter={(a, b) => a.sign_up_time < b.sign_up_time ? 1 : -1}
                    width={120}
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                    key="remark"
                    ellipsis={true}
                    // render={(remark)=>(
                    //     <Typography.Text ellipsis style={{"width": 100}} title={remark}>{remark}</Typography.Text>
                    // )}
                />
            </Table>
        </Modal>
    );
}


export default ClassesStudentAddModal;