/**
 * @name: 登录页面
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import MobileLogin from './mobile-login.jsx';
import {getAccountInfo} from "../../lib/api_account.js";
import {setAccount} from "../../reducers/account.js";
import {setRooms} from "../../reducers/rooms";

const Login = ({setAccount, setRooms}) => {

    useEffect(() => {
        // 获得登录用户信息
        getAccountInfo()
            .then(res => {
                if (res.code===0) {
                    // 设置用户信息
                    setAccount(res.data);
                    setRooms(res.data.rooms);
                } else {
                }
            })
            .catch((err) => {
                // 提示错误
                // jumpToAuth();
                console.log(err);
                // this.props.showLoginModal();
            });
    }, []);

    return (
        <div style={{width: 368, margin: "0 auto", paddingTop: 150}}>
            <h1 style={{textAlign: "center"}}>小码蚁编程 - 管理后台</h1>
            <MobileLogin/>
        </div>
    )
}

export default withRouter(connect(
    state => ({}),
    dispatch => ({
        setAccount: (account) => dispatch(setAccount(account)),
        setRooms: (rooms) => dispatch(setRooms(rooms)),
    })
)(Login));