import axios from './rest-axios';

const postCard = (model) => axios.post(`/v1/card`, model);
const postCardBuLu = (model) => axios.post(`/v1/card/bulu`, model);
const putCard = (model) => axios.put(`/v1/card/${model.id}`, model);

export {
    postCard,
    postCardBuLu,
    putCard
};
