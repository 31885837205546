import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Button} from 'antd';

export function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        border:"solid 1px #1890ff",
        borderRadius:5,
        padding: "3px 3px",
        textAlign:"center",
        margin:3,
        backgroundColor:"#ffffff",
        cursor:"pointer",
        display:"inline-block",
        width: 30
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {/* ... */}
            {props.data.id}
        </div>
    );
}