import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import {Table, Divider, Popconfirm, Button, message, Card,Row,Col} from 'antd';
import PageContent from '../../../../../components/page-content/page-content';
// import {getHomeworks, deleteHomework} from "../../lib/api_homeworks";
import {getHuodong1Templates} from "../../../../../lib/api_huodong";
import TemplatesAddModal from './add';
import TemplatesEditModal from './edit';

const {Column} = Table;

class Templates extends Component {
    state = {
        list: [],
    };

    componentDidMount() {
        this.fetchData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 获取数据
    fetchData = () => {
        getHuodong1Templates()
            .then(result => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })

                } else {
                    message.error(result.message);
                }
            })
    };

    // handleRemove = (record) => {
    //     deleteHomework(record.id)
    //         .then(res => {
    //             if (res.code === 0) {
    //                 message.success('已删除');
    //                 this.loadHomeworks();
    //             } else {
    //                 // message.error(res.message);
    //             }
    //         })
    // };

    render() {
        let {list} = this.state;
        return (
            <PageContent title={"活动模板"}>
                <Card bordered={false}>

                    <Button type='primary'
                            icon={<PlusOutlined />}
                            style={{marginBottom: 10}}
                            onClick={() => this.setState({addModal: true})}
                    >添加模板</Button>
                    <Row gutter={[16,16]}>
                    {list.map(item => {
                            return (
                                <Col>
                                <Card
                                    hoverable
                                    size={"small"}
                                    style={{width: 240}}
                                    cover={<img alt="preview" src={item.preview} />}
                                    onClick={() => this.setState({editRecord: item})}
                                >
                                    <Card.Meta title={item.name} />
                                </Card>
                                </Col>)
                        }
                    )}
                    </Row>

                    {/*<Table dataSource={list} rowKey={"id"} size={"small"}>*/}
                    {/*<Column*/}
                    {/*title="标题"*/}
                    {/*dataIndex="name"*/}
                    {/*key="name"*/}
                    {/*/>*/}


                    {/*<Column*/}
                    {/*title="操作"*/}
                    {/*key="action"*/}
                    {/*render={(text, record) => (*/}
                    {/*<div>*/}
                    {/*<Link to={`/homeworks/${record.id}`}>统计</Link>*/}
                    {/*<Divider type="vertical"/>*/}
                    {/*<Link to={`/homeworks/${record.id}/edit`}>修改</Link>*/}
                    {/*<Divider type="vertical"/>*/}
                    {/*<Popconfirm title='确定删除该作业吗？'*/}
                    {/*onConfirm={() => this.handleRemove(record)}*/}
                    {/*okText="确定"*/}
                    {/*cancelText="取消"*/}
                    {/*>*/}
                    {/*<Link to={`/courses/${record.id}`}>删除</Link>*/}
                    {/*</Popconfirm>*/}
                    {/*</div>*/}
                    {/*)}*/}
                    {/*/>*/}
                    {/*</Table>*/}
                </Card>
                <TemplatesAddModal
                    visible={this.state.addModal}
                    onOk={() => {
                        this.setState({addModal: false});
                        this.fetchData();
                    }}
                    onCancel={() => {
                        this.setState({addModal: false})
                    }}
                />
                <TemplatesEditModal
                    data={this.state.editRecord}
                    onOk={() => {
                        this.setState({editRecord: null});
                        this.fetchData();
                    }}
                    onCancel={() => {
                        this.setState({editRecord: null})
                    }}
                />
            </PageContent>
        );
    }
}


const mapStateToProps = state => ({
    school: state.school
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Templates));

