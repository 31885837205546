/**
 * @name: 课程资源相关接口
 * @description:
 * @author: Wood
 * @date: 2019/4/3
 * @update:
 */
import axios from './rest-axios';

const getLessons = (id) => axios.get(`/v1/lessons/${id}`);
const postLessons = (team_id, names) => axios.post(`/v1/lessons/`, {team_id, names});
const putLessons = (id, model) => axios.put(`/v1/lessons/${id}`, model);
const postCopyLessons = (id, date) => axios.post(`/v1/lessons/${id}/copy`, {date});
const postDianmingLessons = (id, model) => axios.post(`/v1/lessons/${id}/dianming`, model);
const postBuDian = (id, time, user_id) => axios.post(`/v1/lessons/${id}/budian`, {time, user_id});

const deleteLessons = (id) => axios.delete(`/v1/lessons/${id}`);
const putLessonsUsers = (courses_id, user_id, create_on, keshi) => axios.put(`/v1/lessons_users`, {
    courses_id,
    user_id,
    create_on,
    keshi
});
// const putKeshi = (id, keshi, remark, create_on, is_gift) => axios.put(`/v1/keshi/${id}`, {
//     id, keshi, remark, create_on, is_gift
// });
// const postKeshi = (model) => axios.post(`/v1/keshi`, model);

export {
    getLessons,
    deleteLessons,
    postLessons,
    putLessons,
    postDianmingLessons,
    postBuDian,
    putLessonsUsers,
    // putKeshi,
    // postKeshi,
    postCopyLessons,
};
