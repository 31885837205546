import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row} from 'antd';

const SolutionsConfig = ({value, onChange}) => {
    const [solutions, setSolutions] = useState([]);

    useEffect(() => {
        setSolutions(JSON.parse(value || '[]'));
    }, [value]);

    const handleChange = (index, field, value) => {
        const newSolutions = [...solutions];
        newSolutions[index][field] = value;
        setSolutions(newSolutions);
        onChange(JSON.stringify(newSolutions));

    }

    return (
        <div>
            {/* Add solutions for each problem */
                solutions.map((solution, index) => (
                    <Row key={index}>
                        <Col span={6}><Input value={solution.language}
                                             onChange={(e) => handleChange(index, "language", e.target.value)}/></Col>
                        <Col span={15}>
                            <Input.TextArea value={solution.data} onChange={(e) => handleChange(index, "data", e.target.value)}/>
                        </Col>
                        <Col span={3}>
                            <Button onClick={() => {
                                const newSolutions = [...solutions];
                                newSolutions.splice(index, 1);
                                setSolutions(newSolutions);
                                onChange(JSON.stringify(newSolutions));
                            }}>删除</Button>

                        </Col>
                    </Row>
                ))
            }

            <Button onClick={() => setSolutions([...solutions, {language: '', data: ''}])}>Add Solution</Button>
        </div>
    );
}


export default SolutionsConfig;