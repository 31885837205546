import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {PlusOutlined, DeleteOutlined,ShoppingCartOutlined} from '@ant-design/icons';
import {Table, Divider, Popconfirm, Button, message, Tag, Input, Card, Space, Row, Col} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getQuestions, delQuestion} from "../../../lib/api_questions";
import QustionAddModal from './question_add_modal_danxuan';
import QuestionEditModal from './question_edit_modal_danxuan';
import QustionAddModalPanDuan from './question_add_modal_panduan';
import QuestionEditModalPanDuan from './question_edit_modal_panduan';
import QustionAddModalPython from './question_add_modal_python';
import QustionEditModalPython from './question_edit_modal_python';
import ExaminationModal from './examination_modal';
import styles from './index.module.css';

const {Column} = Table;

const Questions = () => {
    const [list, setlist] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [editModal, seteditModal] = useState(null);
    const [addModalpanduan, setaddModalpanduan] = useState(false);
    const [editModalpanduan, seteditModalpanduan] = useState(null);
    const [addModalpython, setaddModalpython] = useState(false);
    const [editModalpython, seteditModalpython] = useState(null);
    const [selected, setselected] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [createPaper, setcreatePaper] = useState(null);

    const fetchData = () => {
        getQuestions()
            .then((result) => {
                if (result.code === 0) {
                    setlist(result.data);
                }
            })
    };

    useEffect(fetchData, []);

    const handleDelete = (id) => {
        // console.log(selectedRows)
        delQuestion(selectedRows.map(m => m.id))
            .then((result) => {
                if (result.code === 0) {
                    fetchData();
                }
            })
    };

    const handleAddPocket = () => {
        let index = 0;
        for(let i in selectedRows){
            let record = selectedRows[i];
            if (selected.some(m => m.id === record.id)) {
                // message.warn("重复添加")
            } else {
                selected.push(record);
                index += 1;
            }
        }
        setselected([...selected]);
        message.success(`成功添加${index}条`);
    };

    const createExamPaper = ()=>{
        setcreatePaper(selected);
    };

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        // selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <PageContent title={"试题库"}>
            <div className={styles.pocket}>
                <div>单选题：{selected.filter(m => m.type === "单选题").length}</div>
                <div>判断题：{selected.filter(m => m.type === "判断题").length}</div>
                <div>python题：{selected.filter(m => m.type === "python").length}</div>
                <Space>
                    <Button onClick={createExamPaper}>生成试卷</Button>
                    <Button onClick={() => setselected([])}>清空</Button>
                </Space>
            </div>
            <Card bordered={false}>
                <Row justify={"space-between"}
                     style={{marginBottom: 10}}>
                    <Col>
                        <Space>
                            <Button icon={<ShoppingCartOutlined />}
                                    disabled={selectedRows.length === 0}
                                    onClick={handleAddPocket}>添加到试题篮</Button>

                            <Popconfirm
                                title={`是否删除${selectedRows.length}条记录？`}
                                onConfirm={handleDelete}
                                // onCancel={cancel}
                                okText="删除"
                                cancelText="取消"
                                disabled={selectedRows.length === 0}
                            >
                                <Button disabled={selectedRows.length === 0} icon={<DeleteOutlined />} danger>删除</Button>
                            </Popconfirm>

                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setAddModal(true)}>添加单选题</Button>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setaddModalpanduan(true)}>添加判断题</Button>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => setaddModalpython(true)}>添加Python题</Button>
                        </Space>
                    </Col>

                </Row>
                <Table dataSource={list} rowKey={"id"}
                       pagination={{
                           defaultPageSize:20
                       }}
                       size="small"
                       rowSelection={rowSelection}
                       scroll={{
                           // x: 1500,
                           y: `calc(100vh - 230px)`,
                       }}
                >
                    <Column
                        title="编号"
                        dataIndex="id"
                        key="id"
                        width={60}
                        render={(id,record) => (
                            <>
                            {record.type === "单选题" && <a onClick={() => seteditModal(record)}>{id}</a>}
                            {record.type === "判断题" && <a onClick={() => seteditModalpanduan(record)}>{id}</a>}
                            {record.type === "python" && <a onClick={() => seteditModalpython(record)}>{id}</a>}
                            </>
                        )}
                    />
                    <Column
                        title="类型"
                        dataIndex="type"
                        width={100}
                        key="类型"
                    />
                    <Column
                        title="标题"
                        dataIndex="title"
                        key="标题"
                        ellipsis={true}
                    />
                </Table>
            </Card>
            {addModal &&
            <QustionAddModal
                onOk={() => {
                    setAddModal(false);
                    fetchData();
                }}
                onCancel={() => {
                    setAddModal(false);
                }}
                visible={addModal}
            />
            }
            {editModal &&
            <QuestionEditModal
                data={editModal}
                onOk={() => {
                    seteditModal(null);
                    fetchData();
                }}
                onCancel={() => {
                    seteditModal(null);
                }}
            />
            }

            {addModalpanduan &&
            <QustionAddModalPanDuan
                onOk={() => {
                    setaddModalpanduan(false);
                    fetchData();
                }}
                onCancel={() => {
                    setaddModalpanduan(false);
                }}
                visible={addModalpanduan}
            />
            }
            {editModalpanduan &&
            <QuestionEditModalPanDuan
                data={editModalpanduan}
                onOk={() => {
                    seteditModalpanduan(null);
                    fetchData();
                }}
                onCancel={() => {
                    seteditModalpanduan(null);
                }}
            />
            }

            {addModalpython &&
            <QustionAddModalPython
                onOk={() => {
                    setaddModalpython(false);
                    fetchData();
                }}
                onCancel={() => {
                    setaddModalpython(false);
                }}
                visible={addModalpython}
            />
            }
            {editModalpython &&
            <QustionEditModalPython
                data={editModalpython}
                onOk={() => {
                    seteditModalpython(null);
                    fetchData();
                }}
                onCancel={() => {
                    seteditModalpython(null);
                }}
            />
            }
            {createPaper &&
            <ExaminationModal
                data={createPaper}
                onOk={() => {
                    setcreatePaper(null);
                    // fetchData();
                }}
                onCancel={() => {
                    setcreatePaper(null);
                }}
            />
            }

        </PageContent>
    );
};

export default Questions;
