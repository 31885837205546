import React from 'react';

const acceptedStyle = {
    color: "rgb(82, 196, 26)",
    fontWeight: "bold"
}
const normalStyle = {
    color: "rgb(250, 219, 20)",
    fontWeight: "bold"
}
const errorStyle = {
    color: "rgb(231, 76, 60)",
    fontWeight: "bold"
}


/**
 * 得分
 * @param value
 * @param style
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const SolutionRate = ({value, style, ...props}) => {
    if (value == null) return null;
    value = value * 100;
    if (value === 100) return <span style={{...acceptedStyle, ...style}} {...props}>{value}</span>;
    else if (value > 0) return <span style={{...normalStyle, ...style}} {...props}>{parseInt(value)}</span>;
    else return <span style={{...errorStyle, ...style}} {...props}>{parseInt(value)}</span>
}

export default React.memo(SolutionRate);
