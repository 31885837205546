/**
 * @name: 学生列表
 * @description:
 * @author: Wood
 * @date: 2024/2/28
 * @update:
 */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {deleteStudentFromClasses} from "../../../lib/api_students.js";
import {ExclamationCircleFilled} from '@ant-design/icons';
import {Table, Divider, Popconfirm, Typography, Space} from 'antd';
import StudentEditModal from './student_edit_modal';
import moment from 'moment';
import DictName from '../../../components/dict/dict-name';
import SexIcon from '../../../components/sex-icon';

const {Column} = Table;

const StudentList = ({list, classes_id, onChange}) => {
    const [editUserId, setEditUserId] = useState(null);

    // 移出班级
    const handleRemove = (record) => {
        deleteStudentFromClasses(classes_id, record.id)
            .then(() => {
                handleChange()
            })
    };

    // 数据更改后请求刷新
    const handleChange = () => {
        onChange && onChange();
    };

    return (
        <div>
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size="small"
            >
                <Column
                    title="账号"
                    dataIndex="login_name"
                    key="login_name"
                    ellipsis={true}
                    sorter={(a, b) => a.login_name < b.login_name ? 1 : -1}
                />
                <Column
                    title="姓名"
                    dataIndex="nick_name"
                    key="nick_name"
                    width={140}
                    sorter={(a, b) => a.login_name < b.login_name ? 1 : -1}
                    render={(nick_name, record) => {
                        let text = nick_name;
                        if (record.other_name) {
                            text = `${nick_name}(${record.other_name})`;
                        }
                        return (
                            <span>
                                <Link to={`/affairs/students/${record.id}`}>{text}</Link>
                                <SexIcon sex={record.sex}/>
                            </span>
                        )
                    }}
                />
                <Column
                    title="联系电话"
                    dataIndex="parent_phone"
                    key="parent_phone"
                    width={120}
                />
                <Column
                    title="年龄"
                    dataIndex="birthday"
                    key="birthday"
                    width={70}
                    render={(birthday) => (
                        <span>
                            {birthday ? <>{moment().diff(moment(birthday), 'years') + 1}</> : ""}
                        </span>
                    )}
                    sorter={(a, b) => a.birthday < b.birthday ? 1 : -1}
                />
                <Column
                    title="年级"
                    dataIndex="grade"
                    key="年级"
                    width={70}
                    sorter={(a, b) => a < b ? 1 : -1}
                    render={(grade) => <DictName value={grade} type_value={"grade"}/>}
                />
                <Column
                    title="课程名称"
                    dataIndex="course_name"
                    key="course_name"
                    ellipsis={true}
                />
                <Column
                    title="剩余课时"
                    dataIndex="keshi_pay"
                    key="keshi_pay"
                    width={80}
                    render={(keshi_pay, record) => (
                        <span>
                            {record.keshi_pay + record.keshi_gift < 10 ? (
                                <span style={{color: "red"}}>{record.keshi_pay}+{record.keshi_gift}
                                    <ExclamationCircleFilled/></span>
                            ) : (
                                <span>{record.keshi_pay}+{record.keshi_gift}</span>
                            )}
                        </span>
                    )}
                />
                <Column
                    title="报名时间"
                    dataIndex="sign_up_time"
                    key="sign_up_time"
                    sorter={(a, b) => a.sign_up_time < b.sign_up_time ? 1 : -1}
                    width={120}
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                    key="remark"
                    ellipsis={true}
                />
                <Column
                    title="操作"
                    key="action"
                    width={classes_id > 0 ? 120 : 60}
                    render={(text, record) => (
                        <Space size={0} split={<Divider type="vertical"/>}>
                            <Typography.Link
                                onClick={() => {
                                    setEditUserId(record.id);
                                }}>
                                修改
                            </Typography.Link>
                            {classes_id > 0 &&
                                <Popconfirm
                                    title='确定将该学生移出班级吗？'
                                    onConfirm={() => handleRemove(record)}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <Typography.Link>
                                        移出班级
                                    </Typography.Link>
                                </Popconfirm>
                            }
                        </Space>
                    )}
                />
            </Table>

            {editUserId && <StudentEditModal
                onOk={() => {
                    handleChange();
                    setEditUserId(null);
                }}
                onCancel={() => {
                    setEditUserId(null);
                }}
                id={editUserId}
            />}

        </div>
    );
}

export default StudentList;