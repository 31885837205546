
import axios from './rest-axios';

const getTasks = () => axios.get(`/v1/tasks`);
const getTask = (id) => axios.get(`/v1/tasks/${id}`);
const postTask = (model) => axios.post(`/v1/tasks`, model);
const putTask = (model) => axios.put(`/v1/tasks/${model.id}`, model);


export {
    getTasks,
    getTask,
    postTask,
    putTask,
};
