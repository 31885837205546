/**
 * @name: 跟进记录
 * @description:
 * @author: Wood
 * @date: 2021/5/6
 * @update:
 */
import React, {useState,useRef} from 'react';
import {Button, Space, Tag} from 'antd';
import DataTable from '../../../components/data/data-table2';
import FollowUpAddModal from './followup-add-modal';
import FollowUpEditModal from './followup-edit-modal';
import {useTable} from 'table-render';
import {PlusOutlined} from '@ant-design/icons';

const WXAccessToken = ({user_id}) => {
    const [visible, setVisible] = useState(false);
    const [editdata, setEditData] = useState(null);
    const {refresh} = useTable();


    const table = useRef();
    const config = {
        table: table,
        // title: "跟进记录",
        tableName: "followup",
        pageQuery: {user_id},
        pagination: false,
        size: "small",
        toolbar: false,
        columns: [{
            title: '添加时间',
            dataIndex: 'createdAt',
            valueType: 'dateTime',
        }, {
            title: '跟进内容',
            dataIndex: 'content',
            render: (content, record) => {
                let files_config = [];
                let files_config_str = record.files_config;
                if (files_config_str) {
                    files_config = JSON.parse(files_config_str)
                }
                return (
                    <div>
                        <div>{content}</div>
                        <div>
                            {files_config.map(item => (
                                <Tag>
                                    <a href={item.url} target="_blank">{item.name}</a>
                                </Tag>
                            ))}
                        </div>
                    </div>
                )
            }
        }, {
            title: '跟进教师',
            dataIndex: 'teacher_id',
        }, {
            title: '更新时间',
            dataIndex: 'updatedAt',
            valueType: 'dateTime',
        }, {
            title: '操作',
            fixed: 'right',
            width: 60,
            render: (record) => (<Space>
                <a target="_blank" key="1">
                    <div
                        onClick={() => {
                            setEditData(record);
                        }}
                    >
                        修改
                    </div>
                </a>
            </Space>)
        }],
        // toolbar: [
        //     <Button
        //         type="primary"
        //         icon={<PlusOutlined/>}
        //         onClick={() => setVisible(true)}
        //     >添加</Button>
        // ]
    };

    // console.log(match.params.type_id);
    return (
        <div>
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => setVisible(true)}
            >添加</Button>
            <DataTable {...config} />

            <FollowUpAddModal
                visible={visible}
                onOk={() => {
                    setVisible(false);
                    refresh()
                }}
                onCancel={() => {
                    setVisible(false)
                }}
                user_id={user_id}
            />
            {editdata &&
            <FollowUpEditModal
                visible={!!editdata}
                data={editdata}
                onOk={() => {
                    setEditData(null);
                    refresh()
                }}
                onCancel={() => {
                    setEditData(null);
                }}
            />
            }
        </div>
    )
};


export default WXAccessToken;


