/**
 * @Description: 选项组件，转换数字0，1，2，3，4，5为A,B,C,D,E,F，最大值为26
 * @author Wood
 * @date 2019/3/6
*/

import PropTypes from 'prop-types';
import React from 'react';

const ChoiceTag = (props) => (
    <React.Fragment>
        {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[props.index]}
    </React.Fragment>
);

ChoiceTag.propTypes = {
    index: PropTypes.number.isRequired,
};
export default ChoiceTag;