import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Input, Form} from 'antd';
import FormOptions from './form_options';
import MdEditor from '../../../components/md-editor/md-editor';


const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};


const FormDanXuan = ({value, onChange}) => {
    const [form] = Form.useForm();
    const [config, setConfig] = useState({
        title: "",
        content:"",
    });

    useEffect(() => {
        if(value){
            let dt = JSON.parse(value);
            setConfig(dt);
            form.setFieldsValue(dt)
        }else{
            form.setFieldsValue({
                title: "",
                content:"",
            })
        }

    }, [value]);

    const handleChange = (changedValues, allValues) => {
        // console.log(allValues);
        onChange && onChange(JSON.stringify(allValues))
    };

    return (
        <Form
            form={form}
            onValuesChange={handleChange}
        >
            <Form.Item
                label="标题"
                name="title"
                rules={[{
                    required: true,
                    message: '请输入标题'
                }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="描述"
                name="content"
                rules={[{
                    required: true,
                    message: '请输入描述'
                }]}
            >
                <MdEditor/>
            </Form.Item>
            <Form.Item
                label="评分标准"
                name="pfbz"
            >
                <MdEditor/>
            </Form.Item>

        </Form>
    )
};


FormDanXuan.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default FormDanXuan;