/**
 * @name: 班级列表
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Form, Icon as LegacyIcon} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {CloudDownloadOutlined, EditOutlined,DeleteOutlined} from '@ant-design/icons';
import {
    Button,
    Modal,
    Input,
    Alert,
    Table,
    Divider,
    Tabs,
    Tag,
    Card,
    List,
    Row,
    Col,
    Avatar,
    Tooltip,
    Popconfirm,
    Pagination,
    message,
    Radio,
} from 'antd';
import PageContent from '../../../components/page-content/page-content';
// import CoursesEditModal from './classes-edit';
import {deleteScratch, getScratchs} from "../../../lib/api_scratchs";
// import CoursesAddModal from "./courses_add_modal";
// import CoursesEditModal from "./courses_edit_modal";
import styles from './scratchs.module.css';
import moment from 'moment';
import defaultBg from '../../../assets/images/classes_item_bg.jpg';
import PublishModal from './publish-modal';
import {unPublicScratch} from "../../../lib/api_scratchs";

const IconText = ({type, text}) => (
    <span>
    <LegacyIcon type={type} style={{marginRight: 8}}/>
        {text}
  </span>
);

const CoursesType = ({type}) => (
    <span>
        {type === 0 && "Scratch"}
        {type === 1 && "Python"}
        {type === 2 && "C++"}
        {type === 3 && "视频"}
    </span>
);

class Scratchs extends Component {
    state = {
        list: [],
        page: 1,
        pageSize: 20,
        total: 0,
        filter: {
            is_public: -1,
            key: null
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    fetchData = () => {
        let {page, pageSize, filter} = this.state;
        getScratchs(page, pageSize, filter)
            .then((result) => {
                if (result.code === 0) {
                    // this.props.history.createPath()
                    this.setState({
                        page: result.data.page,
                        pageSize: result.data.pageSize,
                        total: result.data.total,
                        list: result.data.records
                    })
                } else {

                }
            })
    };

    handlePageChange = (page, pageSize) => {
        console.log(page, pageSize);
        console.log(this.props.history)
        this.props.history.push(`/teaching/scratchs?page=${page}&pageSize=${pageSize}`)
        this.setState({
            page: page,
            pageSize: parseInt(pageSize)
        }, () => {
            this.fetchData();
        })
    };

    handleShowSizeChange = (current, size) => {
        console.log(current, size)
        this.props.history.push(`/teaching/scratchs?page=${current}&pageSize=${size}`)

        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.fetchData();
        })

    };

    handleunpublic = (id) => {
        unPublicScratch(id)
            .then(result => {
                if (result.code === 0) {
                    this.fetchData();
                    message.success("已取消发布");
                } else {
                    message.error(result.message);
                }
            })
    };
    handledelete = (id) => {
        deleteScratch(id)
            .then(result => {
                if (result.code === 0) {
                    this.fetchData();
                    message.success("已删除");
                } else {
                    message.error(result.message);
                }
            })
    };

    handlePublicChange = (e) => {
        let filter = {
            ...filter,
            is_public: e.target.value
        };
        this.setState({
            filter: filter
        }, () => {
            this.fetchData();
        })
    };

    onSearch = (e) => {
        console.log(e);
        let filter = {
            ...filter,
            key: e
        };
        this.setState({
            filter: filter
        }, () => {
            this.fetchData();
        })
    }

    // handledelete = (id) => {
    //     unPublicScratch(id)
    //         .then(result => {
    //             if (result.code === 0) {
    //                 this.loadData();
    //                 message.success("删除成功");
    //             } else {
    //                 message.error(result.message);
    //             }
    //         })
    // };

    render() {
        let {list, filter} = this.state;


        return (
            <PageContent title={'Scratch作品管理'} routes={[
                {
                    path: 'admin',
                    breadcrumbName: "首页",
                },
                {
                    path: 'courses',
                    breadcrumbName: '我的课程',
                },
            ]}>
                <Card bordered={false}>
                    <Input.Search
                        onSearch={this.onSearch}
                        style={{
                            width: 200,
                        }}
                    />
                    <Row type="flex" justify="space-between">
                        <Col>
                            <Radio.Group defaultValue={-1} value={filter.is_public} buttonStyle="solid"
                                         onChange={this.handlePublicChange}>
                                <Radio.Button value={-1}>全部</Radio.Button>
                                <Radio.Button value={1}>已发布</Radio.Button>
                                <Radio.Button value={0}>未发布</Radio.Button>
                            </Radio.Group>
                        </Col>

                        {/*<Button type='primary' icon='plus' onClick={() => this.setState({addModal: true})}*/}
                        {/*style={{marginBottom: 10}}>添加课程</Button>*/}
                        <Col>
                            <Pagination
                                showSizeChanger
                                showQuickJumper
                                // showTotal
                                onChange={this.handlePageChange}
                                onShowSizeChange={this.handleShowSizeChange}
                                defaultCurrent={1}
                                defaultPageSize={20}
                                current={this.state.page}
                                pageSize={this.state.pageSize}
                                total={this.state.total}
                            />
                        </Col>
                    </Row>
                    <Row type="flex">

                        {list.map(item => (
                            <Card
                                className={styles.card}
                                cover={
                                    <a href={`https://xmybc.com/scratch/project/${item.id}`}>
                                        <img
                                            alt="example"
                                            src={item.thumbnail_url ? item.thumbnail_url : defaultBg}
                                        />
                                        {/*<img src={item.QRCode} className={styles.qrcode}/>*/}
                                    </a>
                                }
                                actions={
                                    item.is_public === 1 ?
                                        [
                                            <Tooltip title="修改发布信息">
                                                <EditOutlined key="edit"
                                                              onClick={() => this.setState({publishItem: item})}/>
                                            </Tooltip>,
                                            <Tooltip title="取消发布">

                                                {/*<Popconfirm*/}
                                                {/*title={`是否将作品发布到"作品酷"，使其他人能够看到?`}*/}
                                                {/*onConfirm={() => this.handlepublic(item.id)}*/}
                                                {/*okText="发布"*/}
                                                {/*cancelText="取消"*/}
                                                {/*icon={<Icon type="question-circle" />}*/}
                                                {/*>*/}
                                                {/*<Icon type="cloud-upload" />*/}
                                                {/*</Popconfirm>*/}
                                                <Popconfirm
                                                    title="是否取消发布?"
                                                    onConfirm={() => this.handleunpublic(item.id)}
                                                    okText="取消发布"
                                                    cancelText="点错了"
                                                >
                                                    <CloudDownloadOutlined/>
                                                </Popconfirm>
                                            </Tooltip>,
                                            <Popconfirm
                                                title="是否删除?"
                                                onConfirm={() => this.handledelete(item.id)}
                                                okText="删除"
                                                cancelText="点错了"
                                            >
                                                <DeleteOutlined />
                                            </Popconfirm>
                                        ]
                                        :
                                        [
                                            <Tooltip title="修改发布信息">
                                                <EditOutlined key="edit"
                                                              onClick={() => this.setState({publishItem: item})}/>
                                            </Tooltip>,

                                            <Popconfirm
                                                title="是否删除?"
                                                onConfirm={() => this.handledelete(item.id)}
                                                okText="删除"
                                                cancelText="点错了"
                                            >
                                                <DeleteOutlined />
                                            </Popconfirm>
                                        ]}
                                bodyStyle={{
                                    position: "relative",
                                }}
                            >
                                <Card.Meta
                                    // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                                    title={item.name}
                                    description={<div>
                                        <div>{item.nick_name}</div>
                                        <div>{moment(item.update_on).fromNow()}</div>
                                    </div>}/>
                                {/*<div className={styles.qrcodebtn} onMouseEnter={() => this.showQRCode(item)}*/}
                                {/*onMouseLeave={() => this.hideQRCode(item)}>*/}
                                {/*<Icon type="qrcode"/>*/}
                                {/*</div>*/}
                            </Card>

                        ))}
                    </Row>
                </Card>
                {/*<CoursesAddModal*/}
                {/*visible={this.state.addModal}*/}
                {/*onOk={() => {*/}
                {/*this.setState({addModal: false});*/}
                {/*this.loadClasses();*/}
                {/*}}*/}
                {/*onCancel={() => {*/}
                {/*this.setState({addModal: false})*/}
                {/*}}*/}
                {/*/>*/}
                {/*<CoursesEditModal*/}
                {/*data={this.state.editRecord}*/}
                {/*onOk={() => {*/}
                {/*this.setState({editRecord: null});*/}
                {/*this.loadClasses();*/}
                {/*}}*/}
                {/*onCancel={() => {*/}
                {/*this.setState({editRecord: null})*/}
                {/*}}*/}
                {/*/>*/}
                <PublishModal
                    data={this.state.publishItem}
                    onCancel={() => this.setState({publishItem: null})}
                    onOk={() => {
                        this.fetchData();
                        this.setState({publishItem: null})
                    }}
                />
            </PageContent>
        );
    }
}

const mapStateToProps = state => ({
    school: state.school,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Scratchs));
