/**
 * @name: 课程列表
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {PlusOutlined, EditOutlined} from '@ant-design/icons';
import {Button, Divider, Card, Row, Col, Space} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getProjects} from "../../../lib/api_courses";
import DictName from '../../../components/dict/dict-name';
import CourseEditForm from './course_edit_form';
import EasyFormModal from '../../../components/easy_form_modal';
import {putCourses, postCourses} from "../../../lib/api_courses";


const Courses = () => {
    const [groups, setgroups] = useState([]);
    const [editRecord, setEditRecord] = useState(null);
    const [addModal, setAddModal] = useState(false);

    const fetchData = () => {
        getProjects()
            .then((result) => {
                if (result.code === 0) {
                    let groups = [];
                    for (let i in result.data) {
                        let item = result.data[i];
                        let group = groups.find(g => g.type === item.type);
                        if (!group) {
                            group = {
                                type: item.type,
                                list: []
                            };
                            groups.push(group)
                        }
                        group.list.push(item);
                    }
                    setgroups(groups);
                }
            })
    };

    useEffect(fetchData, []);

    return (
        <PageContent title={'课程管理'} routes={[
            {
                path: 'admin',
                breadcrumbName: "首页",
            },
            {
                path: 'courses',
                breadcrumbName: '我的课程',
            },
        ]}>
            <div>
                <Space direction="vertical">
                    <Button type='primary' icon={<PlusOutlined/>}
                            onClick={() => setAddModal({
                                keshi: 0,
                                duration: 0,
                            })}
                            style={{marginBottom: 10}}>添加课程</Button>
                    {groups.map(group => (
                        <Card size="small" bordered={false}
                              title={<span style={{fontSize: 18, fontWeight: "bold"}}><DictName type_value="course_type"
                                                                                                value={group.type}/></span>}>

                            <Row gutter={[16, 16]} style={{alignItems: "stretch"}}>
                                {group.list.map(item => (
                                    <Col flex="240px">
                                        <Card
                                            bodyStyle={{padding: 10}}
                                            hoverable
                                            cover={<Link to={`/teaching/courses/${item.id}`}>
                                                <div style={{
                                                    width: 224,
                                                    height: 149,
                                                    background: `url(${item.image_url})`,
                                                    backgroundSize: "cover"
                                                }}/>
                                            </Link>}
                                            actions={[
                                                <EditOutlined key="edit" onClick={() => setEditRecord(item)}/>,
                                            ]}
                                        >
                                            <div style={{
                                                fontWeight: "bold",
                                                fontSize: 16,
                                                marginBottom: 5,
                                                height: 25,
                                                overflow: "hidden"
                                            }}>
                                                {item.name}
                                            </div>
                                            <div>
                                                <Space size={1} split={<Divider type={"vertical"}/>}>
                                                    <span>{item.keshi}课时</span>
                                                    <span>{item.duration}分钟</span>
                                                </Space>
                                            </div>
                                            <div style={{color: "#838383", height: 66, overflow: "auto"}}>
                                                {item.description}
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    ))}
                </Space>
            </div>
            {addModal && <EasyFormModal
                data={addModal}
                title={"添加课程"}
                onOk={() => {
                    setAddModal(false);
                    fetchData();
                }}
                onCancel={() => {
                    setAddModal(false);
                }}
                formComponent={CourseEditForm}
                restFunc={postCourses}
            />}
            {editRecord && <EasyFormModal
                data={editRecord}
                title={"修改课程"}
                onOk={() => {
                    setEditRecord(null);
                    fetchData();
                }}
                onCancel={() => {
                    setEditRecord(null);
                }}
                formComponent={CourseEditForm}
                restFunc={putCourses}
            />}

        </PageContent>
    )
};

export default Courses;
