import PropTypes from 'prop-types';
import React from 'react';
import {Radio} from 'antd';
import {connect} from 'react-redux';

const DictRadioGroup = ({value, onChange, type_value, dict_types}) => {
    const handleChange = (e) => {
        console.log(e.target.value)
        onChange && onChange(e.target.value)
    };

    let options = [];
    const dict_type = dict_types.find(_ => _.value === type_value);
    if (dict_type) {
        options = dict_type.sys_dicts.sort((a, b) => {
            return a.order_index - b.order_index
        }).map(dict => {
            return {
                label: dict.key,
                value: dict.value,
            }
        })
    }

    // console.log(value)

    return (
        <Radio.Group
            value={value !== null && value !== undefined ? value.toString() : ""}
            onChange={handleChange}
            options={options}
        />
    );
};

DictRadioGroup.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    type_value: PropTypes.string,
};


const mapStateToProps = state => ({
    dict_types: state.account.dict_types
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DictRadioGroup);


