import React from "react";
import {QuestionCircleOutlined} from '@ant-design/icons';
import {Modal} from 'antd';

/**
 * 比赛赛制 ACM，IOI，OI
 * @param type
 * @returns {string}
 * @constructor
 */
const ContestType = ({type}) => {

    if (type === 0) return "ACM";
    if (type === 1) return <>IOI</>;
    if (type === 2) return "OI";
    return "其他";
}

export default React.memo(ContestType);