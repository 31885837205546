import React, {useState, useEffect} from 'react';
import {DatePicker, Form, Input, message, Modal, Radio} from "antd";
import {getContest, postContest, postContestProblem, putContest} from "../../../lib/api_contest";
import moment from "moment";
import {Colorpicker} from "antd-colorpicker";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};

const ContestProblemAddModal = ({id, onOk, onCancel}) => {
    const [form] = Form.useForm();


    const handleFinish = () => {
        form.validateFields()
            .then((values) => {
                console.log('Received values of form: ', values);
                postContestProblem(id, values)
                    .then(res => {
                        if (res.code === 0) {
                            message.success("保存成功!");
                            handleOk();
                        } else {
                            message.error(res.message);
                        }
                    })
            })
    };

    const handleOk = () => {
        onOk && onOk();
    }

    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title={"添加题目"}
            visible={true}
            onCancel={handleCancel}
            onOk={handleFinish}
            width={700}
        >
            <Form
                {...formItemLayout}
                form={form}
                onFinish={handleFinish}
                initialValues={{color: "#ffffff"}}
            >
                <Form.Item name="pid" label="题目id" rules={[{
                    required: true,
                    message: '请输入${label}！',
                },]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="display_id" label="展示的id" rules={[{
                    required: true,
                    message: '请输入${label}！',
                },]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="display_title" label="展示标题" rules={[{
                    required: true,
                    message: '请输入${label}！',
                },]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="color" label="气球颜色">
                    <Colorpicker popup onColorResult={(color) => color.hex}/>
                    {/*<Input/>*/}
                </Form.Item>
            </Form>
        </Modal>)
}

export default ContestProblemAddModal;