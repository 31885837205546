import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Card, Input, Modal, Space, Table, Typography} from "antd";
import {getContestRecords, getContests} from "../../../lib/api_contest";
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";
import moment from "moment";
import Result from "./result";
import SolutionRate from "./solution_rate";

const ContestRecordList = ({id}) => {
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [state, setState] = useState({
        page: 1, pageSize: 10, key: "",
    });

    const fetchData = () => {
        getContestRecords(id, state)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data.records);
                    setTotal(res.data.total);
                }
            })
    }

    useEffect(fetchData, [state]);

    const handlerPageChange = (page, pageSize) => {
        setState({
            page, pageSize
        })
    };


    return (
        <Table dataSource={list}
               rowKey={"id"}
               pagination={{
                   current: parseInt(state.page),
                   pageSize: parseInt(state.pageSize),
                   total: total,
                   position: ["bottomCenter"],
                   onChange: handlerPageChange
               }}
               size="small"
        >
            <Table.Column
                title="#"
                dataIndex="id"
                key="#"
                width={60}
            />
            <Table.Column
                title="提交人"
                dataIndex="user_id"
                key="提交人"
                render={(pid, record) => (
                    <Link to={`/affairs/students/${record.user.id}`}>
                        {record.user.nick_name}
                    </Link>
                )}
            />
            <Table.Column
                title="题目"
                dataIndex="pid"
                key="题目"
                render={(pid, record) => (
                    <Link to={`/teaching/problems/${record.problem.pid}`}>
                        {record.problem.display_id} {record.problem.display_title}
                    </Link>
                )}
            />
            <Table.Column
                title="状态"
                dataIndex="result"
                key="状态"
                render={(result, record) =>
                    // <Link to={`/cpp/solutions/${record.id}`}>
                    <Result result={result}/>
                    // </Link>
                }
            />
            <Table.Column
                title="分数"
                dataIndex="pass_rate"
                key="分数"
                align="right"
                render={(pass_rate) => <SolutionRate value={pass_rate}/>}
            />
            <Table.Column
                title="提交时间"
                dataIndex="in_date"
                key="提交时间"
                render={(start_time) => moment(start_time).format("YYYY-MM-DD HH:mm:ss")}
            />
            <Table.Column
                title="运行时间"
                dataIndex="time"
                key="运行时间"
                align="right"
                render={time => `${time}ms`}
            />
            <Table.Column
                title="运行内存"
                dataIndex="memory"
                key="运行内存"
                align="right"
                render={memory => `${memory}KiB`}
            />
            <Table.Column
                title="代码长度"
                dataIndex="code_length"
                key="代码长度"
                align="right"
                render={code_length => `${code_length}B`}
            />

            <Table.Column
                title="操作"
                key="action"
                width={100}
                align="right"
                render={(text, record) => (
                    <Space>
                        <Typography.Link onClick={() => {
                            Modal.info({
                                title: '代码',
                                content: (
                                    <Input.TextArea value={record.code} autoSize={{maxRows:10}} >
                                        {record.code}
                                    </Input.TextArea>
                                ),
                                maskClosable: true,
                                width: 800,
                                onOk() {
                                },
                            });
                        }}>查看代码</Typography.Link>
                    </Space>
                )}
            />
        </Table>
    );
}

export default ContestRecordList;