/**
 * @name: 默认页面布局
 * @description:
 * @author: Wood
 * @date: 2019/4/17
 * @update: 2019/4/24
 */
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import { Layout, PageHeader, Breadcrumb } from 'antd';
import styles from './page-content.module.css';
const routes = [
    {
        path: '/',
        breadcrumbName: "首页",
    },
    {
        path: 'first',
        breadcrumbName: 'Second-level Menu',
    },
    {
        path: 'second',
        breadcrumbName: 'Third-level Menu',
    },
];
function itemRender(route, params, routes, paths) {
    console.log(paths.join('/'));
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
    );
}
const PageContent = ({children, wrapperClassName, title,subTitle,routes, ...restProps}) => (
    <div className={wrapperClassName}>
        <PageHeader
            style={{
                background: "#fff",
                height: 56,
                lineHeight: 56,
                borderBottom: "1px solid #e5e5e5",
                padding: "10px 100px 10px 25px",
                // borderBottom: "1px solid #e8e8e8",
                // position: "absolute",
                // top: 0,
                // zIndex: 100,
            }}
            title={title}
            // breadcrumb={{
            //      routes,
            //      itemRender : itemRender
            //  }}
            subTitle={subTitle}
        />
        {children ? <Layout.Content className={styles.content}>{children}</Layout.Content> : null}
    </div>
);

PageContent.propTypes = {
    title: PropTypes.string,
    wrapperClassName: PropTypes.string,
};


export default PageContent;