import PropTypes from 'prop-types';
// import bindAll from 'lodash.bindall';
import React from 'react';
// import {Controlled as CodeMirror} from 'react-codemirror2'
// import 'codemirror/mode/markdown/markdown'
// import 'codemirror/mode/python/python'
// import 'codemirror/lib/codemirror.css';
import SimpleMDE from 'react-simplemde-editor';
import "easymde/dist/easymde.min.css";
// import "simplemde/src/css/simplemde.css";
import SparkMD5 from 'spark-md5';

// import {getSTSTestcases} from "../../services/api";
// import {getSTSTestcases} from "../../rest/api_sts";
import AliOSS from 'ali-oss';
// import {Input , Modal,Header } from 'semantic-ui-react'
import './md-editor.module.css';
import {Modal, Input, Upload, Button} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {getSTSTestcases} from "../../lib/api_sts";

class mdEditor extends React.Component {
    state = {
        imageModal: false,
        imageUrl: 'http://',
        value: this.props.value || ""
    };
    // onBeforeChange = (editor, data, value) => {
    //   // this.setState({value: value});
    //   this.props.onChange && this.props.onChange(value + '');
    // };

    handleChange = value => {
        // this.setState({value: value});
        this.props.onChange && this.props.onChange(value);
    };

    handleImageInsert = (val) => {
        this.setState({imageModal: false})
        console.log(this.refs.mde)
        this.refs.mde.simpleMde.codemirror.doc.replaceSelection(`![](${val})`);
        setTimeout(() => {
            this.refs.mde.simpleMde.codemirror.focus()
        }, 100)
    };

    getmd5 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                const spark = new SparkMD5.ArrayBuffer();
                spark.append(reader.result)
                let md5 = spark.end();
                resolve(md5)
            };
            reader.onerror = (err) => {
                reject(err)
            };
            reader.readAsArrayBuffer(file);
        });
    };

    deletespan=()=>{
        let value = this.refs.mde.simpleMde.codemirror.doc.getValue();
        let cleanedHtml = value.replace(/<[^>]+>/g, '');
        this.refs.mde.simpleMde.codemirror.doc.setValue(cleanedHtml);
    }
    replacelt=()=>{
        let value = this.refs.mde.simpleMde.codemirror.doc.getValue();
        let cleanedHtml = value.replace(/&lt;/g, '<');
        this.refs.mde.simpleMde.codemirror.doc.setValue(cleanedHtml);
    }
    replacegt=()=>{
        let value = this.refs.mde.simpleMde.codemirror.doc.getValue();
        let cleanedHtml = value.replace(/&gt;/g, '>');
        this.refs.mde.simpleMde.codemirror.doc.setValue(cleanedHtml);
    }
    replacenbsp=()=>{
        let value = this.refs.mde.simpleMde.codemirror.doc.getValue();
        let cleanedHtml = value.replace(/&nbsp;/g, ' ');
        this.refs.mde.simpleMde.codemirror.doc.setValue(cleanedHtml);
    }

    replacebr=()=>{
        let value = this.refs.mde.simpleMde.codemirror.doc.getValue();
        let cleanedHtml = value.replace(/<br \/>/g, '  ');
        this.refs.mde.simpleMde.codemirror.doc.setValue(cleanedHtml);
    }
    replacen=()=>{
        let value = this.refs.mde.simpleMde.codemirror.doc.getValue();
        let cleanedHtml = value.replace(/\n[\t| ]+/g, '\n');
        cleanedHtml = cleanedHtml.replace(/^\s*/g, '');
        cleanedHtml = cleanedHtml.replace(/\n\n\n/g, '\n\n');
        this.refs.mde.simpleMde.codemirror.doc.setValue(cleanedHtml);
    }

    replaceall=()=>{
        this.replacebr();
        this.deletespan();
        this.replacen();
        this.replacelt();
        this.replacegt();
        this.replacenbsp();
    }

    handleImageUpload = async (info) => {
        console.log(info);
        let md5 = await this.getmd5(info.file);
        const suffix = info.file.name.slice(info.file.name.lastIndexOf('.'));
        console.log("suffix", suffix)
        let alikey = "mde/" + md5 + suffix;
        let res = await getSTSTestcases(alikey);
        const client = new AliOSS({
            region: 'oss-cn-hangzhou',
            // 云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
            accessKeyId: res.AccessKeyId,
            accessKeySecret: res.AccessKeySecret,
            stsToken: res.SecurityToken,
            bucket: 'yzsteam'
        });
// info.onProgress();
        client.multipartUpload(res.key, info.file)
            .then(result => {
                // resolve(result);
                info.onSuccess({
                    uid: alikey,
                    name: info.file.name,
                    status: 'done',
                    // response: 'Server Error 500', // custom error message to show
                    url: `https://yzsteam.oss-cn-hangzhou.aliyuncs.com/${alikey}`,
                    type: info.file.type,
                    size: info.file.size,
                });
                console.log(result, info)
                this.setState({
                    imageUrl: `https://yzsteam.oss-cn-hangzhou.aliyuncs.com/${alikey}`
                })
                // console.log(`${url}${alikey}`)
                // this.handleImageInsert(`${url}${alikey}`);
            })
            .catch(error => {
                // reject(error);
                info.onError(error);
                console.log(error);
                // this.props.ErrorStatus(error);
            });
    }

    render() {
        return (
            <div style={{width: '100%', lineHeight: 1.5}}>
                {/*<SimpleMDE*/}
                {/*value={this.state.value}*/}
                {/*onChange={this.handleChange}*/}
                {/*/>*/}
                <SimpleMDE
                    ref="mde"
                    value={this.props.value || ""}
                    options={{
                        autoDownloadFontAwesome: false,
                        spellChecker: false,
                        status: false,
                        toolbar: this.props.toolbar || [
                            "bold", "italic", "strikethrough", "heading",
                            "|", "code", "quote", "unordered-list", "ordered-list", "clean-block",
                            "|", "link", {
                                name: "image",
                                action: (editor) => {
                                    // Add your own code
                                    this.setState({imageModal: !this.state.imageModal});

                                    console.log(this.refs.mde);
                                },
                                className: "fa fa-image",
                                title: "插入图片",
                            },
                            "|", "table", "horizontal-rule", "preview", "side-by-side", "fullscreen",
                            // "|", "undo", "redo",
                            "|", "guide",

                        ],
                        height: "100px",
                        // maxHeight:"100px"
                        // etc.
                    }}
                    onChange={this.handleChange}/>
                <Modal
                    title="插入图片"
                    visible={this.state.imageModal}
                    onCancel={() => this.setState({imageModal: false})}
                    closable={true}
                    onOk={() => {
                        this.handleImageInsert(this.state.imageUrl);

                    }}
                    // closeIcon
                >
                    {/*<Header icon='browser' content='插入图片' />*/}
                    {/*<Modal.Content>*/}
                    <label>输入图片url</label>
                    <Input value={this.state.imageUrl} onChange={(e) => this.setState({imageUrl: e.target.value})}/>
                    {/*<input type='file' onChange={this.handleImageUpload}/>*/}
                    {/*<Input as={'input'} type={'file'}  />*/}
                    {/*</Modal.Content>*/}


                    <Upload customRequest={this.handleImageUpload} showUploadList={false}>
                        <Button icon={<UploadOutlined/>}> Upload
                        </Button>
                    </Upload>
                </Modal>
                <a onClick={this.deletespan}>删除html标签</a>
                <a onClick={this.replacelt} style={{marginLeft:10}}>替换lt;标签</a>
                <a onClick={this.replacegt} style={{marginLeft:10}}>替换gt;标签</a>
                <a onClick={this.replacenbsp} style={{marginLeft:10}}>替换nbsp;标签</a>
                <a onClick={this.replacebr} style={{marginLeft:10}}>替换br标签</a>
                <a onClick={this.replacen} style={{marginLeft:10}}>缩进</a>
                <a onClick={this.replaceall} style={{marginLeft:10}}>一键处理</a>
            </div>
            // {/*<CodeMirror ref="editor"*/}
            //             {/*value={this.props.value}*/}
            //             {/*onBeforeChange={this.onBeforeChange}*/}
            //             {/*options={{*/}
            //               {/*lineNumbers: true,*/}
            //               {/*readOnly: false,*/}
            //               {/*mode: this.props.language || 'markdown',*/}
            //               {/*lineWrapping: true*/}
            //             {/*}}*/}
            //             {/*autoFocus={true}*/}
            //             {/*className={'flex1'}/>*/}
        )
    }
}

mdEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    language: PropTypes.string,
    toolbar: PropTypes.array
};

export default mdEditor;


//
// class mdEditor extends React.Component {
//     constructor(props) {
//         super(props);
//         bindAll(this, [
//             'onBeforeChange',
//         ]);
//         // this.state = {
//         //     value: this.props.value
//         // }
//     }
//
//     onBeforeChange(editor, data, value) {
//         // this.setState({value: value});
//         this.props.onChange && this.props.onChange(value + '');
//     }
//
//     render() {
//         return (
//             <CodeMirror ref="editor"
//                         value={this.props.value}
//                         onBeforeChange={this.onBeforeChange}
//                         options={{
//                             lineNumbers: true,
//                             readOnly: false,
//                             mode: this.props.language || 'markdown',
//                             lineWrapping: true
//                         }}
//                         autoFocus={true} className={'flex1'}/>
//         )
//     }
// }
//
//
// mdEditor.propTypes = {
//     value: PropTypes.string,
//     onChange: PropTypes.func,
//     language: PropTypes.string
// };
//
// export default mdEditor;