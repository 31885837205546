
import axios from './rest-axios';

const getScratchs = (page, pageSize, filter) => axios.get(`/v1/scratchs/`, {page, pageSize,filter});
const putScratch = (model) => axios.put(`/v1/scratchs/${model.id}`, model);
const unPublicScratch = (id) => axios.post(`/v1/scratchs/${id}/unpublic`);
const deleteScratch = (id) => axios.delete(`/v1/scratchs/${id}`);
const postClone = (id, courses_lessons_id) => axios.post(`/v1/scratchs/${id}/clone`, {courses_lessons_id});
const getByIds = (ids) => axios.get(`/v1/scratchs/ids`, {ids});

export {
    getScratchs,
    putScratch,
    unPublicScratch,
    deleteScratch,
    postClone,
    getByIds
};
