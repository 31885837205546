
import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';

class Key2Text extends React.Component {
    state = {
        ali_key: this.props.ali_key,
        str: ''
    };

    componentDidMount = () => {
        this.downloadText();
    };

    downloadText = () => {
        axios({
            method: 'GET',
            url: "http://yunheng-hacker.oss-cn-hangzhou.aliyuncs.com/" + this.state.ali_key,
            // headers: {authorization: authorization},
            // json: false,
            responseType: 'arraybuffer', // 默认值是json
        })
            .then(res2 => {
                console.log(res2);
                var buffer = new Buffer(res2, 'binary');
                var textdata = buffer.toString(); // for string

                this.setState({str: textdata});
            })
            .catch(error => {
                console.log(error.response.data)
            });
    }
    render = () => {
        return this.state.str;
    }
}

Key2Text.propTypes = {
    ali_key: PropTypes.string.isRequired,
};

export default Key2Text;