import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Checkbox} from 'antd';
import {getList} from "../../lib/api_data";

const FormDataCheck = ({value, onChange, table, labelkey, valuekey}) => {

    const [options, setOptions] = useState([]);
    const [rvalue, setRValue] = useState([]);


    // 获取数据
    useEffect(() => {
        if (value) {
            setRValue(value.split(','))
        }
        // console.log(value)
    }, [value]);

    useEffect(() => {
        if (value) {
            setRValue(value.split(','))
        }
        getList(table)
            .then(res => {
                if (res.code === 0) {
                    setOptions(res.data.map(_ => {
                        return {
                            label: _[labelkey].toString(),
                            value: _[valuekey].toString()
                        }
                    }))
                }
            })
    }, []);


    const handleChange = (e) => {
        setRValue(e);
        onChange && onChange(e.join(','))
    };

    return (
        <Checkbox.Group
            options={options}
            value={rvalue}
            onChange={handleChange}
        />
    );
};

FormDataCheck.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    table: PropTypes.string,
    labelkey: PropTypes.string,
    valuekey: PropTypes.string,
};

export default FormDataCheck;
