import React, {useState, useRef} from 'react';
import {Button, Space, Tag, Divider} from 'antd';
import DataTable from '../../../components/data/data-table2';
// import {useTable} from 'table-render';
import {PlusOutlined} from '@ant-design/icons';
import KeShiEditModal from './keshi-edit-modal';
import KeShiAddModal from './keshi-add-modal';
import BuLuModal from './bulu-modal';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const KeShiList = ({user_id, account, card_id = null}) => {
    const [visible, setVisible] = useState(false);
    const [editdata, setEditData] = useState(null);
    const [buludata, setBuLuData] = useState(null);
    // const {refresh} = useTable();

    let columns = [{
        title: '时间',
        dataIndex: 'create_on',
        valueType: 'dateTime',
    }, {
        title: '说明',
        dataIndex: 'remark',
    }, {
        title: '课时',
        dataIndex: 'keshi',
        valueType: 'number',
    }, {
        title: '赠送',
        dataIndex: 'is_gift',
        enum: {
            0: "",
            1: "是"
        }
    }, {
        title: '金额',
        dataIndex: 'amount',
        align: 'right',
        valueType: 'money',
    }, {
        title: '课程',
        dataIndex: 'card_id',
    }, {
        title: '操作',
        fixed: 'right',
        width: 100,
        render: (record) => (
            <Space split={<Divider type={"vertical"}/>}>
                <a onClick={() => setEditData(record)}>修改</a>
                {(record.keshi > 0 && record.card_id === 0) &&
                <a onClick={() => setBuLuData(record)}>补录</a>
                }
            </Space>
        )
    }];

    if(account.login_name === "admin"){
        columns = columns.filter(m=>m.title !== "金额");
    }


    const table = useRef();
    const config = {
        table: table,
        title: "课时记录",
        tableName: "keshi",
        pageQuery: {user_id, card_id},
        pagination: false,
        size: "small",
        sorter: {field: "create_on", order: "descend"},
        columns: columns,
        toolbar: [
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => setVisible(true)}
            >添加</Button>
        ]
    };

    // console.log(match.params.type_id);
    return (
        <div>
            <DataTable {...config} />

            {visible &&
            <KeShiAddModal
                visible={visible}
                onOk={() => {
                    setVisible(false);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setVisible(false)
                }}
                user_id={user_id}
                card_id={card_id}
            />
            }

            {editdata &&
            <KeShiEditModal
                visible={!!editdata}
                data={editdata}
                onOk={() => {
                    setEditData(null);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setEditData(null);
                }}
            />
            }
            {buludata && <BuLuModal
                visible={!!buludata}
                data={buludata}
                onOk={() => {
                    setBuLuData(null);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setBuLuData(null);
                }}
            />}
        </div>
    )
};





const mapStateToProps = state => ({
    account: state.account,
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(KeShiList));

