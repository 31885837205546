import PropTypes from 'prop-types';
import React from 'react';
import styles from './markdown-html.module.css';

let hljs = require('highlight.js'); // https://highlightjs.org/
let md = require('markdown-it')({
    html: true,
    highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
            try {
                return hljs.highlight(lang, str).value;
            } catch (__) {
            }
        }

        return ''; // use external default escaping
    }
});


const MarkdownHtml = (props) => (
    <div className={styles.markdown} dangerouslySetInnerHTML={{__html: md.render(props.markdown || '')}} style={props.style}/>
);

MarkdownHtml.propTypes = {
    markdown: PropTypes.string,
};
export default MarkdownHtml;