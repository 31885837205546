/**
 * @name: 教室管理
 * @description:
 * @author: Wood
 * @date: 2021/5/24
 * @update:
 */
import React, {useState, useRef} from 'react';
import {Card, Button, Space} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import DataTable from '../../../components/data/data-table2';
import RoomAddModal from './room-add-modal';
import RoomEditModal from './room-edit-modal';
// import {useTable} from 'table-render';
import {PlusOutlined} from '@ant-design/icons';

const Rooms = () => {
    const [visible, setVisible] = useState(false);
    const [editdata, setEditData] = useState(null);
    // const {refresh} = useTable();


    const table = useRef();
    const config = {
        table: table,
        title: "教室列表",
        tableName: "rooms",
        pagination: false,
        size: "small",
        columns:[{
            title: '编号',
            dataIndex: 'id',
        }, {
            title: '教室名称',
            dataIndex: 'name',
        }, {
            title: '教室大小',
            dataIndex: 'size',
        }, {
            title: '最大学生数量',
            dataIndex: 'max_students_count',
        }, {
            title: '适合课程',
            dataIndex: 'courses',
        }, {
            title: '排序',
            dataIndex: 'order_index',
        }, {
            title: '状态',
            dataIndex: 'status',
            enum: {
                0: '启用',
                1: '停用',
            },
            filters: [{
                text: '启用',
                value: 0,
            }, {
                text: '停用',
                value: 1,
            }]
        }, {
            title: '操作',
            render: (record) => (<Space>
                <a key="1">
                    <div
                        onClick={() => {
                            setEditData(record);
                        }}
                    >
                        修改
                    </div>
                </a>
            </Space>)
        }],
        toolbar: [
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => setVisible(true)}
            >添加</Button>
        ]
    };

    return (
        <PageContent title={"教室管理"}>
            <Card bordered={false}>
                <DataTable {...config} />

                {/*<DataTable*/}
                    {/*tableName={"rooms"}*/}
                    {/*table={{*/}
                        {/*size: "small",*/}
                        {/*columns: [{*/}
                            {/*title: '编号',*/}
                            {/*dataIndex: 'id',*/}
                        {/*}, {*/}
                            {/*title: '教室名称',*/}
                            {/*dataIndex: 'name',*/}
                        {/*}, {*/}
                            {/*title: '教室大小',*/}
                            {/*dataIndex: 'size',*/}
                        {/*}, {*/}
                            {/*title: '最大学生数量',*/}
                            {/*dataIndex: 'max_students_count',*/}
                        {/*}, {*/}
                            {/*title: '适合课程',*/}
                            {/*dataIndex: 'courses',*/}
                        {/*}, {*/}
                            {/*title: '排序',*/}
                            {/*dataIndex: 'order_index',*/}
                        {/*}, {*/}
                            {/*title: '状态',*/}
                            {/*dataIndex: 'status',*/}
                            {/*render: (status) => {*/}
                                {/*switch (status) {*/}
                                    {/*case 0:*/}
                                        {/*return "启用";*/}
                                    {/*case 1:*/}
                                        {/*return "停用";*/}
                                    {/*default:*/}
                                        {/*return "其他"*/}
                                {/*}*/}
                            {/*}*/}
                        {/*}, {*/}
                            {/*title: '操作',*/}
                            {/*render: (record) => (<Space>*/}
                                {/*<a key="1">*/}
                                    {/*<div*/}
                                        {/*onClick={() => {*/}
                                            {/*setEditData(record);*/}
                                        {/*}}*/}
                                    {/*>*/}
                                        {/*修改*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                            {/*</Space>)*/}
                        {/*}],*/}
                        {/*toolbar: [*/}
                            {/*<Button*/}
                                {/*type="primary"*/}
                                {/*icon={<PlusOutlined/>}*/}
                                {/*onClick={() => setVisible(true)}*/}
                            {/*>添加</Button>*/}
                        {/*]*/}
                    {/*}}*/}
                {/*/>*/}
            </Card>
            <RoomAddModal
                visible={visible}
                onOk={() => {
                    setVisible(false);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setVisible(false)
                }}
            />
            {editdata &&
            <RoomEditModal
                visible={!!editdata}
                data={editdata}
                onOk={() => {
                    setEditData(null);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setEditData(null);
                }}
            />
            }


        </PageContent>
    )
};


export default Rooms;


