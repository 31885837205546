import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import AccountMenuComponent from '../components/account-menu/account-menu';
import {signOut} from "../reducers/account";

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Modal, Input, message } from 'antd';
import {showPasswordModal, hidePasswordModal} from "../reducers/password-modal";
import {postChangePassword} from "../lib/api_account";
import md5 from 'md5';


class ChangePassword extends Component {
    state = {
        confirmDirty: false,
    };

    validateToNextPassword = (rule, value, callback) => {
        const {form} = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    };

    compareToFirstPassword = (rule, value, callback) => {
        const {form} = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('两次密码输入不一致！');
        } else {
            callback();
        }
    };

    handleConfirmBlur = e => {
        const {value} = e.target;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    };


    handleCancel = () => {
        this.props.hidePasswordModal();
    };

    handleOk = () => {
        //修改密码
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);

                // 密码加密
                postChangePassword(md5(values.oldpassword), md5(values.password))
                    .then(result=>{
                        if(result.code === 0){
                            message.success("修改成功！");
                            this.props.hidePasswordModal();
                        }else{
                            // message.error(result.message);
                        }
                    })

            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {passwordModal} = this.props;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };

        return (
            <Modal
                title="修改密码"
                visible={passwordModal}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
            >
                <Form {...formItemLayout}>
                    <Form.Item label="旧密码" hasFeedback>
                        {getFieldDecorator('oldpassword', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入旧密码!',
                                },
                            ],
                        })(<Input.Password/>)}
                    </Form.Item>
                    <Form.Item label="新密码" hasFeedback>
                        {getFieldDecorator('password', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入密码！',
                                },
                                {
                                    validator: this.validateToNextPassword,
                                },
                            ],
                        })(<Input.Password/>)}
                    </Form.Item>
                    <Form.Item label="重复新密码" hasFeedback>
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    required: true,
                                    message: '请再次输入密码！',
                                },
                                {
                                    validator: this.compareToFirstPassword,
                                },
                            ],
                        })(<Input.Password onBlur={this.handleConfirmBlur}/>)}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}


ChangePassword.propTypes = {};

const mapStateToProps = state => ({
    passwordModal: state.passwordModal
});

const mapDispatchToProps = dispatch => ({
    showPasswordModal: () => dispatch(showPasswordModal()),
    hidePasswordModal: () => dispatch(hidePasswordModal())
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({name: 'register'})(ChangePassword)));

