import axios from './rest-axios';

// 题目
const getSearch = (key) => axios.get(`/v1/challenges/search?key=${key}`);
const getList = (params) => axios.get(`/v1/challenges`, params);
const postChallenge = (data) => axios.post(`/v1/challenges/`, data);
const putChallenge = (id, data) => axios.put(`/v1/challenges/${id}`, data);
const getChallenge = (id) => axios.get(`/v1/challenges/${id}`);
const postUpdate = () => axios.post(`/v1/challenges/update`);


export {
    getSearch,
    getList,
    postChallenge,
    putChallenge,
    getChallenge,
    postUpdate
};
