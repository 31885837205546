/**
 * 结果显示
 */
import PropTypes from 'prop-types';
import React from 'react';

const re = ["Pending",
    "Pending Rejudging",
    "Compiling",
    "Running Judging",
    "Accepted",
    "Presentation Error",
    "Wrong Answer",
    "Time Limit Exceed",
    "Memory Limit Exceed",
    "Output Limit Exceed",
    "Runtime Error",
    "Compile Error",
    "Compile OK",
    "TEST RUN",
    "MANUAL CONFIRMATION"];

const Result = ({result, ...props}) => (
    <React.Fragment>
        {result === 4
            ? <span style={{color: "rgb(82, 196, 26)", fontWeight: "bold"}}>{re[result]}</span>
            : <span style={{color: "rgb(231, 76, 60)", fontWeight: "bold"}}>{re[result]}</span>
        }
    </React.Fragment>
);

Result.propTypes = {
    difficulty: PropTypes.number.isRequired,
};
export default React.memo(Result);