import React, {useState, useRef} from 'react';
import {Modal, Space, Tag, Divider} from 'antd';
import KeShiList from './keshi-list';

const KeShiListModal = ({card, visible, onOk, onCancel}) => {
    return (
        <Modal
            title={"课时记录"}
            visible={visible}
            width={800}
            onOk={onOk}
            onCancel={onCancel}
            footer={false}
        >
            <KeShiList user_id={card.user_id} card_id={card.id}/>
        </Modal>
    )
};


export default KeShiListModal;


