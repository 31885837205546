import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Space, Table} from "antd";
import {getContests} from "../../../lib/api_contest";
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";
import moment from "moment";
import ContestType from "./contest_type";
import ContestAddModal from "./contest_add_modal";
import ContestEditModal from "./contest_edit_modal";

const ContestList = () => {
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [state, setState] = useUrlState({
        page: 1, pageSize: 10, key: "",
    });
    const [showAddModal, setShowAddModal] = useState(false);

    const fetchData = () => {
        getContests(state)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data.records);
                    setTotal(res.data.total);
                }
            })
    }

    useEffect(fetchData, [state]);

    const handlerPageChange = (page, pageSize) => {
        setState({
            page, pageSize
        })
    };


    return (
        <PageContent title={"比赛列表"}>
            <Card bordered={false} title={"比赛列表"} extra={[<Space>
                <Button onClick={() => setShowAddModal(true)}>添加</Button>
            </Space>]}>
                <Table dataSource={list}
                       rowKey={"id"}
                       pagination={{
                           current: parseInt(state.page),
                           pageSize: parseInt(state.pageSize),
                           total: total,
                           position: ["bottomCenter"],
                           onChange: handlerPageChange
                       }}
                       size="small"
                >
                    <Table.Column
                        title="编号"
                        dataIndex="id"
                        key="编号"
                        width={60}
                        render={id => <Link to={`/teaching/contest/${id}`}>{id}</Link>}
                    />
                    <Table.Column
                        title="名称"
                        dataIndex="title"
                        key="名称"
                        ellipsis={true}
                        render={(title,record) => <Link to={`/teaching/contest/${record.id}`}>{title}</Link>}
                    />
                    <Table.Column
                        title="赛制"
                        dataIndex="type"
                        key="赛制"
                        width={60}
                        render={type => <ContestType type={type}/>}
                    />
                    {/*<Table.Column*/}
                    {/*    title="来源"*/}
                    {/*    dataIndex="source"*/}
                    {/*    key="来源"*/}
                    {/*/>*/}
                    <Table.Column
                        title="公开"
                        dataIndex="auth"
                        key="公开"
                        width={60}
                        render={auth => auth === 0 ? "公开赛" : auth === 1 ? "私有赛" : "保护赛"}
                    />
                    <Table.Column
                        title="开始时间"
                        dataIndex="start_time"
                        key="开始时间"
                        width={150}
                        render={(start_time) => moment(start_time).format("YYYY-MM-DD HH:mm")}
                    />
                    <Table.Column
                        title="结束时间"
                        dataIndex="end_time"
                        key="结束时间"
                        width={150}
                        render={(end_time) => moment(end_time).format("YYYY-MM-DD HH:mm")}
                    />
                    <Table.Column
                        title="时长"
                        dataIndex="duration"
                        key="时长"
                        width={100}
                        render={(duration) => `${parseInt(duration / 60 / 60 / 24)}天${parseInt(duration / 60 / 60) % 24}时${parseInt(duration / 60) % 60}分`}
                    />
                    <Table.Column
                        title="状态"
                        dataIndex="status"
                        key="状态"
                        width={60}
                        render={status => status === -1 ? "未开始" : status.status === 0 ? "进行中" : "已结束"}
                    />
                    <Table.Column
                        title="可见"
                        dataIndex="visible"
                        key="可见"
                        width={60}
                        render={visible => visible ? "显示" : "隐藏"}
                    />
                    <Table.Column
                        title="作者"
                        dataIndex="author"
                        width={60}
                        key="作者"
                    />
                </Table>
            </Card>

            {showAddModal && <ContestAddModal
                onOk={() => {
                    fetchData();
                    setShowAddModal(false);
                }}
                onCancel={() => {
                    setShowAddModal(false);
                }}
            />}


        </PageContent>
    );
}

export default ContestList;