
import axios from './rest-axios';

const listDocuments = () => axios.get(`/v1/documents`, {});
const getDocument = (id) => axios.get(`/v1/documents/${id}`, {});
const postDocument = (model) => axios.post(`/v1/documents`, model);
const putDocument = (model) => axios.put(`/v1/documents/${model.id}`, model);

export {
    listDocuments,
    getDocument,
    postDocument,
    putDocument,
};
